import React from 'react';
import { MdPostAdd } from "react-icons/md";
import { Link } from 'react-router-dom';

const PostadMobile = () => {
    return (
        <div className='md:hidden w-11/12 mx-auto text-center my-10 shadow-lg rounded-lg p-10'>
            <MdPostAdd className='text-8xl text-[#589BB1] w-full mx-auto' />
            <h1 className='my-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat sapiente doloribus nulla animi consequatur sint quos, quidem magni blanditiis error?</h1>
            <div className='btn btn-accent rounded-md border-none btn-wide bg-[#589BB1]'>
                <Link to='/advertise' >
                    <h1>Post Your Ad</h1>
                </Link>
            </div>
        </div>
    );
};

export default PostadMobile;