import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../Components/Footer/Footer';
import NavAppBar from '../Components/NavAppBar/NavAppBar';
import Navbar from '../Components/Navbar/Navbar';
import TopAppBar from '../Components/TopAppBar/TopAppBar';


const Main = () => {
    const currentRoute = useLocation();
    // console.log( currentRoute.pathname );
    return (

        <div>

            {/* top app bar goes here */ }

            <TopAppBar currentRoute={ currentRoute }></TopAppBar>

            {/* nav app bar */ }
            <NavAppBar currentRoute={ currentRoute }></NavAppBar>
            {/* <Navbar /> */ }

            <Outlet></Outlet>

            {/* Footer */ }
            <Footer />
        </div>
    );
};

export default Main;