import React, { useContext, useEffect, useState } from 'react';
import './Profile.css'
import { doc, getDoc, setDoc } from "firebase/firestore";
// import { db } from '../../firebase.init';
import { Avatar, Box, Container, Stack, Typography, TextField, Grid, Button, Divider } from '@mui/material';
import placeholder from '../../Images/placeHolder/placeholderImage.png'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { EmailAuthProvider, reauthenticateWithCredential, updateEmail, updatePassword, updateProfile } from 'firebase/auth';
import ReAuthDialouge from '../../Components/ReAuth/ReAuth';
import { AuthContext } from '../../Context/AuthProvider';
import { FaCloudUploadAlt, FaCog, FaRegImage } from "react-icons/fa";

const Profile = () => {
    const { user, db, updateUser, updateUserPassword, updateUserEmail } = useContext( AuthContext );
    // const providerId = user?.providerData[0];
    const [ saveUser, setSaveUser ] = useState()

    // console.log( user );

    useEffect( () => {
        fetch( `https://api.reuse.sale/user/api/authentication.php?u_password=${ user?.uid }` )
            .then( res => res.json() )
            .then( data => {
                setSaveUser( data );
            } )
    }, [ user?.uid ] )

    // console.log( saveUser );
    /**
     * Description
     * for phone number
     * display phone number from data base
     * @returns {any}
     * */
    const [ phone, setPhone ] = useState();

    /** *
     * for enable or disable edit buttons
     */
    const [ isDisable, setIsDisable ] = useState( true )

    /**
     * all the states to control the inputs
     */
    const [ name, setName ] = useState( '' );
    const [ email, setEmail ] = useState( '' );
    const [ phoneNum, setPhoneNum ] = useState( "" );
    const [ pass, setPass ] = useState( "" );
    const [ confPass, setConfPass ] = useState( "" );
    const [ changeFb, setchangeFb ] = useState( "" );
    const [ changeInsta, setchangeInsta ] = useState( "" );
    const [ changeWa, setchangeWa ] = useState( "" );


    /**
     * to store and change functions
     * their name describes their point
     */


    const handleFullName = ( event ) => {
        setName( event.target.value );
        console.log( event.target.value );
    }
    const handleEmail = ( event ) => {
        setEmail( event.target.value );
        console.log( event.target.value );
    }
    const handlePhone = ( event ) => {
        setPhoneNum( event.target.value );
        console.log( event.target.value );
    }
    const handlePass = ( event ) => {
        setPass( event.target.value );
        console.log( event.target.value );
    }
    const handleConfPass = ( event ) => {
        setConfPass( event.target.value );
        // console.log( event.target.value );
    }
    const handleFb = ( event ) => {
        setchangeFb( event.target.value );
        console.log( event.target.value );
    }
    const handleInsta = ( event ) => {
        setchangeInsta( event.target.value );
        console.log( event.target.value );
    }
    const handleWa = ( event ) => {
        setchangeWa( event.target.value );
        console.log( event.target.value );
    }

    /**
     * now compare  functions  and other button handle functions
     */

    const handleUpdatePass = () => {
        updateUserPassword( pass )
            // updateUserPassword( user, pass )
            .then( () => {
                // Update successful.
                alert( "Password Updated!" );
                setPass( '' )
                setConfPass( '' )
            } )
            .catch( ( error ) => {
                // An error ocurred
                console.log( error );
                if ( error.code === "auth/requires-recent-login" ) {
                    // setDialouge( true )
                }
            } );
    }

    const handleMatchPass = () => {
        if ( pass !== '' || confPass !== '' ) {
            if ( pass === confPass ) {
                console.log( "Matched" );
                handleUpdatePass()
            } else {
                console.log( "No Mtach" );
            }
        } else {
            alert( "Password field empty" )
        }
    }

    const handleNameUpdate = () => {

        if ( name !== '' ) {
            updateUser( {
                displayName: name,
            } )
                .then( () => {
                    alert( "Name Updated to:", name )
                    console.log( name );
                    setName( '' );
                } )
                .catch( ( error ) => {
                    console.log( error );
                } );
        } else {
            alert( "Name Empty" )
        }
    }

    const [ dialouge, setDialouge ] = useState( false )

    const promptForCredentials = ( email, pass ) => {

        try {
            const credential = EmailAuthProvider.credential(
                user.email,
                pass
            );
            reauthenticateWithCredential( user, credential ).then( () => {
                // User re-authenticated.
                handleClose()
            } );
        } catch ( error ) {
            console.log( error.message );
        }


    }

    // console.log( changeFb, changeInsta, changeWa, handleFb, handleInsta, handleWa )

    const handleEmailUpdate = () => {
        console.log( email );

        // if ( email !== '' ) {
        //     updateEmail( user, email )
        //         .then( () => {
        //             // Email updated!
        //             alert( ( "Email Updated!" ) )
        //         } )
        //         .catch( ( error ) => {
        //             // An error occurred
        //             // console.log( error );
        //             if ( error.code === "auth/requires-recent-login" ) {
        //                 setDialouge( true )
        //             }
        //         } );
        // } else {
        //     alert( "Email Empty" )
        // }
    }

    // const handlePhoneNumUpdate = () => {
    //     console.log( phoneNum );
    //     const ref = doc( db, 'userinfo', user.uid )
    //     setDoc( ref, { phoneNum } )
    //         .then( ( res ) => {
    //             // console.log( res );
    //             alert( "Phone Number Updated" );

    //         } ).catch( ( err ) => {
    //             console.error( err );
    //         } )
    // }

    // useEffect( () => {

    //     if ( user ) {

    //         const docRef = doc( db, "userinfo", user.uid );
    //         getDoc( docRef )
    //             .then( ( res ) => {
    //                 // console.log( res.data().phoneNum );
    //                 setPhone( res.data()?.phoneNumberSignup ? res.data()?.phoneNumberSignup : res.data().phoneNum )
    //             } )
    //     }


    // }, [ user ] );


    const handleClose = () => {
        setDialouge( false );
    };

    return (
        <div>
            { dialouge && <ReAuthDialouge dialouge={ dialouge } handleClose={ handleClose } promptForCredentials={ promptForCredentials }></ReAuthDialouge> }
            <Box sx={ { boxShadow: 5, py: 5, px: 3, mt: 5, borderRadius: 3, maxWidth: { xs: "500px", sm: "900px", md: "1200px", lg: "1200px", xl: "1440px" }, width: 'auto', mx: "auto" } }>
                {/* user profile container */ }
                <Container sx={ { display: "flex", alignItems: "stretch", justifyContent: "center", flexDirection: { xs: "column", sm: "column", md: "row" } } } >
                    <Container sx={ { width: { sm: "70%", md: "60%" } } }>
                        <Box sx={ { width: "fit-content", mx: "auto", display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' } }>

                            <Avatar alt='userName' src={ user?.photoURL ? user?.photoURL : placeholder } sx={ { width: "140px", height: "140px", border: 1, borderColor: "blue" } }></Avatar>

                            <div className='absolute top-28 right-4 z-10'>
                                <label htmlFor="upload-photo">
                                    <FaRegImage className='text-2xl' />
                                </label>
                                <input type="file" name="photo" id="upload-photo" className='hidden' />
                            </div>

                            <Stack sx={ { textAlign: "center" } } spacing={ 1.3 }>
                                <Typography variant="h4" sx={ { color: "#000000" } } >{ user?.displayName ? user.displayName : 'No Name' }</Typography>
                                <Typography variant="p" sx={ { color: "#9E9797" } } >{ email }</Typography>
                                <Typography variant="p" sx={ { color: "#9E9797" } } > { saveUser?.u_phone ? saveUser.u_phone : "N/A" }</Typography>
                            </Stack>
                        </Box>
                    </Container>
                    <Container sx={ {} } >
                        <Box >
                            <Typography variant="profileHeading" >Personal Details</Typography>
                            <Grid container spacing={ 6 } sx={ { mt: 5 } } >
                                <Grid item sm={ 10 } md={ 6 }>
                                    <Box>
                                        <Typography variant="h6" >Full Name</Typography>

                                        {/* Name Field--------- */ }
                                        <TextField
                                            id="displayName"
                                            label={ user?.displayName }
                                            value={ name }
                                            onChange={ ( event ) => handleFullName( event ) }
                                            disabled={ isDisable }
                                            variant='outlined'
                                            type={ "text" }
                                            sx={ {
                                                width: "100%", backgroundColor: "#F1F1F1",
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#C6DECD',
                                                }, borderRadius: 2, "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "green"
                                                }, "&:hover": {
                                                    "&& fieldset": {
                                                        border: "1px solid green"
                                                    }
                                                },
                                                "& .Mui-disabled": {
                                                    backgroundColor: "#BFEAF5",

                                                },

                                            }
                                            }
                                            InputLabelProps={ { style: { fontWeight: "600", } } }

                                        />
                                        <Button onClick={ handleNameUpdate } disabled={ isDisable } sx={ {
                                            bgcolor: "#BFEAF5", mt: 3, py: 2, px: 3, color: "black", "&:hover": {
                                                bgcolor: "wheat",
                                            }
                                        } }>
                                            Update
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item sm={ 10 } md={ 6 } >
                                    <Box>
                                        <Typography variant="h6" >Email</Typography>

                                        {/* Email Field----------- */ }
                                        <TextField
                                            id="emailProfile"
                                            label={ user?.email }
                                            value={ email }
                                            onChange={ ( event ) => handleEmail( event ) }
                                            variant='outlined'
                                            disabled={ isDisable }
                                            type={ "email" }
                                            sx={ {
                                                width: "100%", backgroundColor: "#F1F1F1",
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#C6DECD',
                                                }, borderRadius: 2, "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "green"
                                                }, "&:hover": {
                                                    "&& fieldset": {
                                                        border: "1px solid green"
                                                    }
                                                },
                                                "& .Mui-disabled": {
                                                    backgroundColor: "#BFEAF5",

                                                },
                                            } }
                                            InputLabelProps={ { style: { fontWeight: "600", } } }
                                        />
                                        <Button onClick={ handleEmailUpdate } disabled={ isDisable } sx={ {
                                            bgcolor: "#BFEAF5", mt: 3, py: 2, px: 3, color: "black", "&:hover": {
                                                bgcolor: "wheat",
                                            }
                                        } }>
                                            Update
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item sm={ 10 } md={ 6 }>
                                    <Box>
                                        <Typography variant="h6" >Phone Number</Typography>

                                        {/* PhoneNumber field-------------- */ }
                                        <TextField
                                            id="PhoneNumberProfile"
                                            label="Phone Number"
                                            value={ phone }
                                            onChange={ ( event ) => handlePhone( event ) }
                                            variant='outlined'
                                            type={ "number" }
                                            disabled={ isDisable }
                                            sx={ {
                                                width: "100%", backgroundColor: "#F1F1F1",
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: '#C6DECD',
                                                }, borderRadius: 2, "&:hover .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "green"
                                                }, "&:hover": {
                                                    "&& fieldset": {
                                                        border: "1px solid green"
                                                    }
                                                },
                                                "& .Mui-disabled": {
                                                    backgroundColor: "#BFEAF5",

                                                },
                                            } }
                                            InputLabelProps={ { style: { fontWeight: "600", } } }
                                        />
                                        <Button disabled={ isDisable } sx={ {
                                            // <Button onClick={ handlePhoneNumUpdate } disabled={ isDisable } sx={ { 
                                            bgcolor: "#BFEAF5", mt: 3, py: 2, px: 3, color: "black", "&:hover": {
                                                bgcolor: "wheat",
                                            }
                                        } }>
                                            Update
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box>
                                <Box sx={ { mt: 4 } }>
                                    <Typography variant="h6" >Type New Password</Typography>
                                    <TextField
                                        id="ChangePasswordProfile"
                                        label="Change Password"
                                        value={ pass }
                                        onChange={ ( event ) => handlePass( event ) }
                                        variant='outlined'
                                        type={ "password" }
                                        disabled={ isDisable }
                                        sx={ {
                                            width: "100%", backgroundColor: "#F1F1F1",
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#C6DECD',
                                            }, borderRadius: 2, "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "green"
                                            }, "&:hover": {
                                                "&& fieldset": {
                                                    border: "1px solid green"
                                                }
                                            },
                                            "& .Mui-disabled": {
                                                backgroundColor: "#BFEAF5",

                                            },
                                        } }
                                        InputLabelProps={ { style: { fontWeight: "600", } } }
                                    />
                                </Box>
                                <Box sx={ { mt: 4 } }>
                                    <Typography variant="h6" >Confirm Password</Typography>
                                    <TextField
                                        id="ConfirmPasswordProfile"
                                        label="Confirm Password"
                                        value={ confPass }
                                        onChange={ ( event ) => handleConfPass( event ) }
                                        variant='outlined'
                                        type={ "password" }
                                        disabled={ isDisable }
                                        sx={ {
                                            width: "100%", backgroundColor: "#F1F1F1",
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#C6DECD',
                                            }, borderRadius: 2, "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "green"
                                            }, "&:hover": {
                                                "&& fieldset": {
                                                    border: "1px solid green"
                                                }
                                            },
                                            "& .Mui-disabled": {
                                                backgroundColor: "#BFEAF5",

                                            },
                                        } }
                                        InputLabelProps={ { style: { fontWeight: "600", } } }
                                    />
                                </Box>
                                <Box sx={ { mt: 4 } }>
                                    <Button disabled={ isDisable } onClick={ handleMatchPass } size="large" variant='contained' sx={ {
                                        bgcolor: "wheat", color: "black", py: 3,
                                        px: 5,
                                        fontWeight: 600,
                                        fontSize: 15,
                                    } }>Change Password</Button>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={ { mt: 5 } }>
                            {/* <Typography variant="profileHeading" >Edit Settings</Typography> */ }
                            <Box sx={ { my: 3 } }>

                                <Button onClick={ () => setIsDisable( !isDisable ) } size='large' variant="contained" startIcon={ < SettingsOutlinedIcon sx={ {
                                    animation: "spin 2s linear infinite",
                                    "@keyframes spin": {
                                        "0%": {
                                            transform: "rotate(0deg)",
                                        },
                                        "100%": {
                                            transform: "rotate(360deg)",
                                        },
                                    },
                                } } /> }
                                    sx={ {
                                        bgcolor: isDisable ? "wheat" : "whitesmoke", "&:hover": {
                                            bgcolor: "pink"
                                        },
                                        py: 3,
                                        px: 5,
                                        fontWeight: 600,
                                        fontSize: 20,
                                    } }>
                                    Edit Settings
                                </Button>

                            </Box>
                        </Box>

                        {/* <button type="button" className="btn btn-accent text-xl my-5" >
                            <FaCog style={ { animation: 'spin 2s linear infinite' } } className='animate-spin h-6 w-6 mr-3' />
                            Edit Setting
                        </button> */}
                        <Box>
                            <Typography variant="profileHeading" >Social Handles</Typography>
                            <Stack gap={ 6 } direction={ { xs: "column", sm: "row" } } sx={ { p: 2, mt: 4 } }>
                                <Box>
                                    <Button startIcon={ <FacebookIcon></FacebookIcon> } size='large' variant='contained' sx={ {
                                        bgcolor: "whitesmoke", color: "black", py: 3, px: 6, "&:hover": {
                                            bgcolor: "wheat"
                                        }
                                    } }>
                                        Facebook
                                    </Button>
                                </Box>
                                <Box>
                                    <Button startIcon={ <InstagramIcon></InstagramIcon> } size='large' variant='contained' sx={ {
                                        bgcolor: "whitesmoke", color: "black", py: 3, px: 6, "&:hover": {
                                            bgcolor: "wheat"
                                        }
                                    } }>
                                        Instagram
                                    </Button>
                                </Box>
                                <Box>
                                    <Button startIcon={ <WhatsAppIcon></WhatsAppIcon> } size='large' variant='contained' sx={ {
                                        bgcolor: "whitesmoke", color: "black", py: 3, px: 6, "&:hover": {
                                            bgcolor: "wheat"
                                        }
                                    } }>
                                        Whatsapp
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Container>
                </Container>
                <Divider sx={ { my: 5 } }></Divider>
                {/* user post container */ }
                <Container>
                    <Box>
                        <Typography variant='profileHeading'>Posts By { user?.displayName ? user.displayName : 'No Name' }</Typography>
                    </Box>
                </Container>
            </Box>
        </div >
    );
};

export default Profile;