import React from 'react';
import { FaAngleRight } from 'react-icons/fa';

const PrivacyPolicy = () => {
    return (
        <div className='w-5/6 mx-auto mt-10'>
            <div className='flex w-full items-center font-semibold'>
                <h1>Home</h1>
                <FaAngleRight />
                <h1>Privacy Policy</h1>
            </div>

            <div className='mt-10 flex flex-col md:flex-row gap-14'>
                <div className='w-full md:w-1/4'>
                    <div className='sticky top-24'>
                        <div>
                            <h1 className='text-2xl font-bold'>Privacy Statement</h1>
                            <hr className='border mb-5 mt-2' />
                        </div>
                        <div className='flex flex-col gap-3 '>
                            <a href='#introduction' className='text-[#F33C93]'>Introduction</a>
                            <a href='#advertising' className='text-[#F33C93]'>Collection of Personally Identifiable Information</a>
                            <a href='#dealer' className='text-[#F33C93]'>Information Use</a>
                            <a href='#copyrights' className='text-[#F33C93]'>Information Sharing</a>
                            <a href='#trademark' className='text-[#F33C93]'>Changes to this Policy</a>
                        </div>
                    </div>
                </div>
                <div className='w-full md:w-3/4 flex flex-col gap-8'>
                    <div>
                        <h1 className='text-xl font-semibold mb-3'>Introduction</h1>
                        <p id='introduction' className='text-justify'>
                            Cars.com strongly supports individual privacy, customer and consumer data rights, and the protection of consumer data. We value every consumer's individual right to protect data about that consumer and exercise control over use. We give all consumers the right of access to the data we collect about them and we give all consumers the option to control our use of that data—including correction and deletion upon request. Cars.com only obtains consumer data through lawful and transparent processes and will obtain explicit consent to collection of personal information where required by law. Cars.com's consumer data usage is overseen by its Data Governance Council comprised of senior executives with reporting authority to the Board of Directors.
                            <br />
                            <br />
                            This Privacy Policy (the"Policy") describes Cars.com's collection, use and disclosure of personal information. This Policy applies to all uses of consumer data by Cars.com and applies to all products and services, including uses in connection with the website located at Passive Information Collection www.cars.com, all versions of the Site that Cars.com operates (including without limitation any mobile or similar versions), and offline uses of consumer data. Cars.com does not use personal information collected from the Site except for the intended purposes more fully-described in this Policy.
                        </p>
                    </div>
                    <div>
                        <h1 id='advertising' className='text-xl font-semibold mb-3'>Collection of Personally Identifiable Information</h1>
                        <p className='text-justify'>
                            Cars.com is committed to obtaining user data through lawful and transparent means, with explicit consent where required by law. You are not required to provide any personally identifiable information to visit the Site.Cars.com automatically collects Usage Data (as described below) and may collect ZIP codes and other information in connection with your use of the Site (e.g., the type of car you are looking for). Cars.com collects personally identifiable information only when you make it available through your use of the Site. You may be required to provide personally identifiable information to use certain services on this Site, including, but not limited to, the "Email the Dealer/Seller" feature and to purchase and create a "Sell It Yourself" online classified listing ("SIY Ad") and account ("SIY Account"). In addition, you may submit personally identifiable information through the Site in other ways, including: (a) participating in online surveys, promotions, contests and sweepstakes; (b) subscribing to newsletters, editorial content, or promotional content sent via email, (c) using the Site's "Feedback" and "More Information" forms; and (d) in connection with other products, services, features, or requests made available through the Site. We also publish toll-free phone numbers for many of the dealerships that list vehicles for sale on the Site. If you dial one of these phone numbers, Cars.com or a service provider operating on Cars.
                        </p>
                    </div>
                    <div>
                        <h1 id='dealer' className='text-xl font-semibold mb-3'>Information Use</h1>
                        <p className='text-justify'>
                            Personally Identifiable Information Personally identifiable information collected by Cars.com through your use of the Site (including without limitation through your submission of an email address to receive promotional or other communications via email, or through you providing a mobile number to submit a lead or to receive coupon(s) or other communications via call, text or SMS) may be stored on servers or in a cookie on your computer and may be used by Cars.com for the purposes listed below. Please note that in most cases the primary purposes for use of personal information are the submission and transmittal of lead data and powering targeted advertising and user experiences; however, although Cars.com does not track exact numbers, personal information of most users is also used for the purposes listed below as secondary purposes:

                        </p>
                        <ol className='list-disc list-outside mt-2 text-justify leading-relaxed'>
                            <li>
                                To submit and transmit lead data to Cars.com advertisers.
                            </li>
                            <li>To provide targeted advertising both on the Site and on third-party advertising platforms (for example, in connection with our FUEL advertising product).</li>
                            <li>To power individualized or targeted user experiences across the Site and to improve those experiences.</li>
                            <li>To engage in attribution and analytics modeling such as lead scoring and performance reporting to advertisers.</li>
                            <li>To confirm or fulfill a request that you make (e.g., in order to send you a price quote or other information you have requested, Cars.com may share your contact information and content interests with a participatingCars.com dealer and/or manufacturer).</li>
                            <li>To deliver coupons and other marketing communications via text or SMS, which, by providing your mobile number to Cars.com, you have expressly consented to receive.</li>
                        </ol>
                    </div>
                    <div>
                        <h1 id='copyrights' className='text-xl font-semibold mb-3'>Information Sharing</h1>
                        <p className='text-justify'>Cars.com shares the personally identifiable information that you provide with third-parties in the following cases:</p>
                        <ol className='list-decimal list-outside mt-2 text-justify leading-relaxed'>
                            <li>
                                Affiliates. Cars.com may share your personally identifiable information with affiliates in a manner consistent with this Policy.
                            </li>
                            <li>Leads and Requests. If you submit a lead or request information or services through the Site, you agree that Cars.com may share personally identifiable information with third-parties as may be necessary to provide such information to or perform such services for you. For example, if you use the Site's "Email the Dealer"form in a vehicle listing to request a price quote from a dealership, Cars.com will share the information you entered with that dealership (or its designee). If that vehicle listing was provided by an automobile manufacturer on behalf of a dealer, Cars.com may also share the personally identifiable information you submit as part of the request with the automobile manufacturer and/or its affiliates. Similarly, if through the Site you request additional information from a financial institution, Cars.com will share your information with that institution. Personally identifiable information also may be included in reports to these third-parties,but only when you have intended to submit the information to the third-party as described above. After the information is provided to such third-parties, the subsequent use or disclosure of such information is subject to those third-parties' privacy policies and practices and Cars.com does not control their subsequent use or disclosure of information.</li>
                            <li>Sweepstakes/Promotions. In the event that you participate in a sweepstakes, contest or other promotion on the Site that is sponsored by Cars.com and a third-party, Cars.com reserves the right to share personally identifiable information collected in conjunction with the sweepstakes, contest or promotion with the third-party. After the information is provided to such third-parties, the subsequent use or disclosure of such information is subject to those third-parties' privacy policies and practices.</li>
                            <li>Direct Marketing. Except as provided herein, it is Cars.com's general practice not to disclose personally identifiable information of our customers to third-parties specifically for the third-parties'direct marketing purposes. If you are 18 years of age or older and have affirmatively agreed to receive third-party direct marketing materials, Cars.com may share personally identifiable information with third-parties for direct marketing purposes. Although Cars.com will notify all third-party direct marketers that the information provided to them is confidential, the subsequent use or disclosure of such information is subject to those third-parties' privacy policies and practices.</li>
                            <li>Service Providers. Cars.com may work with third-parties that provide services on its behalf, including,among others, providers of analytical, attribution, development, promotional, research, credit card processing, technical and other services. For example, Cars.com may ask that a third-party conduct an online survey or sweepstakes or send newsletters on its behalf. Similarly, if you make a payment to Cars.com by credit card, Cars.com may share your personally identifiable information and credit card information with a third-party in order to process credit card transactions, or such information may be collected directly by the credit card processing service provider on Cars.com's behalf. In such cases, Cars.com asks that the third-party maintain your personally identifiable information as confidential and use the information only to perform the services specified by Cars.com.</li>
                            <li>Email List Opt-Outs. In the event Cars.com sends promotional emails to you on behalf of third-party advertisers and you inform Cars.com you wish to opt out of future promotional emails from a particular third-party, Cars.com may share your email address with that third-party so the third-party may add you to its opt-out lists.</li>
                        </ol>
                    </div>
                    <div>
                        <h1 id='trademark' className='text-xl font-semibold mb-3'>Changes to this Policy</h1>
                        <p>We reserve the right to update and periodically amend this Policy at our discretion and at any time. If we make changes to this Policy, amendments will be posted online and the date of update will be included. Your continued use of Cars.com and its affiliated sites after any such update indicates your agreement to the same.</p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;