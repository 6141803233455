import React from 'react';
import { BsSpeedometer2 } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GoWatch } from 'react-icons/go';
import { Link } from 'react-router-dom';


const Product = ({ car }) => {
    return (
        <div className="card md:card-side h-full md:h-56 shadow-xl my-5 rounded-lg">
            <img className='w-full md:w-1/2 object-cover h-44 md:h-full rounded-t-lg md:rounded-tr-none md:rounded-br-none md:rounded-l-lg' src={car?.image_one} alt="car" />
            <div className="card-body px-3 md:px-5 py-2">
                <h2 className="card-title font-bold text-xl">{car?.list_registrationyear} {car?.list_productname} </h2>
                {/* <h1 className='text-2xl font-semibold'>{ car?.list_id }</h1> */}
                {/* <h1 className='text-2xl font-semibold'>{ car?.u_id }</h1> */}
                <div className='flex-none md:flex md:gap-10'>
                    <div className='text-gray-400 text-lg items-center font-semibold flex gap-2'>
                        <BsSpeedometer2 className='text-2xl' />
                        <p>{car?.list_mileage} km</p>
                    </div>
                    <div className='text-[#589BB1]'>
                        <div className='flex gap-2'>
                            <h1 className='text-lg font-bold'>Price:</h1>
                            <p className='text-xl font-semibold'>$ {car?.list_price}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='flex gap-2 text-lg items-center'>
                        {/* <GoLocation className='text-orange-600 font-bold' /> */}
                        <FaMapMarkerAlt className='text-red-600 font-bold text-xl' />
                        <h1>{car?.list_address}</h1>
                        {/* <h1>New York, USA</h1> */}
                    </div>
                    <div className='flex gap-2 text-lg items-center'>
                        <GoWatch />
                        <h1>{car?.list_publishdate}</h1>
                    </div>
                </div>
                <div className="card-actions justify-end">
                    <Link to={`/singleProduct/${car?.list_id}`} className='fled items-end' >
                        <button
                            className="btn bg-[#589BB1] border-none text-white">
                            Check availability
                        </button>
                    </Link>

                </div>
            </div>
        </div>
    );
};

export default Product;