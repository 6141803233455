import { createUserWithEmailAndPassword, FacebookAuthProvider, fetchSignInMethodsForEmail, getAuth, GoogleAuthProvider, linkWithCredential, onAuthStateChanged, RecaptchaVerifier, sendEmailVerification, signInWithEmailAndPassword, signInWithPhoneNumber, signInWithPopup, signOut, updateEmail, updatePassword, updateProfile } from 'firebase/auth';

import { getFirestore } from "firebase/firestore";
import React, { createContext, useEffect, useState } from 'react';
import app from '../firebase/firebase.config';

export const AuthContext = createContext()

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider()



// const appVerifier = new RecaptchaVerifier( 'recaptcha-container' );


const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const db = getFirestore(app);

    // createuser or Signup-----------------
    const createUser = (email, password) => {
        setLoading(true)
        return createUserWithEmailAndPassword(auth, email, password);
    }

    // Login user --------------------------
    const Login = (email, password) => {
        setLoading(true)
        return signInWithEmailAndPassword(auth, email, password);
    }

    // phneNumber verification ------------
    // const signInWithPhone = ( phoneNumber ) => {
    //     return signInWithPhoneNumber( auth, phoneNumber, appVerifier )
    //     // .then( ( confirmationResult ) => {
    //     //     // SMS sent. Prompt user to type the code from the message, then sign the
    //     //     // user in with confirmationResult.confirm(code).
    //     //     window.confirmationResult = confirmationResult;
    //     //     // ...
    //     // } ).catch( ( error ) => {
    //     //     // Error; SMS not sent
    //     //     // ...
    //     // } );
    // }


    // update User Profile----------------------
    const updateUser = (profile) => {
        setLoading(true)
        return updateProfile(auth.currentUser, profile)
    }

    // Email verification-------------------------
    const emailVerification = () => {
        setLoading(true)
        return sendEmailVerification(auth.currentUser);
    }

    // update Email-----------------------
    const updateUserEmail = (email) => {
        setLoading(true)
        return updateEmail(auth.currentUser, email)
    }

    // update Password ----------------
    const updateUserPassword = (pass) => {
        setLoading(true)
        return updatePassword(user, pass)
    }

    // Logout User----------------------
    const logOut = () => {
        return signOut(auth)
    }

    // Sing is with Google---------------------------
    const googleLogin = () => {
        setLoading(true)
        return signInWithPopup(auth, googleProvider)

        // // const googleProvider = new GoogleAuthProvider();
        // signInWithPopup(googleProvider)
        //     .then((result) => {
        //         console.log(result);
        //         // Sign in successful
        //     })
        //     .catch((error) => {
        //         if (error.code === 'auth/account-exists-with-different-credential') {
        //             const email = error.email;
        //             fetchSignInMethodsForEmail(email)
        //                 .then((methods) => {
        //                     if (methods.includes('facebook.com')) {
        //                         const facebookProvider = new FacebookAuthProvider();
        //                         return signInWithPopup(facebookProvider);
        //                     } else {
        //                         throw new Error('You already have an account with a different provider.');
        //                     }
        //                 })
        //                 .then((result) => {
        //                     // Sign in successful with Facebook
        //                     // Link the accounts if desired
        //                     const googleCred = GoogleAuthProvider.credential(
        //                         result.credential.accessToken);
        //                     return result.user.linkWithCredential(googleCred);
        //                 })
        //                 .then((usercred) => {
        //                     // Accounts linked successfully
        //                     const user = usercred.user;
        //                     console.log(user);
        //                 })
        //                 .catch((error) => {
        //                     console.log(error);
        //                     // Handle error
        //                 });
        //         } else {
        //             // Handle other errors
        //         }
        //     });



    }

    // Sign in with Facebook---------------------------
    const facebookLogin = () => {
        setLoading(true)
        return signInWithPopup(auth, facebookProvider)

    }

    // get Current Login User ---------------------------
    useEffect(() => {
        const unSubscribe = onAuthStateChanged(auth, currentUser => {
            setUser(currentUser);
            setLoading(false)
        })
        return () => {
            unSubscribe();
        }
    }, [])

    const authInfo = { user, db, auth, loading, createUser, emailVerification, Login, updateUserEmail, updateUser, googleLogin, facebookLogin, updateUserPassword, logOut }

    return (
        <AuthContext.Provider value={authInfo}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;