import React, { useState } from 'react';
import './Contact.css'
import headerimg from '../../Images/hero/Group-1118.png'
import headerover from '../../Images/hero/Rectangle 2381.png'
import img from '../../Images/heroImages/image 117.png'
import { BsTelephone } from "react-icons/bs";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaRegEnvelope, FaPlus, FaMinus } from "react-icons/fa";
import { useForm } from 'react-hook-form';


const accordions = [
    {
        id: 1
    },
    {
        id: 2
    },
    {
        id: 3
    },
    {
        id: 4
    },
    {
        id: 5
    },
    {
        id: 6
    },
]

const Contact = () => {
    const [ selectAccordion, setSelectAccordion ] = useState();
    const [ sign, setSign ] = useState( false )


    const AccordionSelect = ( id ) => {
        setSelectAccordion( id )
        setSign( !sign )

    }

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const onSubmit = data => console.log( data );
    // console.log( acordion );
    // console.log( sign );



    return (
        <div>
            <div className='relative'>
                <img className='absolute h-full w-full' src={ headerover } alt="" />
                <img className='h-full w-full object-cover' src={ headerimg } alt="" />
                <div className='absolute top-1/3 left-1/3'>
                    <hr className='border-2 border w-96' />
                    <h1 className='text-center text-6xl font-semibold text-white my-5'>Contact Us</h1>
                    <hr className='border-2 border w-96' />
                </div>
            </div>
            {/* Form part ----------- */ }
            <div className='my-16'>
                <h1 className='text-3xl text-center font-bold'>Have a question? Let's connect</h1>
                <div className="flex flex-col lg:flex-row w-11/12 mx-auto my-16">
                    <div className="grid flex-grow card rounded-box place-items-center w-full md:w-1/2">
                        <img className='w-full mb-8 md:w-56' src={ img } alt="" />
                        <h1 className='text-xl font-bold'>You can also call or email us</h1>
                        <p>Every day from 10:00 AM to 6:00 PM</p>
                        <div className='mt-11 flex gap-5 mx-auto'>
                            <div>
                                <div className='flex gap-2 items-center justify-center text-xl'>
                                    <BsTelephone className='text-[#589BB1] ' />
                                    <h1>Call Us</h1>
                                </div>
                                <h1 className='text-center text-[#589BB1]'>000-123-456-789</h1>
                            </div>
                            <div className="divider lg:divider-horizontal"></div>
                            <div>
                                <div className='flex gap-2 items-center justify-center text-xl'>
                                    <FaRegEnvelope className='text-[#589BB1] ' />
                                    <h1>Email Us</h1>
                                </div>
                                <h1 className='text-center text-[#589BB1]'>000-123-456-789</h1>
                            </div>
                            <div className="divider lg:divider-horizontal"></div>
                            <div>
                                <div className='flex gap-2 items-center justify-center text-xl'>
                                    <AiOutlineGlobal className='text-[#589BB1] ' />
                                    <h1>Visit Us</h1>
                                </div>
                                <h1 className='text-center text-[#589BB1]'>000-123-456-789</h1>
                            </div>
                        </div>
                    </div>
                    <div className="divider lg:divider-horizontal"></div>
                    {/* Form design---------------start */ }
                    <div className="grid flex-grow card rounded-box place-items-center w-full md:w-1/2 mx-auto">
                        <form onSubmit={ handleSubmit( onSubmit ) } className='w-full mx-auto flex justify-center flex-col items-center' >
                            <div className='flex flex-col w-full gap-2'>
                                <label className="label">
                                    <span className="">Your Name (required)</span>
                                </label>
                                <input className="input input-bordered  bg-transparent" { ...register( "firstName", { required: true, maxLength: 20 } ) } />
                                <label className="label">
                                    <span className="">Your Email</span>
                                </label>
                                <input className="input input-bordered  bg-transparent" { ...register( "lastName", { required: true } ) } />
                                <label className="label">
                                    <span className="">Your Message</span>
                                </label>
                                <textarea className="textarea textarea-bordered w-full bg-transparent h-24"{ ...register( "message", { required: true, } ) } placeholder="Message"></textarea>
                            </div>
                            <input type="submit" className='btn bg-[#589BB1] border-none hover:bg-[#195366] text-white btn-wide mt-5' />
                        </form>
                    </div>
                    {/* Form design---------------start */ }
                </div>
            </div>
            {/* Form part ----------- */ }

            <div className='bg-[#006B7F] py-14 my-28 text-white'>
                <div className='w-11/12 mx-auto text-center'>
                    <h1 className='text-3xl font-bold my-5'>Better together with innovative solutions</h1>
                    <p className='w-3/4 mx-auto'>CARS is an incubator for the most innovative products and solutions driving the future of the automotive industry, and importantly, helping to drive our customers' businesses. We are made up of Cars.com, Dealer Inspire and DealerRater.</p>
                </div>
            </div>

            <div className='w-11/12 mx-auto text-center my-16'>
                <h1 className='text-3xl font-bold text-center my-5'>Frequently Asked Questions</h1>
                <p className='w-3/4 mx-auto'>Some of us are car nuts, some of us aren't, but we're all passionate about solving big problems and building awesome products. While we're an impressive team (if we do say so ourselves) that works hard, we try to keep our culture ego-free and value creativity, autonomy, and fun.</p>
            </div>
            {/* Accordion start------------- */ }
            <div className='w-2/3 mx-auto flex flex-col gap-4'>
                {
                    accordions.map( accordion =>
                        <div
                            key={ accordion.id }
                            className="collapse border-y rounded-md"
                            onClick={ () => AccordionSelect( accordion.id ) }
                        >
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium flex justify-between items-center">
                                <h1 className='font-semibold'>Focus me to see content</h1>
                                {
                                    sign && selectAccordion === accordion.id ?
                                        <FaMinus className='text-[#00A590]' />
                                        :
                                        <FaPlus className='text-[#00A590]' />
                                }
                            </div>
                            <div className="collapse-content px-10">
                                <p>Some of us are car nuts, some of us aren't, but we're all passionate about solving big problems and building awesome products. While we're an impressive team (if we do say so ourselves) that works hard, we try to keep our culture ego-free and value creativity, autonomy, and fun.

                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
            {/* Accordion start------------- */ }
        </div>
    );
};

export default Contact; <h1>This is contact Page</h1>