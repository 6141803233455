import React, { useContext, useState } from 'react';
import logo from '../../Images/logo/reuse-signinandup-logo.png'
import google from '../../Images/logo/Google.png'
import facebook from '../../Images/logo/Facebook.png'
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../Context/AuthProvider';
import axios from 'axios';


const Login = () => {
    const { Login, googleLogin, facebookLogin } = useContext( AuthContext )
    const [ showPassword, setShowPassword ] = useState( false );
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const [ error, setError ] = useState( '' )


    const location = useLocation();
    let from = location.state?.from?.pathname || '/';


    // Email password Login-----------------
    const onSubmit = data => {

        Login( data?.email, data?.password )
            .then( result => {
                navigate( from, { replace: true } );
            } )
            .catch( error => {
                console.log( error );
            } )

    };

    // Google Login------------------------------
    const handleGoogleLogin = async () => {

        googleLogin()

            .then( result => {
                console.log( result );
                const user = {
                    name: result?.user.displayName,
                    email: result?.user.email,
                    u_id: result?.user.uid,
                    vStatus: result?.user.emailVerified

                }
                saveUsertoDB( user )
                // navigate( '/' )
                navigate( from, { replace: true } );
            } )
            .catch( error => {
                console.log( error );
            } )

    }

    // Facebook Login------------------------------------
    const handleFacebookLogin = () => {

        facebookLogin()

            .then( result => {
                // console.log(result);
                const user = {
                    name: result?.user.displayName,
                    email: result?.user.email,
                    u_id: result?.user.uid,
                    vStatus: result?.user.emailVerified

                }
                saveUsertoDB( user )
                // navigate( '/' )
                navigate( from, { replace: true } );
            } )
            .catch( error => {
                if ( error.code === 'auth/account-exists-with-different-credential' ) {
                    setError( 'Facebook Email Already in Used!' )
                }
            } )

    }


    const saveUsertoDB = ( user ) => {

        // user Save to database done------------- Pintu

        axios.post( 'https://api.reuse.sale/user/api/create.php', {
            "u_fullname": user?.name,
            "u_email": user?.email,
            "u_password": user?.u_id,
            "u_phone": user?.phone,
            "u_image": '',
            "code": '',
            "status": `${ user?.vStatus ? 'true' : 'false' }`,
            "pstatus": '',
            "fburl": '',
            "instaurl": '',
            "waurl": '',
        } )

            .then( res => {
                // console.log(res)
            } )
            .catch( error => {
                console.log( error );
            } )
    }



    return (
        <div className='w-full md:w-1/2 mx-auto shadow-2xl p-10 mt-16 rounded-lg'>
            <div className='w-2/5 mx-auto'>
                <img className='w-full' src={ logo } alt="" />
            </div>
            <h1 className='text-3xl font-bold text-center my-5'>Login</h1>

            {/* Login with google and facebook--------------- */ }
            <div className='grid grid-flow-row md:grid-cols-2 gap-4  md:gap-2'>
                <button onClick={ handleGoogleLogin } className='flex justify-center border rounded-md px-8 text-center py-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={ google } className='w-6' alt="" />
                        <h1 className='font-semibold'>Sign in with Google</h1>
                    </div>
                </button>
                <button onClick={ handleFacebookLogin } className='flex justify-center border rounded-md px-8 text-center py-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={ facebook } className='w-6' alt="" />
                        <h1 className='font-semibold'>Sign in with Facebook</h1>
                    </div>
                </button>
            </div>
            <p className='text-error text-right'>{ error }</p>
            <div className="divider my-4">OR</div>

            {/* Login with Email and password----------- */ }
            <form onSubmit={ handleSubmit( onSubmit ) } className='flex flex-col gap-4'>
                <div>
                    {/* <label>
                        <span className="text-lg font-medium">Email:</span>
                    </label> */}
                    <input type="Email" placeholder="Enter your Email" className="input border-2 text-black font-bold input-bordered w-full bg-transparent"{ ...register( "email", { required: true } ) } />
                </div>

                <div className='relative'>
                    {/* <label>
                        <span className="text-lg font-medium">Password:</span>
                    </label> */}
                    <input type={ showPassword ? 'text' : 'password' } placeholder="Enter your Password" className="input border-2 text-black font-bold input-bordered w-full bg-transparent" { ...register( "password", { required: true } ) } />
                    <div className='absolute top-3 right-4'>
                        {
                            showPassword ?
                                <BsFillEyeSlashFill onClick={ () => setShowPassword( !showPassword ) } className='text-2xl' />
                                :
                                <BsFillEyeFill onClick={ () => setShowPassword( !showPassword ) } className='text-2xl' />
                        }
                    </div>
                </div>
                <button className='btn btn-accent'>Log in</button>
            </form>
            <p className='text-[#589BB1] font-medium mt-2 hover:text-success duration-300'>Forgot password?</p>
            <p className='text-center font-semibold mt-8'>Don't have and account? <Link to='/signup' className='text-[#589BB1] font-semibold hover:text-success duration-300'> Sign Up</Link></p>

        </div>
    );
};

export default Login;