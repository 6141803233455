import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import pic from '../../Images/placeHolder/photo.png'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Swal from 'sweetalert2';


const ImageUpload = ({ setGetImages, getImages }) => {

    // uploaded images are saved here
    const [images, setImages] = React.useState();
    let allImages = [];

    // objectURL for the images are here
    const [url, setUrl] = useState()
    const [url2, setUrl2] = useState()
    const [url3, setUrl3] = useState()
    const [url4, setUrl4] = useState()
    const [url5, setUrl5] = useState()


    /**
     * Description
     * takes in  image and creates a objet url for preview
     * @param {any} e
     * @returns {any}
     * 
     * */



    const handleImageUpload = (e) => {
        const getImages = e.target.files
        setImages(e.target.files)
        // setGetImages( e.target.files )
        const formData = new FormData();

        for (let i = 0; i < getImages?.length; i++) {
            formData.append('file', getImages[i]);

            // Image upload successful----------

            // // fetch( 'https://api.imgbb.com/1/upload?expiration=600&key=1b03953cb11f98867c80d226ce43416c', {


            fetch('https://api.reuse.sale/listing/api/upload.php', {
                method: 'POST',
                body: formData
            })
                .then(res => res.json())
                .then(data => {
                    // console.log( data );
                    allImages.push(data[0])
                    setGetImages(allImages);

                })
                .catch(error => {
                    console.error('Error:', error);

                    Swal.fire(
                        'Ooooop!',
                        'Somthing Wrong Here! Please Check Your update again',
                        'error'
                    )

                });


        }





        if (e.target.files && e.target.files[0]) {

            if (e.target.files[0]) {
                setUrl(URL.createObjectURL(e.target.files[0]));
            }
            if (e.target.files[1]) {
                setUrl2(URL.createObjectURL(e.target.files[1]));
            }
            if (e.target.files[2]) {
                setUrl3(URL.createObjectURL(e.target.files[2]));
            }
            if (e.target.files[3]) {
                setUrl4(URL.createObjectURL(e.target.files[3]));
            }
            if (e.target.files[4]) {
                setUrl5(URL.createObjectURL(e.target.files[4]));

            }

        }



    }

    /**
     * removes all images
     * */
    const handleRemoveImage = () => {

        setImages(null);
        setUrl('');
        setUrl2('');
        setUrl3('');
        setUrl4('');
        setUrl5('');

    }


    return (
        <div>
            <Box className="image-box">

                <Grid container columns={5} spacing={2} sx={{ width: "100%", flexGrow: 1, alignItems: "center", objectFit: "cover" }}>
                    <Grid item xs={12} sm={2} md={1} lg={1}>
                        <img className='border border-info w-full h-full object-cover rounded-lg p-1' src={url ? url : pic} alt="" />
                    </Grid>
                    <Grid item xs={12} sm={2} md={1} lg={1}>
                        <img className='border border-info w-full h-full object-cover rounded-lg p-1' src={url2 ? url2 : pic} alt="" />
                    </Grid>
                    <Grid item xs={12} sm={2} md={1} lg={1}>
                        <img className='border border-info w-full h-full object-cover rounded-lg p-1' src={url3 ? url3 : pic} alt="" />
                    </Grid>
                    <Grid item xs={12} sm={2} md={1} lg={1}>
                        <img className='border border-info w-full h-full object-cover rounded-lg p-1' src={url4 ? url4 : pic} alt="" />
                    </Grid>
                    <Grid item xs={12} sm={2} md={1} lg={1}>
                        <img className='border border-info w-full h-full object-cover rounded-lg p-1' src={url5 ? url5 : pic} alt="" />

                    </Grid>
                </Grid>
            </Box>
            <input
                accept="image/*"

                className={"image-upload"}
                style={{ display: 'none' }}
                id="image-upload-button-file"
                multiple
                type="file"
                onChange={(e) => handleImageUpload(e)}
            // required
            />
            <label htmlFor="image-upload-button-file">
                <Button variant="raised" component="span" sx={{

                    color: "white",
                    fontWeight: "600",
                    mt: 10,
                    width: "50%",
                    mx: 'auto',
                    bgcolor: "brown",
                    "&:hover": {
                        color: "black",
                        bgcolor: "gray",
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 5,

                }} className={"image-upload-btn"}>

                    Upload  <AddAPhotoOutlinedIcon></AddAPhotoOutlinedIcon>
                </Button>
            </label>
            {

                images ? <Button variant="raised" onClick={handleRemoveImage} sx={{

                    color: "white",
                    fontWeight: "600",
                    mt: 2,
                    bgcolor: "red",
                    width: "50%",
                    mx: 'auto',
                    "&:hover": {
                        color: "black",
                        bgcolor: "gray",
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 5,

                }} className={"image-upload-btn"}>

                    Clear  <DeleteForeverIcon></DeleteForeverIcon>
                </Button> : null
            }
        </div>
    )
}

/**
 * Description 
 * function for description , price , Negotiable, Seller Disclosures, Add Contact Number
 * @param{description,price,negotiable,sellerDisclosure,contactNumber} 
 */


export default ImageUpload;