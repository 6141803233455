import React, { useEffect, useState } from 'react';
import { GoLocation } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa";
import { BsSpeedometer2 } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import SingleCar from '../SingleCar/SingleCar';


const MostRecentCar = () => {
    const [allProduct, setAllProduct] = useState([])

    useEffect(() => {
        fetch('https://api.reuse.sale/listing/api/read.php')
            .then(res => res.json())
            .then(data => {
                // console.log( data.body );
                const allProducts = data.body;
                if (data.body.length > 4) {
                    let products = allProducts.slice(0, 4);
                    setAllProduct(products);
                    return;
                }
                setAllProduct(allProducts);
            })
    }, []);
    // console.log( allProduct );
    // console.log( views );

    return (
        <div className='w-11/12 mx-auto rounded-lg md:rounded-none md:w-full bg-[#589BB1] bg-opacity-20 py-10'>
            <div className='w-11/12 mx-auto'>
                <div className='flex justify-between items-center'>
                    <div>
                        <h1 className='text-lg font-bold'>
                            Most Recent Car
                        </h1>
                        <hr className='border w-36 border-[#589BB1]' />
                        <hr className='border w-24 mt-1 border-[#589BB1]' />
                    </div>
                    <Link to='/findavehicle' className='text-[#589BB1] text-xl flex items-center gap-2 hover:text-[#3fbbe4]  duration-500'>
                        <p className='font-semibold'>view all</p>
                        <FaArrowRight />
                    </Link>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-4 mt-5'>
                    {
                        allProduct.map((product, i) => <SingleCar key={i} product={product}></SingleCar>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default MostRecentCar;