import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';

import Container from '@mui/material/Container';
import reuseLogo from '../../Images/logo/reuse-signinandup-logo.png'
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Hidden, Stack, } from '@mui/material';
import './NavAppBar.css'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import SwipeableTemporaryDrawer from '../Drawer/Drawer';
import { useContext } from 'react';
import { searchContext } from '../../Context/SearchContext/SearchContext';
import { useEffect } from 'react';
import { useState } from 'react';



function NavAppBar({ currentRoute }) {
    const navigate = useNavigate();
    const { setSearchText } = useContext(searchContext);
    const [models, setModels] = useState([])


    // get all model form CarModels.json file-------------
    useEffect(() => {
        fetch('CarModels.json')
            .then(res => res.json())
            .then(data => {
                setModels(data);
            })
    }, [])


    const Search = styled('div')(({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: "#E7E7E7",
        '&:hover': {

            backgroundColor: alpha(theme.palette.common.white, 0.80),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    }));

    const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "#171827"

    }));

    const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'black',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '12ch',
                '&:focus': {
                    width: '20ch',
                },
            },
        },

    }));


    const handleSearch = (e) => {
        e.preventDefault()
        if (e.target.value === '') {
            return;
        }

        const search = (e.target.value);
        setSearchText(search?.toLowerCase())
        navigate('/findavehicle')

    }



    return (
        <div className='sticky top-0 md:top-6 z-10'>


            <AppBar color={currentRoute?.pathname === '/' ? 'primary' : 'secondary'} sx={{ borderBottom: { xs: "2px solid #589BB1", sm: "none" }, position: { xs: "static", sm: "static" }, top: { xs: 0, sm: "none" } }}>

                <Container sx={{ width: "91%", paddingTop: '5px', paddingBottom: '5px', display: "flex", alignItems: "center", justifyContent: "center", gap: { sm: "1em", md: '1.5em', lg: "5em", xl: '5em' } }} >
                    {/* Pintu comment-------*/}
                    {/* <Toolbar sx={ { width: { xs: "100%", sm: 163, md: 163, lg: 200 } } }>
                        <img width={ "100%" } height={ "auto" } src={ reuseLogo } alt="reuse-logo" />
                    </Toolbar> */}
                    {/* Pintu comment------- */}


                    {/* Pintu add------------------ */}
                    <Link to='/'>
                        <img className='w-3/4' src={reuseLogo} alt="" />
                    </Link>
                    {/* Pintu add------------------ */}

                    <Container
                        sx={{ maxWidth: { xs: "500px", sm: "800px", md: "900px", lg: "1200px", xl: "1440px" }, display: "flex", alignItems: "center", justifyContent: "space-between", gap: { sm: "1em", md: "1.5em", lg: '5em', xl: '5em' } }} >
                        {/* <Hidden smDown> */}
                        <Hidden mdDown>
                            <Stack className={currentRoute?.pathname === '/' ? 'nav-div-li' : 'nav-div-liWhite'} direction={"row"} gap={{ sm: "1em", md: "1.5em", lg: '2em', xl: '2em' }}>
                                <NavLink to={'/'}>
                                    <p className='text-sm lg:text-base'>Home</p>
                                </NavLink>
                                <NavLink to={'findavehicle'}>
                                    <p className='text-sm lg:text-base'>Find Vehicle</p>
                                </NavLink>
                                <NavLink to={'howitworks'}>
                                    <p className='text-sm lg:text-base'>How It Works</p>
                                </NavLink>
                                <NavLink to={'faq'}>

                                    <p className='text-sm lg:text-base'>FAQ</p>
                                </NavLink>
                                {/* <NavLink to={ '/alluser' }>
                                    <p className='text-sm lg:text-base'>Alluser</p>
                                </NavLink> */}
                            </Stack>

                            <Stack sx={{
                                alignItems: 'center'
                            }} direction={"row"} gap={{ sm: "1em", md: "1.5em", lg: '2em', xl: '2em' }}>
                                <div onChange={handleSearch}>

                                    <div className='relative '>
                                        <SearchIconWrapper>
                                            <SearchIcon />
                                        </SearchIconWrapper>

                                        {/* <StyledInputBase
                                            type="text"
                                            list="cars"
                                            name='search'
                                            placeholder="Search…"
                                            inputProps={ { 'aria-label': 'search' } }
                                        /> */}

                                        <input
                                            list="cars"
                                            type="text"
                                            name='search'
                                            placeholder="Search…"
                                            className="input input-sm border-[#2a96ba] rounded-lg text-black input-bordered w-full bg-[#E0E0E0] pl-10" />

                                        <datalist id="cars">
                                            <option></option>
                                            {

                                                models.map(model => <option key={model.id}>{model.name}</option>)

                                            }
                                        </datalist>

                                    </div>
                                </div >


                                {/* Pintu comment----------- */}

                                {/* <Box >
                                    <NavLink to={ 'postyourad' }>
                                        <Button disableElevation variant="contained" sx={ {
                                            py: 1, bgcolor: "#589BB1", '&:hover': {
                                                backgroundColor: '#4E6C50',
                                            },
                                        } }>
                                            <Typography variant="p" color="white">Post Your Ad</Typography>
                                        </Button>
                                    </NavLink>
                                </Box> */}

                                {/* Pintu comment----------- */}

                                <div className='btn bg-[#2a96ba] text-white border-none hover:bg-[#24a8d4] btn-sm'>
                                    <Link to='/advertise' >
                                        <h1>Post Your Ad</h1>
                                    </Link>
                                </div>
                            </Stack>
                        </Hidden>

                    </Container>

                    {/* <Hidden smUp> */}
                    <Hidden mdUp>
                        <SwipeableTemporaryDrawer currentRoute={currentRoute}></SwipeableTemporaryDrawer>

                    </Hidden>
                </Container>

            </AppBar>

        </div >
    );
}
export default NavAppBar;