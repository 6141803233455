import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import timezone from 'dayjs/plugin/timezone'


const DateTime = () => {
    const [ date, setDate ] = useState();

    dayjs.extend( timezone );
    const tz = dayjs.tz.guess();

    useEffect( () => {
        setInterval( () => {
            const date = dayjs().format( 'DD-MMM-YY, dddd , hh:mm:ss A ' )
            setDate( date );
        }, 1000 )
    }, [] )




    return (
        <div>
            <div >
                <p>Current Time: { date } , { tz }</p>
            </div>
        </div>
    );
};

export default DateTime;