import React from 'react';
import reuseLogo from '../../Images/logo/reuse-signinandup-logo.png'

const LoadingPage = () => {
    return (
        <div className='text-9xl text-center mt-auto'>
            <img src={ reuseLogo } alt="" />
        </div>
    );
};

export default LoadingPage;