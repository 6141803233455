import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import './Drawer.css'
import Typography from '@mui/material/Typography'
import { Stack, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import Location from '../../Location/Location';
import TranslateSelect from '../TranslateSelect/TranslateSelect';
import SignInModal from '../SignInModal/SignInModal';
import DateTime from '../DateTime/DateTime';
// import ProfileMenu from '../ProileMenu/ProfileMenu';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase.init';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthProvider';

export default function SwipeableTemporaryDrawer({ currentRoute }) {
    const { user, logOut } = useContext(AuthContext);
    const navigate = useNavigate()

    // console.log( currentRoute );
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')

        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const handleLogout = () => {
        logOut()
            .then(result => {
                navigate('/')
            })
            .catch(error => {
                console.log(error);
            })
    }

    const list = (anchor) => (


        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300, color: '#1C315E' }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            color="primary"
        >
            <List >
                <ListItem sx={{ width: "80%", mx: "auto" }}>

                    <DateTime></DateTime>
                </ListItem>
                <Divider></Divider>
                <ListItem disablePadding>
                    <Stack alignItems={"start"} pt="1em" className='drawer-menu' >
                        <NavLink to={'/'} end>

                            <ListItemButton>
                                <Typography variant="p" color="black">Home</Typography>
                            </ListItemButton>
                        </NavLink>
                        <NavLink to={'findavehicle'}>
                            <ListItemButton>
                                <Typography variant="p" color="black">Find Vehicle</Typography>
                            </ListItemButton>
                        </NavLink>
                        <NavLink to={'howitworks'}>
                            <ListItemButton>
                                <Typography variant="p" color="black">How It Works</Typography>
                            </ListItemButton>
                        </NavLink>
                        <NavLink to={'faq'}>
                            <ListItemButton>
                                <Typography variant="p" color="black">FAQ</Typography>
                            </ListItemButton>
                        </NavLink>
                        <NavLink to={'contact'}>
                            <ListItemButton>
                                <Typography variant="p" color="black">Contact</Typography>
                            </ListItemButton>
                        </NavLink>
                        <NavLink to={'blog'}>
                            <ListItemButton>
                                <Typography variant="p" color="black">Blog</Typography>
                            </ListItemButton>
                        </NavLink>
                        <NavLink to={'/advertise'}>
                            <ListItemButton>
                                <Typography variant="p" color="black">Post Your Ad</Typography>
                            </ListItemButton>
                        </NavLink>
                    </Stack>
                </ListItem>
            </List>
            <Divider />
            <List >
                <ListItem disablePadding sx={{ display: "flex", flexDirection: "column", alignItems: "start", }}>
                    <ListItemButton>
                        <Location></Location>
                    </ListItemButton>
                    <ListItemButton>
                        <TranslateSelect color={'#1C315E'}></TranslateSelect>
                    </ListItemButton>
                    <ListItemButton className='listbtn-signin' sx={{ width: "80%", bgcolor: "#589BB1", borderRadius: '5px', mt: 4, mx: 'auto', p: 0, py: 1 }} >
                        <Container className="signin-button-drawer">
                            <div className='w-full mx-auto py-2'>
                                {/* <SignInModal></SignInModal> */}
                                {
                                    // !currentUser ? <SignInModal currentRoute={ currentRoute }></SignInModal> :
                                    //     <Stack spacing={ 6 }>
                                    //         <Typography variant='h6'>Welcome, { currentUser.displayName }</Typography>

                                    //         <Link to={ 'profile' }><Button sx={ { width: "100%", bgcolor: "green" } }>Profile</Button></Link>
                                    //         {/* <Button onClick={ () => signOut( auth ) } sx={ { bgcolor: "green" } }>Sign Out</Button> */ }
                                    //         <Button sx={ { bgcolor: "green" } }>Sign Out</Button>
                                    //     </Stack>
                                }
                                {
                                    user ?
                                        <div className='flex flex-col gap-2'>
                                            <Link to='/userprofile'>
                                                <button className='btn btn-success w-full text-lg text-white font-bold'>User Profile</button>
                                                {/* <button className='btn btn-success w-full text-lg text-white font-bold'>{user?.displayName ? user?.displayName : user?.email}</button> */}
                                            </Link>
                                            <button onClick={handleLogout} className='btn btn-error w-full text-white'>
                                                LogOut
                                            </button>
                                        </div>
                                        :
                                        <Link to='/login' className='btn bg-[#24b9eb] border-none w-full text-lg text-white font-bold'>
                                            Sign In
                                        </Link>
                                }
                            </div>
                        </Container>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button sx={{ bgcolor: "inherit" }} className='menu-button-mbl' onClick={toggleDrawer(anchor, true)}><MenuIcon sx={{ color: currentRoute?.pathname === '/' ? "#9E9797" : "white" }}></MenuIcon></Button>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}

        </div>
    );
}