import React from 'react';
import { FaBars, FaSearch } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import reuseLogo from '../../Images/logo/reuse-signinandup-logo.png'

const menuItems =
    <>
        <li>Home</li>
        <li>Find Vehicle</li>
        <li>How it Work</li>
        <li>FAQ</li>
    </>

const Navbar = () => {
    return (
        <div className="navbar px-10 py-0 shadow-lg sticky top-0 bg-white z-10">
            {/* <div className='w-11/12 mx-auto'> */ }
            <div className="navbar-start">
                <div className="dropdown">
                    <label tabIndex={ 0 } className=" md:hidden">
                        <FaBars className='text-2xl' />
                    </label>
                    <ul tabIndex={ 0 } className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-white rounded-box w-52">
                        { menuItems }
                    </ul>
                </div>
                <Link to='/'>
                    <img className='w-2/4' src={ reuseLogo } alt="" />
                </Link>
            </div>
            <div className="navbar-center hidden lg:flex">
                <ul className="menu menu-horizontal px-1">
                    { menuItems }
                </ul>
            </div>
            <div className="navbar-end">
                <div className='flex gap-4 items-center justify-end'>
                    <div className='flex items-center relative'>
                        <FaSearch className='absolute left-3 text-lg' />
                        <input type="text" placeholder="Search" className="input input-bordered input-sm bg-transparent text-md px-10" />
                    </div>
                    <div className='btn btn-accent btn-sm'>
                        <Link to='/advertise' >
                            <h1>Post Your Ad</h1>
                        </Link>
                    </div>
                </div>
                {/* </div> */ }
            </div>
        </div >
        // <div className="navbar bg-base-100">
        //     <div className="navbar-start">
        //         <div className="dropdown">
        //             <label tabIndex={ 0 } className="btn btn-ghost lg:hidden">
        //                 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
        //             </label>
        //             <ul tabIndex={ 0 } className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
        //                 <li><a>Item 1</a></li>
        //                 <li tabIndex={ 0 }>
        //                     <a className="justify-between">
        //                         Parent
        //                         <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" /></svg>
        //                     </a>
        //                     <ul className="p-2">
        //                         <li><a>Submenu 1</a></li>
        //                         <li><a>Submenu 2</a></li>
        //                     </ul>
        //                 </li>
        //                 <li><a>Item 3</a></li>
        //             </ul>
        //         </div>
        //         <Link to='/'>
        //             <img className='w-2/4' src={ reuseLogo } alt="" />
        //         </Link>
        //     </div>
        //     <div className="navbar-center hidden lg:flex">
        //         <ul className="menu menu-horizontal px-1">
        //             <li><a>Item 1</a></li>
        //             <li tabIndex={ 0 }>
        //                 <a>
        //                     Parent
        //                     <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
        //                 </a>
        //                 <ul className="p-2">
        //                     <li><a>Submenu 1</a></li>
        //                     <li><a>Submenu 2</a></li>
        //                 </ul>
        //             </li>
        //             <li><a>Item 3</a></li>
        //         </ul>
        //     </div>
        //     <div className="navbar-end">
        //         <a className="btn">Get started</a>
        //     </div>
        // </div>
    );
};

export default Navbar;