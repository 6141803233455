import React from 'react';
import './TranslateSelect.css'
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LanguageIcon from '@mui/icons-material/Language';
import Typography from '@mui/material/Typography'




const TranslateSelect = ( { color, currentRoute } ) => {

    // const route = currentRoute.pathName;
    // console.log( currentRoute.pathname );
    const [ language, setLanguage ] = React.useState( '' );

    const handleChange = ( event ) => {
        setLanguage( event.target.value );

    };

    // console.log( language );

    return (
        <Box sx={ { maxHeight: 10, display: "flex", alignItems: "center", justifyItems: "center", border: "none" } }>
            <LanguageIcon sx={ { fontSize: "16px" } }></LanguageIcon>
            <FormControl fullWidth size='small'>
                <Select
                    value={ language }
                    onChange={ handleChange }
                    inputProps={ { 'aria-label': 'language select box' } }
                    sx={ {
                        color: currentRoute?.pathname === '/' ? "#B6B0B0" : "#171827", border: "none", '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                        }, '& .MuiSvgIcon-root': {
                            color: color,
                        }
                    } }
                    placeholder={ "Language" }
                    displayEmpty

                >
                    <MenuItem value="" sx={ { color: "#B6B0B0" } } >
                        <Typography variant={ currentRoute?.pathname === '/' ? "text1" : 'text1alt' } >Language</Typography>

                    </MenuItem>
                    <MenuItem value={ "en" } sx={ { fontSize: "16px" } }><Typography variant={ currentRoute?.pathname === '/' ? "text1" : 'text1alt' } >English</Typography></MenuItem>
                    <MenuItem value={ "fr" } sx={ { fontSize: "16px" } }><Typography variant={ currentRoute?.pathname === '/' ? "text1" : 'text1alt' } >French</Typography></MenuItem>
                </Select>

            </FormControl>
        </Box >
    );
};


export default TranslateSelect;