import React, { createContext, useContext } from 'react';
import { useState } from 'react';

export const LocationContext = createContext()

const LocationProvider = ({ children }) => {
    const [location, setLocation] = useState()

    const locationInfo = { location, setLocation };
    return (
        <LocationContext.Provider value={locationInfo}>
            {children}
        </LocationContext.Provider>
    );
};

export default LocationProvider;