import React, { useContext, useEffect, useState } from 'react';
import { FaCar } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { searchContext } from '../../Context/SearchContext/SearchContext';

// category.map( item => {
//     console.log( item.color );
// } )

const BrowseItems = () => {
    const [ allVehicle, setAllVehicle ] = useState( [] );
    const [ car, setCar ] = useState( '' );
    const [ motorbikes, setMotorBikes ] = useState( '' );
    const [ bicycle, setBicycle ] = useState( '' );
    const [ trucks, setTrucks ] = useState( '' );
    const [ buses, setBuses ] = useState( '' );
    const [ taxi, setTaxi ] = useState( '' );
    const [ ambulance, setAmbulance ] = useState( '' );
    const [ tractor, setTractor ] = useState( '' );
    const { setCategory } = useContext( searchContext );
    const navigate = useNavigate()


    useEffect( () => {
        fetch( 'https://api.reuse.sale/listing/api/read.php' )
            .then( res => res.json() )
            .then( data => {
                const allAds = data?.body;
                if ( allAds?.length ) {
                    const allCars = allAds?.filter( ads => ads?.list_vehicletype === "Car" );
                    setCar( allCars )
                    const allMotorBikes = allAds?.filter( ads => ads?.list_vehicletype === "Motorbikes" )
                    setMotorBikes( allMotorBikes )
                    const allBicycle = allAds?.filter( ads => ads?.list_vehicletype === "Bicycles" );
                    setBicycle( allBicycle )
                    // const allTrucks = allAds?.filter( ads => ads?.list_vehicletype === "Trucks" );
                    const allTrucks = allAds?.filter( ads => ads?.list_vehicletype === "Consumer Trucks" );
                    setTrucks( allTrucks )
                    const allBuses = allAds?.filter( ads => ads?.list_vehicletype === "Bus" );
                    setBuses( allBuses )
                    const allTaxi = allAds?.filter( ads => ads?.list_vehicletype === "Taxi" );
                    setTaxi( allTaxi )
                    const allAmbulance = allAds?.filter( ads => ads?.list_vehicletype === "Ambulance" );
                    setAmbulance( allAmbulance )
                    const allTractor = allAds?.filter( ads => ads?.list_vehicletype === "Tractor" );
                    setTractor( allTractor )
                }
                else {
                    console.log( 'hello' );
                    setAllVehicle( data.body );
                }

            } )
    }, [] );

    const categories = [
        {
            id: '01',
            category: 'Cars',
            name: 'Car',
            addNo: `${ car?.length }`,
            color: '#589BB1'
        },
        {
            id: '02',
            category: 'Motorbikes',
            name: 'Motorbikes',
            addNo: `${ motorbikes?.length }`,
            color: '#F39893'
        },
        {
            id: '03',
            category: 'Bicycles',
            name: 'Bicycles',
            addNo: `${ bicycle?.length }`,
            color: '#7C87A5'
        },
        {
            id: '04',
            category: 'Trucks',
            name: 'Trucks',
            addNo: `${ trucks?.length }`,
            color: '#ABD194'
        },
        {
            id: '05',
            category: 'Buses',
            name: 'Buses',
            addNo: `${ buses?.length }`,
            color: '#21898C'
        },
        {
            id: '06',
            category: 'Taxi',
            name: 'Taxi',
            addNo: `${ taxi?.length }`,
            color: '#B88CBF'
        },
        {
            id: '07',
            category: 'Ambulance',
            name: 'ambulance',
            addNo: `${ ambulance?.length }`,
            color: '#5CC8B9'
        },
        {
            id: '08',
            category: 'Tractor',
            name: 'Tractor',
            addNo: `${ tractor?.length }`,
            color: '#8786FB'
        },
    ]

    const handleCategory = ( name ) => {
        // console.log( category );
        setCategory( name );
        navigate( '/category' )
    }


    return (
        <div className='w-11/12 mx-auto my-20'>
            <h1 className='text-lg font-bold'>
                Browse Items By Category
            </h1>
            <hr className='border w-36 border-[#589BB1]' />
            <hr className='border w-24 mt-1 border-[#589BB1]' />
            <div className='bg-none grid grid-cols-3 md:grid-cols-4 gap-4 mt-5'>
                {
                    categories.map( category =>

                        <div
                            onClick={ () => handleCategory( category?.name ) }
                            key={ category.id }
                            className='shadow-md py-0 md:py-4 rounded-lg flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start gap-5'>
                            <div
                                style={ { backgroundColor: `${ category.color }` } }
                                className={ `flex justify-center items-center h-24 md:h-16 w-24 md:w-16 rounded-b-md md:rounded-b-none md:rounded-br-md rounded-md-none md:rounded-r-md` }>
                                <FaCar className='text-white text-3xl' />
                            </div>
                            <div className='mb-5 md:mb-0 text-center'>
                                <h1 className='font-semibold'>{ category.category }</h1>
                                <h2 className='text-gray-400'>{ category?.addNo ? category?.addNo : 0 } ads</h2>
                            </div>
                        </div>
                        // <Link
                        //     to='/category'
                        //     key={ category.id }
                        //     className='shadow-md py-0 md:py-4 rounded-lg flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start gap-5'>
                        //     <div
                        //         style={ { backgroundColor: `${ category.color }` } }
                        //         className={ `flex justify-center items-center h-24 md:h-16 w-24 md:w-16 rounded-b-md md:rounded-b-none md:rounded-br-md rounded-md-none md:rounded-r-md` }>
                        //         <FaCar className='text-white text-3xl' />
                        //     </div>
                        //     <div className='mb-5 md:mb-0 text-center'>
                        //         <h1 className='font-semibold'>{ category.category }</h1>
                        //         <h2 className='text-gray-400'>{ category?.addNo ? category?.addNo : 0 } ads</h2>
                        //     </div>
                        // </Link>
                    )
                }

            </div>
        </div>
    );
};

export default BrowseItems;