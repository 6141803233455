import React from 'react';
import tesla from '../../Images/hero/tesla.jpg'
import { GoLocation } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa";
import { BsSpeedometer2 } from 'react-icons/bs';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SingleCar from '../SingleCar/SingleCar';


// const cards = [
//     {
//         id: 1
//     },
//     {
//         id: 2
//     },
//     {
//         id: 3
//     },
//     {
//         id: 4
//     }
// ]

const TopViewsCar = () => {
    const [topViews, setTopViews] = useState([]);
    const [topViewsProduct, setTopviewsProduct] = useState([])


    // get all veiws----------------

    // useEffect( () => {
    //     fetch( 'https://api.reuse.sale/count/api/topview.php' )
    //         .then( res => res.json() )
    //         .then( data => {
    //             let items = []
    //             const topViewedProduct = data?.body;
    //             for ( const product of topViewedProduct ) {
    //                 // console.log( product.list_id );
    //                 fetch( `https://api.reuse.sale/listing/api/single_read.php?list_id=${ product?.list_id }` )
    //                     .then( res => res.json() )
    //                     .then( data => {
    //                         console.log( data );
    //                     } )
    //                 setTopViews( items )
    //             }
    //         } );
    // }, [] );

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch('https://api.reuse.sale/count/api/topview.php')
            const data = await res.json()

            for (const item of data.body) {
                const viewsData = async () => {
                    const res = await fetch(`https://api.reuse.sale/listing/api/single_read.php?list_id=${item?.list_id}`)
                    const data = await res.json();
                    // console.log( data );
                    setTopViews(prevData => [...prevData, data]);
                }
                viewsData()
            }
        }
        fetchData()

    }, [])



    // get unique top views product--------------
    const key = 'list_id';
    const topViewsCar = [...new Map(topViews.map(item =>
        [item[key], item])).values()];


    const LimitViewsProduct = () => {
        if (topViewsCar?.length > 4) {
            let products = topViewsCar?.slice(0, 4);
            return products;
        }
    }

    const products = LimitViewsProduct();


    return (
        <div className='w-11/12 mx-auto rounded-lg md:rounded-none md:w-full py-10'>
            <div className='w-11/12 mx-auto'>
                <div className='flex justify-between items-center'>
                    <div>
                        <h1 className='text-lg font-bold'>
                            Top Views Car
                        </h1>
                        <hr className='border w-36 border-[#589BB1]' />
                        <hr className='border w-24 mt-1 border-[#589BB1]' />
                    </div>

                    <Link to='/findavehicle' className='text-[#589BB1] text-xl flex items-center gap-2 hover:text-[#3fbbe4]  duration-500'>
                        <p className='font-semibold'>view all</p>
                        <FaArrowRight />
                    </Link>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-2 md:gap-4 mt-5'>
                    {
                        products?.map((product, i) => <SingleCar key={i} product={product}></SingleCar>)
                    }
                </div>
            </div>
        </div>
    );
};

export default TopViewsCar;