import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { Stack } from '@mui/system';
import './TopAppBar.css'
import DateTime from '../DateTime/DateTime';
import SignInModal from '../SignInModal/SignInModal';
import Location from '../../Location/Location';
import TranslateSelect from '../TranslateSelect/TranslateSelect';
import ProfileMenu from '../ProileMenu/ProfileMenu';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { AuthContext } from '../../Context/AuthProvider';



const TopAppBar = ({ currentRoute }) => {
    const { user, logOut } = useContext(AuthContext);
    const navigate = useNavigate();
    // console.log( user );
    // console.log( user?.phoneNumber );
    // console.log( user?.photoURL );

    // seems container do not need { marginX: "auto" } to center fixed maxWidth div

    // console.log( currentRoute.pathname );

    const handleLogout = () => {
        logOut()
            .then(result => {
                navigate('/')
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <div className='sticky top-0 z-20'>
            <AppBar position="static" color={currentRoute?.pathname === '/' ? 'secondary' : 'primary'} >
                <Container sx={{ maxWidth: { xs: "500px", sm: "800px", md: "900px", lg: "1200px", xl: "1440px" }, display: { xs: 'none', sm: 'block' } }}>
                    <Toolbar variant='dense' sx={{ minHeight: 25, maxWidth: '100%', display: "flex", alignItems: "center", justifyContent: "center", }}>

                        <Typography variant={currentRoute?.pathname === '/' ? "text1" : 'text1alt'} sx={{ flexGrow: 1, flexShrink: 1 }}>
                            {/* Date and Time */}
                            <DateTime></DateTime>
                        </Typography>
                        <Stack direction={"row"} spacing={3} sx={{ display: "flex", alignItems: "center", justifyContent: "space-center", }}>
                            <Typography variant={currentRoute?.pathname === '/' ? "text1" : 'text1alt'} sx={{}}>
                                <Location currentRoute={currentRoute}></Location>
                            </Typography>
                            <div className='text-[#B6B0B0]'>|</div>
                            <Typography variant={currentRoute?.pathname === '/' ? "text1" : 'text1alt'} sx={{ fontSize: 1 }}>
                                <TranslateSelect currentRoute={currentRoute} color={currentRoute?.pathname === '/' ? "white" : '#171827'}></TranslateSelect>
                            </Typography>
                            <div className='text-[#B6B0B0]'>|</div>

                            {/* <Button variant='text1' disableElevation sx={ { p: 0, fontStyle: 'normal', textTransform: "none" } } onClick={ handleCLick } >
                                <Typography variant="text1" sx={ { fontStyle: "normal", } }>Sign In</Typography>
                            </Button> */}
                            <div className='modal-in-top-bar flex items-center'>
                                {/* {
                                    !user ?
                                        <SignInModal currentRoute={ currentRoute }></SignInModal>
                                        :
                                        <ProfileMenu currentRoute={ currentRoute }></ProfileMenu>
                                } */}
                                {
                                    user ?
                                        <div className="dropdown dropdown-end">
                                            <button tabIndex={0} className={currentRoute?.pathname === '/' ? "text-[#B6B0B0]" : 'text1alt'}>{user?.displayName ? user?.displayName : user?.email}
                                            </button>
                                            <div tabIndex={0} className="dropdown-content menu w-auto rounded-lg px-5 py-3 gap-4 bg-white">
                                                {/* <Link to='/profile'>
                                                    <button className='btn btn-sm btn-success'>Profile</button>
                                                </Link> */}
                                                <Link to='/userprofile'>
                                                    <button className='btn btn-sm btn-success'>Profile</button>
                                                </Link>
                                                <button onClick={handleLogout} className='btn btn-sm btn-error'>LogOut</button>
                                            </div>
                                        </div>
                                        :
                                        <Link to='/login' className={currentRoute?.pathname === '/' ? "text-[#B6B0B0]" : 'text1alt'}>
                                            Sign In
                                        </Link>
                                }
                                {/* <Link to='/login' className={ currentRoute?.pathname === '/' ? "text-[#B6B0B0]" : 'text1alt' }>
                                    Sign In
                                </Link> */}
                            </div>

                        </Stack>

                    </Toolbar>
                </Container>
            </AppBar>
        </div >
    );
};

export default TopAppBar;