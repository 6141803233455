import axios from 'axios';
import { format } from 'date-fns';
import addDays from 'date-fns/addDays'
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { AuthContext } from '../../Context/AuthProvider';
import AdditionalInfo from '../AdditionalInfo/AdditionalInfo';
import ContactDetails from '../ContactDetails/ContactDetails';
import FormReset from '../FormReset/FormReset';
import PersonalInfo from '../PersonalInfo/PersonalInfo';

import { LocationContext } from '../../Context/LocationProvider/LocationProvider';

const Advertise = () => {
    const { user } = useContext(AuthContext)
    const [activeStep, setActiveStep] = useState(0)
    const [personalInfo, setPersonalInfo] = useState({})
    const [getImages, setGetImages] = useState([]);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [saveUser, setSaveUser] = useState();
    const { location } = useContext(LocationContext)

    // console.log(location);


    const onSubmit = data => {
        setPersonalInfo({ ...personalInfo, ...data });
        // reset()
    };

    useEffect(() => {
        fetch(`https://api.reuse.sale/user/api/authentication.php?u_password=${user?.uid}`)
            .then(res => res.json())
            .then(data => {
                window.scrollTo(0, 0)
                setSaveUser(data);
                // console.log( data );
            })
    }, [user?.uid]);


    // console.log( saveUser?.u_id );

    // Post Date----------------
    const current = new Date()
    const publishedDate = format(current, 'E, d LLL y pp O');


    const result = addDays(new Date(), 7)
    const archiveDate = format(result, 'E, d LLL y pp O');



    const handleNext = (event) => {
        setActiveStep(activeStep + 1)

    }
    const handleBack = (event) => {
        event.preventDefault()
        if (activeStep === 0) {
            return
        }
        setActiveStep(activeStep - 1)

        // getStepsOutput( activeStep );
    }


    const handleFinish = () => {
        setActiveStep(0)
    }

    const details = {
        "list_productname": `${personalInfo?.brand}_${personalInfo?.model}`,
        "list_description": personalInfo?.description,
        "list_address": `${personalInfo?.city ? personalInfo.city : location?.state} , ${personalInfo?.country ? personalInfo.country : location?.country} `,
        "list_image": 'image',
        "list_publishdate": publishedDate,
        "list_price": personalInfo?.price,
        "list_condition": personalInfo?.condition,
        "list_fueltype": personalInfo?.fuel,
        "list_registrationyear": personalInfo?.reg_year,
        "list_vehicletype": personalInfo?.vehicle_Type,
        "list_model": personalInfo?.model,
        "list_enginecapacity": personalInfo?.engine_capacity,
        "list_mileage": personalInfo?.mileage,
        "list_brand": personalInfo?.brand,
        "list_transmission": personalInfo?.transmission,
        "list_negotiable": personalInfo?.negotiable,
        "list_phone": personalInfo?.phone,
        "image_one": getImages && getImages[0],
        "image_two": getImages && getImages[1],
        "image_three": getImages && getImages[2],
        "image_four": getImages && getImages[3],
        "image_five": getImages && getImages[4],
        "u_id": saveUser?.u_id,
        "vin": personalInfo?.vin_number,
        "interior": personalInfo?.interior,
        "exterior": personalInfo?.exterior,
        "trimm": personalInfo?.trim,
        "title_type": personalInfo?.title_type,
        "body_style": personalInfo?.body_style,
        "cylinders": personalInfo?.cylinders,
        "doors": personalInfo?.doors,
        "disclosure": personalInfo?.disclosure,
        "archivedate": archiveDate,

    }

    const submitPost = () => {
        const listingAPI = 'https://api.reuse.sale/listing/api/create.php';
        console.log(details);

        // Product details sent to database -------------------------Pintu success

        // axios.post(listingAPI, {
        //     "list_productname": `${personalInfo?.brand}_${personalInfo?.model}`,
        //     "list_description": personalInfo?.description,
        //     "list_address": `${personalInfo?.city} , ${personalInfo?.country}`,
        //     // "list_address": `${ personalInfo?.city } ${ personalInfo?.state } ${ personalInfo?.country }`,
        //     "list_image": 'image',
        //     "list_publishdate": publishedDate,
        //     "list_price": personalInfo?.price,
        //     "list_condition": personalInfo?.condition,
        //     "list_fueltype": personalInfo?.fuel,
        //     "list_registrationyear": personalInfo?.reg_year,
        //     "list_vehicletype": personalInfo?.vehicle_Type,
        //     "list_model": personalInfo?.model,
        //     "list_enginecapacity": personalInfo?.engine_capacity,
        //     "list_mileage": personalInfo?.mileage,
        //     "list_brand": personalInfo?.brand,
        //     "list_transmission": personalInfo?.transmission,
        //     "list_negotiable": personalInfo?.negotiable,
        //     "list_phone": personalInfo?.phone,
        //     "image_one": getImages && getImages[0],
        //     "image_two": getImages && getImages[1],
        //     "image_three": getImages && getImages[2],
        //     "image_four": getImages && getImages[3],
        //     "image_five": getImages && getImages[4],
        //     "u_id": saveUser?.u_id,
        //     "vin": personalInfo?.vin_number,
        //     "interior": personalInfo?.interior,
        //     "exterior": personalInfo?.exterior,
        //     "trimm": personalInfo?.trim,
        //     "title_type": personalInfo?.title_type,
        //     "body_style": personalInfo?.body_style,
        //     "cylinders": personalInfo?.cylinders,
        //     "doors": personalInfo?.doors,
        //     "disclosure": personalInfo?.disclosure,
        //     "archivedate": archiveDate,

        // })
        //     .then(res => {
        //         if (res.data === "Success") {
        //             Swal.fire(
        //                 'Good job!',
        //                 'Your Car in on going to Approved by Admin!',
        //                 'success'
        //             )
        //             reset()
        //         }
        //     }).catch(err => {

        //         Swal.fire(
        //             'Ooooop!',
        //             'Somthing Wrong Here! Please Check Your update again',
        //             'error'
        //         )
        //         console.log(err);
        //     })


    }


    // Active steps-----------start

    const getStepsOutput = (activeStep) => {
        switch (activeStep) {
            case 0:
                return (
                    <PersonalInfo
                        setPersonalInfo={setPersonalInfo}
                        personalInfo={personalInfo}
                        getStepsOutput={getStepsOutput}
                        activeStep={activeStep}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        handleFinish={handleFinish}

                    />
                )
            case 1:
                return (
                    <AdditionalInfo

                        personalInfo={personalInfo}
                        setPersonalInfo={setPersonalInfo}
                        getStepsOutput={getStepsOutput}
                        activeStep={activeStep}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        handleFinish={handleFinish}

                    />
                )
            case 2:
                return (
                    <ContactDetails

                        getImages={getImages}
                        personalInfo={personalInfo}
                        setPersonalInfo={setPersonalInfo}
                        getStepsOutput={getStepsOutput}
                        setGetImages={setGetImages}
                        activeStep={activeStep}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        handleFinish={handleFinish}

                    />
                )
            case 3:
                return (
                    <FormReset

                        submitPost={submitPost}
                        personalInfo={personalInfo}
                        setPersonalInfo={setPersonalInfo}
                        getStepsOutput={getStepsOutput}
                        activeStep={activeStep}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        handleFinish={handleFinish}
                    ></FormReset>
                )
            default:
                throw new Error('Unknown step');
        }
    }


    return (

        <div className='w-11/12 md:w-10/12 mx-auto md:flex gap-2 pb-5'>
            <div className='basis-1/4 md:min-h-screen flex flex-row md:flex-col md:gap-8 mt-5 justify-center md:justify-start items-center md:items-start md:mt-24'>
                <div className='flex gap-3 md:items-center'>
                    <button className={activeStep === 0 ? 'btn btn-circle text-[#589BB1] border-[#589BB1] btn-outline' : 'btn btn-circle btn-outline'}>
                        1
                    </button>
                    <h1 className={activeStep === 0 ? 'text-[#589BB1] hidden md:block' : 'hidden md:block'}>Personal Information</h1>
                </div>
                {/* <hr className='border-2 w-28 md:hidden' /> */}
                <hr className={activeStep === 0 ? 'border-2 border-[#589BB1] w-28 md:hidden' : 'border-2 w-28 md:hidden'} />
                <div className='flex gap-3 md:items-center'>
                    <button className={activeStep === 1 ? 'btn btn-circle text-[#589BB1] border-[#589BB1] btn-outline' : 'btn btn-circle btn-outline'}>
                        2
                    </button>
                    <h1 className={activeStep === 1 ? 'text-[#589BB1] hidden md:block' : 'hidden md:block'} >Additional Information</h1>
                </div>

                {/* <hr className='border-2 w-28 md:hidden' /> */}
                <hr className={activeStep === 1 ? 'border-2 border-[#589BB1] w-28 md:hidden' : 'border-2 w-28 md:hidden'} />
                <div className='flex gap-3 md:items-center'>
                    <button className={activeStep >= 2 ? 'btn btn-circle text-[#589BB1] border-[#589BB1] btn-outline' : 'btn btn-circle btn-outline'}>
                        3
                    </button>
                    <h1 className={activeStep >= 2 ? 'text-[#589BB1] hidden md:block' : 'hidden md:block'}>Contact Details</h1>

                </div>

            </div>
            <div className='basis-3/4 form-style p-5 my-10'>

                <form onChange={handleSubmit(onSubmit)} className='w-full flex gap-4 justify-between'>
                    <h1 className='text-xl'>Fill in the details</h1>
                    <div className='hidden md:block'>
                        <select
                            required
                            className="w-44 font-normal bg-[#E0E0E0] select select-bordered"
                            {...register("city",
                                { required: 'City is required' }
                            )}
                        >
                            <option value=''>City</option>
                            <option value='New York'>New York</option>
                            <option value='California'>California</option>
                            <option value='Texas'>Texas</option>
                            <option value='Arizona'>Arizona</option>
                        </select>
                        {errors.city && <span className="text-error">{errors.city?.message}</span>}

                        <select
                            className="w-44 mx-4 bg-[#E0E0E0] select select-bordered font-normal"
                            {...register("state",
                                { required: true }
                            )}

                        >
                            <option value=''>State</option>
                            <option value='Arizona'>Alaska</option>
                            <option value='Arizona'>Arizona</option>
                            <option value='Georgia'>Georgia</option>
                            <option value='California'>California</option>
                            <option value='Colorado'>Colorado</option>
                            <option value='Idaho'>Idaho</option>
                        </select>

                        <select
                            className="w-44 bg-[#E0E0E0] select select-bordered font-normal"

                            {...register("country",
                                { required: true }
                            )}

                        >
                            <option value=''>Country</option>
                            <option value='USA'>USA</option>
                            <option value='Canada'>Canada</option>
                            <option value='Canada'>Canada</option>
                            <option value='UK'>UK</option>
                            <option value='India'>India</option>
                            <option value='Bangladesh'>Bangladesh</option>
                        </select>
                    </div>
                </form>
                <hr className='border mt-2 border-gray-400' />
                <div>
                    <div className='w-full'>

                        {getStepsOutput(activeStep)}
                    </div>
                </div>

            </div>
            {/* <div className="drawer drawer-mobile gap-5">
                <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content mt-5 flex flex-col">

                    <div className='w-full flex gap-4 justify-between'>
                        <h1 className='text-2xl'>Fill in the details</h1>
                        <div className='flex gap-2'>
                            <select className="w-44 bg-white select select-bordered">
                                <option disabled selected>City</option>
                                <option>City 1</option>
                                <option>City 2</option>
                            </select>
                            <select className="w-44 bg-white select select-bordered">
                                <option disabled selected>State</option>
                                <option>State 1</option>
                                <option>State 2</option>
                            </select>
                            <select className="w-44 bg-white select select-bordered">
                                <option disabled selected>Country</option>
                                <option>Country 1</option>
                                <option>Country 2</option>
                            </select>
                        </div>
                    </div>

                    <hr className='border mt-2 border-gray-200' />
                    <div className='form-style min-h-full overflow-hidden p-5'>
                    
                    </div>

                    <label htmlFor="my-drawer-2" className="btn btn-primary drawer-button lg:hidden">Open drawer</label>

                </div>
                <div className="drawer-side">
                    <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
                    <ul className="menu p-4 w-80 bg-[#F6F6F6] text-base-content justify-center items-center">
                        <div className='basis-1/4 flex flex-col gap-10 items-center justify-center'>
                            <div className='flex gap-3 items-center justify-center'>
                                <button className="btn btn-circle text-[#589BB1] border-[#589BB1] btn-outline">
                                    1
                                </button>
                                <h1 className='text-lg text-[#589BB1]'>Personal Information</h1>
                            </div>
                            <div className='flex gap-3 items-center justify-center'>
                                <button className="btn btn-circle btn-outline">
                                    1
                                </button>
                                <h1 className='text-lg'>Personal Information</h1>
                            </div>
                            <div className='flex gap-3 items-center justify-center'>
                                <button className="btn btn-circle btn-outline">
                                    1
                                </button>
                                <h1 className='text-lg'>Personal Information</h1>
                            </div>
                        </div>
                    </ul>
                </div>
            </div> */}
        </div >
    );
};

export default Advertise;