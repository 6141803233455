import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../Context/AuthProvider";


const brands = [
    'Audi', 'Chevrolet', 'Cadillac', 'Acura',
    'BMW', 'Chrysler', 'Ford', 'Buick',
    'INFINITI', 'GMC', 'Honda', 'Hyundai',
    'Jeep', 'Genesis', 'Dodge', 'Jaguar',
    'Kia', 'Land Rover', 'Lexus', 'Mercedes-Benz',
    'Mitsubishi', 'Lincoln', 'MAZDA', 'Nissan',
    'MINI', 'Porsche', 'Ram', 'Subaru',
    'Toyota', 'Volkswagen', 'Volvo', 'Alfa Romeo',
    'FIAT', 'Freightliner', 'Maserati', 'Tesla',
    'Aston Martin', 'Bentley', 'Ferrari', 'Lamborghini',
    'Lotus', 'McLaren', 'Rolls-Royce', 'smart',
    'Scion', 'SRT', 'Suzuki', 'Fisker',
    'Maybach', 'Mercury', 'Saab', 'HUMMER',
    'Pontiac', 'Saturn', 'Isuzu', 'Panoz',
    'Oldsmobile', 'Daewoo', 'Plymouth', 'Eagle',
    'Geo', 'Daihatsu'
]
const allVehicles = [
    'Car',
    'Van',
    'Taxi',
    'Bus',
    'Ambulance',
    'Skateboard',
    'Bicycle',
    'Scooter',
    'Motorbike',
    'Tractor',
    'Recycling truck',
    'Cement mixer',
    'Dump truck',
    'Aerial tramway',
]


const PersonalInfo = ({ setPersonalInfo, personalInfo, handleBack, activeStep, handleFinish, handleNext }) => {
    const [BrnadInputField, setBrandInputField] = useState('');
    const [modelInputField, setModelInputField] = useState('');
    const { register, handleSubmit, formState: { errors }, } = useForm();
    // const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm();
    const [models, setModels] = useState([])

    // const { user } = useContext( AuthContext );
    // console.log( user );


    // get all model form CarModels.json file-------------

    useEffect(() => {
        fetch('CarModels.json')
            .then(res => res.json())
            .then(data => {
                setModels(data);
            })
    }, [])


    const onSubmit = data => {
        setPersonalInfo({ ...personalInfo, ...data });
        // console.log(data);
        handleNext()
    };
    // const onSubmit2 = data => {
    //     setPersonalInfo({ ...personalInfo, ...data });
    //     // console.log(data);
    //     handleNext()
    // };


    const years = []
    const date = new Date()
    const currentYear = date.getFullYear();

    for (let i = 0; i <= 40; i++) {
        years.push(currentYear - i);
    }

    const handleBrand = (e) => {
        const value = e.target.value;
        setBrandInputField(value)
    }

    const handleModel = (e) => {
        const value = e.target.value;
        setModelInputField(value)


    }
    // console.log( inputField );

    // const handleSubmit = ( e ) => {
    //     e.preventDefault()
    //     const form = e.target;
    //     console.log( form.value );
    //     handleNext()
    // }

    return (
        <div>

            {/* <form onChange={handleSubmit2(onSubmit2)} className='w-full flex gap-4 justify-between'>
                <h1 className='text-xl'>Fill in the details</h1>
                <div className='hidden md:block'>
                    <select
                        required
                        className="w-44 font-normal bg-[#E0E0E0] select select-bordered"
                        {...register2("city",
                            { required: 'City is required' }
                        )}
                    >
                        <option value=''>City</option>
                        <option value='New York'>New York</option>
                        <option value='California'>California</option>
                        <option value='Texas'>Texas</option>
                        <option value='Arizona'>Arizona</option>
                    </select>
                    {errors2.city && <span className="text-error">{errors2.city?.message}</span>}

                    <select
                        className="w-44 mx-4 bg-[#E0E0E0] select select-bordered font-normal"
                        {...register2("state",
                            { required: true }
                        )}
                    >
                        <option value=''>State</option>
                        <option value='Arizona'>Alaska</option>
                        <option value='Arizona'>Arizona</option>
                        <option value='Georgia'>Georgia</option>
                        <option value='California'>California</option>
                        <option value='Colorado'>Colorado</option>
                        <option value='Idaho'>Idaho</option>
                    </select>

                    <select
                        className="w-44 bg-[#E0E0E0] select select-bordered font-normal"
                        {...register2("country",
                            { required: true }
                        )}
                    >
                        <option value=''>Country</option>
                        <option value='USA'>USA</option>
                        <option value='Canada'>Canada</option>
                        <option value='Canada'>Canada</option>
                        <option value='UK'>UK</option>
                        <option value='India'>India</option>
                        <option value='Bangladesh'>Bangladesh</option>
                    </select>
                </div>
            </form> */}
            {/* <hr className='border mt-2 border-gray-400' /> */}



            <div>
                <h1 className='text-xl font-semibold text-center mt-10'>Condition</h1>
                <hr className='w-24 mx-auto border border-dashed border-gray-600' />
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
                {/* condition section -----------start */}
                <div className='flex gap-3 justify-center mt-5'>
                    <div className='flex gap-2'>
                        <input type="radio" name="new" className="radio" value='new' defaultChecked {...register("condition")} />
                        <p className='text-base'>New</p>
                    </div>
                    <div className='flex gap-2'>
                        <input type="radio" name="used" className="radio" value='used' {...register("condition")} />
                        <p className='text-base'>Used</p>
                    </div>
                    <div className='flex gap-2'>
                        <input type="radio" name="reconditioned" className="radio" value='recondition' {...register("condition")} />

                        <p className='text-base'>Recondition</p>
                    </div>
                    {/* <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text">Remember me</span>
                            <input type="checkbox" className="checkbox" />
                        </label>
                    </div> */}
                </div>

                {/* condition section -----------End */}

                {/* Form section ---------------start */}

                <div className='text-black grid grid-cols-1 md:grid-cols-2 gap-5 justify-between items-center mt-5'>
                    <div className="form-control w-full">
                        <label className="label py-0">
                            <span className="label-text text-black">VIN Number</span>
                        </label>

                        <div className="md:h-12">
                            <input type="text" placeholder="VIN Number" className="input text-black py-0 bg-transparent border-[#00000051] focus:outline-none w-full "
                                {...register("vin_number",
                                    { required: 'Vin Number is required' }
                                )} />
                            {errors.vin_number && <span className="text-error">{errors.vin_number?.message}</span>}

                        </div>
                    </div>

                    <div className="form-control w-full">
                        <label className="label py-0">
                            <span className="label-text text-black">Vehicle Type</span>
                        </label>

                        <div className="md:h-12">
                            <select className="select bg-transparent border-[#00000051] focus:outline-none w-full font-normal "
                                {...register("vehicle_Type",
                                    { required: 'vehicle_Type Required' }
                                )} >
                                <option value=''>Select</option>
                                {
                                    allVehicles.map((vehicle, i) => <option key={i} value={`${vehicle}`}>{vehicle}</option>)
                                }

                            </select>
                            {errors.vehicle_Type && <span className="text-error">{errors.vehicle_Type?.message}</span>}

                        </div>
                    </div>

                    <div className="form-control w-full">
                        <label className="label py-0">
                            <span className="label-text text-black">Fuel Type</span>
                        </label>

                        <div className="md:h-12">
                            <select defaultValue='' className="font-normal w-full select bg-transparent border-[#00000051] focus:outline-none "
                                {...register("fuel",
                                    { required: 'Fule Required!' }
                                )} >

                                <option value=''>Select</option>
                                <option value='Diesel'>Diesel</option>
                                <option value='Petrol'>Petrol</option>
                                <option value='CNG'>CNG</option>
                                <option value='Electric'>Electric </option>
                                <option value='octane'>octane</option>
                                <option value='Hydrogen'>Hydrogen</option>
                            </select>

                            {errors.fuel && <span className="text-error">{errors.fuel?.message}</span>}

                        </div>
                    </div>

                    <div className="form-control w-full">
                        <label className="label py-0">
                            <span className="label-text text-black">Brand</span>
                        </label>

                        <div className="md:h-12">
                            {
                                BrnadInputField === 'Other' ?
                                    <input type="text" placeholder="Enter Your Brand" className="input text-black bg-transparent border-[#00000051] focus:outline-none w-full "
                                        {...register("brand",
                                            { required: 'Please Enter your Brand' }
                                        )} />
                                    :
                                    <select onClick={(e) => handleBrand(e)} defaultValue='Brand' className="select w-full bg-transparent border-[#00000051] focus:outline-none font-normal "
                                        {...register("brand", { required: 'Please Enter your Brand' })}>
                                        <option value=''>Brand</option>
                                        {
                                            brands.map((brand, i) =>
                                                <option key={i}>{brand}</option>

                                            )
                                        }
                                        <option value='Other'>Other</option>
                                    </select>
                            }

                            {errors.brand && <span className="text-error">{errors.brand?.message}</span>}

                        </div>
                    </div>
                    <div className="form-control w-full ">
                        <label className="label py-0">
                            <span className="label-text text-black">Engine capacity (cc)</span>
                        </label>

                        <div className="md:h-12">
                            <input type="text" placeholder="What is the engine capacity of your car?" className="input text-black bg-transparent border-[#00000051] focus:outline-none w-full " {...register("engine_capacity",
                                { required: 'Engine Capacity is required' }
                            )} />
                            {errors.engine_capacity && <span className="text-error">{errors.engine_capacity?.message}</span>}

                        </div>
                    </div>
                    <div className="form-control w-full">
                        <label className="label py-0">
                            <span className="label-text text-black">Kilometers run (km)</span>
                        </label>

                        <div className="md:h-12">
                            <input type="text" placeholder="Total KM run" className="input text-black bg-transparent border-[#00000051] focus:outline-none w-full " {...register("mileage",
                                { required: 'Kilometers is required' }
                            )} />
                            {errors.mileage && <span className="text-error">{errors.mileage?.message}</span>}

                        </div>
                    </div>
                    <div className="form-control w-full">
                        <label className="label py-0">
                            <span className="label-text text-black">Model</span>
                        </label>

                        <div className="md:h-12">
                            {
                                modelInputField === 'Other' ?
                                    <input type="text" placeholder="Enter Your Model" className="input text-black bg-transparent border-[#00000051] focus:outline-none w-full " {...register("model",
                                        { required: 'Please Enter your Model' }
                                    )} />
                                    :
                                    <select onClick={(e) => handleModel(e)} defaultValue='Model' className="select w-full bg-transparent border-[#00000051] focus:outline-none font-normal " {...register("model", { required: 'Please Enter your Model' })}>
                                        <option value=''>Model</option>
                                        {
                                            models?.map(model => <option key={model?.id}>{model?.name}</option>)

                                        }
                                        <option value='Other'>Other</option>
                                    </select>

                            }

                            {errors.model && <span className="text-error">{errors.model?.message}</span>}

                        </div>
                    </div>
                    <div className="form-control w-full ">
                        <label className="label py-0">
                            <span className="label-tex text-black">Mileage</span>
                        </label>

                        <div className="md:h-12">
                            <input type="text" placeholder="What is the mileage of your car?" className="input bg-transparent border-[#00000051] focus:outline-none w-full " {...register("speed",
                                { required: 'Speed field required' }
                            )} />
                            {errors.speed && <span className="text-error">{errors.speed?.message}</span>}

                        </div>
                    </div>
                    <div className="form-control w-full">
                        <label className="label py-0">
                            <span className="label-text text-black">Registration year</span>
                        </label>

                        <div className="md:h-12">
                            <select defaultValue='Registration year' className="select w-full font-normal bg-transparent border-[#00000051] focus:outline-none " {...register("reg_year", { required: 'Registration year required' })}>
                                <option value=''>Registration year</option>
                                {
                                    years.map((year, i) => <option key={i} value={`${year}`}>{year}</option>)
                                }
                            </select>
                            {errors.reg_year && <span className="text-error">{errors.reg_year?.message}</span>}

                        </div>
                    </div>
                    <div className="form-control w-full">
                        <label className="label py-0">
                            <span className="label-text text-black">Transmission</span>
                        </label>

                        <div className="md:h-12">
                            <select defaultValue='Transmission' className="select w-full font-normal bg-transparent border-[#00000051] focus:outline-none " {...register("transmission", { required: 'Transmission field required' })}>
                                <option disabled >Transmission</option>
                                <option>Auto</option>
                                <option>Manual</option>
                            </select>
                            {errors.transmission && <span className="text-error">{errors.transmission?.message}</span>}
                        </div>
                    </div>
                </div>
                {/* Form section --------------- End */}

                {/* button section---------------start */}
                <div className='flex flex-col md:flex-row gap-4 md:justify-between mt-10'>
                    <button
                        onClick={handleBack}

                        className='btn btn-accent btn-outline btn-block md:btn-wide'
                    >Back</button>

                    {
                        activeStep === 2 ?
                            <button

                                onClick={handleFinish}

                                className='btn btn-primary btn-block md:btn-wide'
                            >Post Ad</button>
                            :
                            // <button

                            //     type="submit"
                            //     onClick={ handleNext }
                            //     className='btn btn-primary btn-wide'
                            // >Next</button>
                            <button type="submit"
                                onSubmit={handleSubmit}

                                className='btn btn-primary btn-block md:btn-wide'>
                                Next
                            </button>
                    }
                </div>
                {/* <FormButton
                    handleBack={ handleBack }
                    handleFinish={ handleFinish }
                    handleSubmit={ handleSubmit }
                    activeStep={ activeStep }
                    handleNext={ handleNext }
                /> */}

                {/* button section---------------End */}


            </form>
        </div>
    );
};

export default PersonalInfo;