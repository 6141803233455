import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";
const slides = [
    {
        id: 1
    },
    {
        id: 2
    },
    {
        id: 3
    },
    {
        id: 4
    }
]

const FeedBack = () => {
    return (

        <div className='w-full'>
            <h1 className='text-4xl font-bold text-center my-5 underline'>What Clients Say</h1>

            {/* <div className="p-5 w-1/3 mx-auto shadow-xl rounded-md">
                <p className="">Quite often, the threat of a negative Trustpilot review will accomplish what would otherwise require legal action. A positive force in all industries.</p>
                <div className="flex gap-4 items-center my-8">
                    <div className="avatar">
                        <div className="w-16 rounded-full">
                            <img className="w-32" src="https://w7.pngwing.com/pngs/912/392/png-transparent-jerry-mouse-tom-cat-tom-and-jerry-poster-tom-and-jerry-mammal-heroes-cat-like-mammal.png" />
                        </div>
                    </div>
                    <div>
                        <h1 className="text-lg font-bold">Mary White</h1>
                        <p>Principal, Bluth Finance</p>
                    </div>
                </div>
            </div> */}

            <Swiper
                effect={ "coverflow" }
                grabCursor={ true }
                centeredSlides={ true }
                slidesPerView={ "auto" }
                coverflowEffect={ {
                    rotate: 50,
                    stretch: 0,
                    depth: 50,
                    modifier: 1,
                    slideShadows: true,
                } }
                pagination={ true }
                modules={ [ EffectCoverflow, Pagination ] }
                className="mySwiper"
            >
                {
                    slides.map( slide =>
                        <SwiperSlide
                            key={ slide.id }
                        >
                            <div className="p-5 w-full mx-auto rounded-md">
                                <p className="">Quite often, the threat of a negative Trustpilot review will accomplish what would otherwise require legal action. A positive force in all industries.</p>
                                <div className="flex gap-4 items-center my-8">
                                    <div className="avatar">
                                        <div className="w-16 rounded-full">
                                            <img className="w-32" src="https://w7.pngwing.com/pngs/912/392/png-transparent-jerry-mouse-tom-cat-tom-and-jerry-poster-tom-and-jerry-mammal-heroes-cat-like-mammal.png" />
                                        </div>
                                    </div>
                                    <div>
                                        <h1 className="text-lg font-bold">Mary White</h1>
                                        <p>Principal, Bluth Finance</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                }
                {/* <SwiperSlide>
                    <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="https://swiperjs.com/demos/images/nature-8.jpg" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src="https://swiperjs.com/demos/images/nature-9.jpg" />
                </SwiperSlide> */}
            </Swiper>

        </div>
    );
};

export default FeedBack;