import React from 'react';
import FormButton from '../FormButton/FormButton';


const FormReset = ({ handleBack, submitPost, activeStep, handleFinish, handleNext, handleSubmit }) => {


    const handlesubmit = () => {
        submitPost()
        // alert( 'successuly added' )
        handleFinish()
    }

    return (
        <div className='w-11/12 mx-auto'>
            <div className='my-5'>

                <p>You have Successful fill up your total details of your advertise car. If you want to advertise this product please press the <button onClick={handlesubmit} className='text-primary font-bold'>Submit button</button> or if you want to reset your form please press the <button onClick={handleFinish} className='text-primary font-bold'>Reset button</button> </p>
            </div>
            <div className='flex justify-between mt-10'>
                {/* <button
                    onClick={handleFinish}
                    className='btn btn-error btn-wide'
                >Reset Post</button> */}
                <button
                    onClick={handleBack}
                    className='btn btn-accent btn-outline btn-block md:btn-wide'
                >Back</button>
                {
                    activeStep === 3 ?
                        // <button
                        //     onClick={ handleFinish }
                        //     className='btn btn-primary btn-wide'
                        // >Post Ad</button>

                        <button type="submit"

                            onClick={handlesubmit}
                            className='btn btn-primary btn-wide'>
                            Submit Post
                        </button>
                        :
                        <button

                            onClick={handleNext}
                            className='btn btn-primary btn-wide'
                        >Next</button>
                }
            </div>
            {/* <FormButton
                handleBack={ handleBack }
                handleFinish={ handleFinish }
                handleSubmit={ handleSubmit }
                activeStep={ activeStep }
                handleNext={ handleNext }
            /> */}
        </div>
    );
};

export default FormReset;