import React from 'react';
import headerimg from '../../Images/hero/Group-1118.png'
import headerover from '../../Images/hero/Rectangle 2381.png'
import carbg from '../../Images/hero/carbg.jpg'
import { FaAngleRight, FaRegEnvelope } from "react-icons/fa";

const Aboutus = () => {
    return (
        <div>
            <div className='relative'>
                <img className='absolute h-full w-full' src={ headerover } alt="" />
                <img className='h-full w-full object-cover' src={ headerimg } alt="" />
                <div className='absolute top-1/3 left-1/3'>
                    <div>
                        <hr className='border-2  w-96' />
                        <h1 className='text-center text-6xl font-semibold text-white my-5'>About Us</h1>
                        <hr className='border-2  w-96' />
                    </div>
                </div>
            </div>

            {/* about reuse--- */ }
            <div className='w-4/5 my-16 mx-auto leading-8 text-center'>
                <h1 className='text-3xl font-bold my-2 text-center underline'>About Reuse</h1>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime vero tempora, a eligendi eum dicta deleniti mollitia perferendis magnam veritatis tempore error laboriosam incidunt minima. Iusto dignissimos fugiat praesentium, doloremque amet adipisci magni! Officiis voluptatem quaerat itaque illum tempora optio, obcaecati illo perspiciatis necessitatibus, sed eius, exercitationem labore provident quos?
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur non harum dolor eos veritatis suscipit repudiandae perferendis sequi nemo, id earum minima porro ipsum esse. Perferendis reprehenderit distinctio eligendi sit dignissimos enim sequi dolorum facere in ullam laudantium molestias ea, rerum quos cupiditate aliquam accusantium a quis! Maiores, ipsa est.
                </p>
            </div>
            {/* about reuse--- */ }

            {/* contact us-------- */ }
            <div className='relative'>
                {/* <div className='bg-black absolute h-[700px] z-50 w-full opacity-10'>
                    </div> */}
                <img className='absolute h-[700px]' src={ headerover } alt="" />
                <img className='h-[700px] w-full object-cover' src={ carbg } alt="" />
                <div className='absolute w-full top-28 flex justify-center items-center'>
                    <div className='w-11/12 mx-auto grid grid-cols-2 gap-10 justify-center items-center'>
                        <div className='text-white flex flex-col gap-4'>
                            <div className='pr-10'>
                                <h1 className='text-2xl font-bold'>The Problem</h1>
                                <p className='my-2'>Buying and selling cars is a hassle. Autolist is on a
                                    mission to make finding, selling, & financing a car easy.</p>
                            </div>
                            <div className='pr-10 my-5'>
                                <h1 className='text-2xl font-bold'>The Approach</h1>
                                <p className='my-2'>Autolist is building a better automotive buying experience for everyone. We employ the latest and greatest tech and the largest database of vehicles to power car searches across Android, iOS and the web to provide our users with the best car buying experience anywhere.</p>
                            </div>
                            <div className='pr-10'>
                                <h1 className='text-2xl font-bold'>The Result</h1>
                                <p className='my-2'>While we've just started, we serve millions of visitors and facilitate over a million car purchases. We're profitable and growing fast.</p>
                            </div>
                        </div>
                        <div className='px-12'>
                            <button className='border-4 items-center w-full h-32 border-white rounded-full text-white flex justify-between bg-[#0808086c] px-12 gap-4'>
                                <FaRegEnvelope className='text-6xl' />
                                <div className=''>
                                    <h1 className='text-3xl font-bold'>Contact With Us</h1>
                                    <p className='text-xl '>Get in touch & let us know
                                        what you think.</p>
                                </div>
                                <FaAngleRight className='text-6xl' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* contact us-------- */ }
            {/* who we are--------- */ }
            <div className='w-4/5 mx-auto text-center my-16'>
                <h1 className='text-4xl font-bold underline'>Who We Are</h1>
                <p className='w-full px-10 my-5'>Some of us are car nuts, some of us aren't, but we're all passionate about solving big problems and building awesome products. While we're an impressive team (if we do say so ourselves) that works hard, we try to keep our culture ego-free and value creativity, autonomy, and fun.</p>
            </div>
            {/* who we are--------- */ }
        </div>
    );
};

export default Aboutus;