import React, { useEffect, useState } from 'react';

const AllUsers = () => {
    const [ allusers, setAllusers ] = useState( [] )

    useEffect( () => {
        fetch( 'https://api.reuse.sale/user/api/read.php' )
            .then( res => res.json() )
            .then( data => {
                console.log( data );
                setAllusers( data.body )
            } )
    }, [] )

    return (
        <div className='text-center mt-10'>
            All users { allusers?.length }
        </div>
    );
};

export default AllUsers;