import React from 'react';
import { BsSpeedometer2 } from 'react-icons/bs';
import { GoLocation } from 'react-icons/go';
import { Link } from 'react-router-dom';

const SingleCar = ({ product }) => {
    // console.log(product);
    return (
        <Link
            // onClick={ () => setViews( views + 1 ) }
            to={`/singleProduct/${product?.list_id}`}
            className="card rounded-md card-compact shadow-md">
            <figure><img className='h-40 object-cover w-full' src={product?.image_one} alt="car" /></figure>
            <div className="card-body">
                <h2 className="text-base font-semibold md:card-title">{product?.list_productname}</h2>
                <div className='flex gap-3 items-center'>
                    <h1 className='text-base md:text-lg font-semibold text-gray-600'>Price:</h1>
                    <p className='text-base md:text-lg font-semibold md:font-bold'>$ {product?.list_price}</p>
                </div>
                <div className='flex justify-between'>
                    <div className='flex gap-1 items-center'>
                        <GoLocation className='text-lg text-orange-500' />
                        <p>Address Here</p>
                    </div>
                    <div className='flex gap-1 items-center text-gray-500'>
                        <BsSpeedometer2 className='text-lg' />
                        <p>{product?.list_mileage} km</p>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default SingleCar;