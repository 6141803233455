import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { PhotosOfCar } from '../FormSteps/FormSteps';
import ImageUpload from '../ImageUpload/ImageUpload';


// const images = [
//     {
//         id: 1
//     },
//     {
//         id: 2
//     },
//     {
//         id: 3
//     },
//     {
//         id: 4
//     },
//     {
//         id: 5
//     },
// ]


const ContactDetails = ({ setPersonalInfo, personalInfo, handleBack, activeStep, handleFinish, handleNext, setGetImages, getImages }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();


    // const imgaekey = '1b03953cb11f98867c80d226ce43416c';

    // const handleOnChange = () => {
    //     const formData = new FormData();
    //     const photos = document.querySelector( 'input[type="file"][multiple]' );

    //     formData.append( 'title', 'My Vegas Vacation' );
    //     let i = 0;
    //     console.log( photos );

    //     // for ( const photo of photos.files ) {
    //     //     formData.append( `photos_${ i }`, photo );
    //     //     console.log( photo );

    //     //     // fetch( 'https://api.imgbb.com/1/upload?expiration=600&key=1b03953cb11f98867c80d226ce43416c', {
    //     //     //     method: 'POST',
    //     //     //     body: formData
    //     //     // } )

    //     //     //     // axios.post( "https://api.imgbb.com/1/upload?expiration=600&key=1b03953cb11f98867c80d226ce43416c", formData, {
    //     //     //     //     headers: {
    //     //     //     //         "Content-Type": "multipart/form-data",
    //     //     //     //     },
    //     //     //     // } )
    //     //     //     .then( ( response ) => response.json() )
    //     //     //     .then( ( result ) => {
    //     //     //         console.log( result );
    //     //     //     } )
    //     //     //     .catch( ( error ) => {
    //     //     //         console.error( 'Error:', error );
    //     //     //     } );



    //     //     i++;
    //     // }



    // }

    // api = https://api.reuse.sale/listing/api/upload.php

    const onSubmit = (data) => {
        setPersonalInfo({ ...personalInfo, ...data })

        handleNext();
    };



    return (


        <form onSubmit={handleSubmit(onSubmit)}>

            <h1 className='text-xl mt-10 ml-5'>Photos</h1>
            <h2 className='ml-8 opacity-70 mb-5'>Add up to 5 photos</h2>

            {/* <div
                className="form-control border-2 w-2/5 rounded-lg">
                <label className="label">
                    <span className="label-text text-lg pl-3 text-black">Upload Your Images</span>
                </label>
                <input accept="image/*" multiple type="file" placeholder="Images" className="input bg-transparent "
                    // { ...register( "image", { required: true } ) }
                    { ...register( "image",
                        // { required: 'Image is required' }
                    ) }
                />
                { errors.image && <span className="text-error">{ errors.image?.message }</span> }
            </div> */}

            <ImageUpload className='mt-5' setGetImages={setGetImages} getImages={getImages}></ImageUpload>


            {/* <PhotosOfCar setGetImages={ setGetImages } /> */}

            <div className="form-control mt-8">
                <label className="label">
                    <span className="label-text text-lg text-black">Description</span>
                </label>

                <div className='md:h-40'>
                    <textarea className="textarea textarea-bordered h-32 bg-transparent focus:outline-none border-[#00000051] text-black  w-full bg-[#E0E0E0]" placeholder="Description"
                        {...register("description",
                            { required: 'Description is Required' }
                        )} >

                    </textarea>
                    {errors.description && <span className="text-error">{errors.description?.message}</span>}
                </div>
            </div>
            <div className='text-black grid grid-cols-1 md:grid-cols-2 gap-5 justify-between items-center mt-5'>
                <div className='w-full'>
                    <label className="label">
                        <span className="label-text text-black">Enter Your Name</span>
                    </label>
                    <div className='md:h-12'>
                        <input type="text" placeholder="Please Enter Your Name" className="input text-black input-bordered bg-transparent focus:outline-none border-[#00000051] w-full bg-[#E0E0E0]"
                            {...register("name",
                                { required: 'Provide Your Name' }
                            )} />
                        {errors.name && <span className="text-error">{errors.name?.message}</span>}
                    </div>
                </div>
                <div className="form-control w-full ">
                    <label className="label">
                        <span className="label-text text-black">Phone</span>
                    </label>
                    <div className='md:h-12'>
                        <input type="text" placeholder="Phone Number" className="input text-black input-bordered bg-transparent focus:outline-none border-[#00000051] w-full bg-[#E0E0E0]"
                            {...register("phone",
                                { required: 'Phone Number is Required' }
                            )} />
                        {errors.phone && <span className="text-error">{errors.phone?.message}</span>}
                    </div>
                </div>
                <div className="form-control w-full flex-row items-center gap-8">
                    <div className='w-full'>
                        <label className="label">
                            <span className="label-text text-black">Price</span>
                        </label>
                        <div className='md:h-12'>
                            <input type="text" placeholder="price" className="input text-black input-bordered bg-transparent focus:outline-none border-[#00000051] w-full bg-[#E0E0E0]"
                                {...register("price",
                                    { required: 'Price is Required' }
                                )} />
                            {errors.price && <span className="text-error">{errors.price?.message}</span>}
                        </div>
                    </div>
                    <div>
                        <label className="label">
                            <span className="label-text text-black font-semibold">Negotiable</span>
                        </label>
                        <div className='flex gap-3'>
                            <div className='flex gap-2'>
                                <input type="radio" name="new" className="radio" value='Yes' defaultChecked {...register("negotiable")} />
                                <p className='text-base'>Yes</p>
                            </div>
                            <div className='flex gap-2'>
                                <input type="radio" name="used" className="radio" value='No' {...register("negotiable")} />

                                <p className='text-base'>No</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-control w-full ">
                    <label className="flex flex-col">
                        <span className="label-text text-black font-bold">Seller Disclosures</span>
                        <span className="label-text text-gray-500">Are you aware of any damage or defects that would materially effect the value of the vehicle? (Yes/No)</span>
                    </label>
                    <div className='md:h-12'>
                        <input type="text" placeholder="Please type your answer" className="input text-black input-bordered bg-transparent focus:outline-none border-[#00000051] w-full bg-[#E0E0E0]"
                            {...register("disclosure",
                                { required: 'Disclosure is Required' }
                            )} />
                        {errors.disclosure && <span className="text-error">{errors.disclosure?.message}</span>}
                    </div>
                </div>
            </div>
            {/* Button section ------------start */}
            <div className='flex flex-col md:flex-row gap-4 md:justify-between mt-10'>
                <button
                    onClick={handleBack}

                    className='btn btn-accent btn-outline btn-block md:btn-wide'
                >Back</button>

                {
                    activeStep === 2 ?
                        // <button
                        //     onClick={ handleFinish }
                        //     className='btn btn-primary btn-wide'
                        // >Post Ad</button>

                        <button type="submit"

                            onSubmit={handleSubmit}
                            className='btn btn-primary btn-block md:btn-wide'
                        >
                            Submit Post
                        </button>
                        :
                        <button
                            onClick={handleNext}
                            className='btn btn-primary btn-block md:btn-wide'
                        >Next</button>
                }
            </div>
            {/* Button section ------------End */}
        </form >

        //    image show ----------
        // <div
        //     className="form-control border-2 w-2/5 rounded-lg">
        //     {
        //         Array.from( img ).map( item => {
        //             return (
        //                 <span >
        //                     <img src={ item ? URL.createObjectURL( item ) : null } alt="" />
        //                 </span>
        //             )
        //         } )
        //     }
        //     <label className="label">
        //         <span className="label-text text-lg pl-3 text-black">Upload Your Images</span>
        //     </label>
        //     <input onChange={ e => setImg( e.target.files ) } multiple type="file" placeholder="Images" className="input bg-transparent "
        //     // { ...register( "image", { required: true } ) }
        //     />
        //     <button>Upload</button>
        // </div>
        //    image show ----------
    );
};

export default ContactDetails;