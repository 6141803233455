
import { RouterProvider } from 'react-router-dom';
import './App.css';

import LoadingPage from './Pages/LoadingPage/LoadingPage';
import { LanguageContext } from './Context/Context';
import { useState } from 'react';
import { router } from './Routes/routes';


function App() {

  const [languageContext, setContextLanguage] = useState("");
  return (
    <div >
      <LanguageContext.Provider value={{ languageContext, setContextLanguage }}>
        <RouterProvider router={router}
          fallbackElement={<LoadingPage></LoadingPage>}
        ></RouterProvider>
      </LanguageContext.Provider>
    </div>
  );
}

export default App;
