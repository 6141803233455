import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../Images/logo/footer logo.png'
import fb from '../../Images/social media/fb.png'
import linkedin from '../../Images/social media/linkedin.png'
import insta from '../../Images/social media/insta.png'
import twitter from '../../Images/social media/twitter.png'
import { FaRegEnvelope } from "react-icons/fa";

const Footer = () => {
    return (
        <div className='bg-[#589BB1] text-white mt-14 w-full mx-auto'>
            <footer className="p-5 md:px-10 grid sm:grid-col-1 lg:grid-cols-7 mx-auto lg:py-14 gap-2 bg-[#589BB1] ">
                {/* Mobile responsive----------- */ }
                <div className='col-span-2 w-full lg:hidden'>
                    <div className='h-14 rounded-lg  bg-white text-black flex justify-between items-center p-4'>
                        <div className='flex gap-2 items-end w-full'>
                            <FaRegEnvelope className='text-xl text-gray-400' />
                            <input className='bg-transparent w-4/5 border-b-2 text-sm' type="email" name="email" placeholder='Enter You Email' />
                        </div>
                        <button className='btn btn-sm rounded-full border-none text-white bg-[#589BB1]' type="submit">subscribe</button>
                    </div>
                    <hr className='border-1 w-full mt-5' />
                </div>

                <div className='col-span-2 text-center lg:text-left object-center'>
                    <img className='mx-auto lg:mx-0' src={ logo } alt="" />
                    <div className='w-full md:w-3/5 lg:w-full mx-auto lg:mx-0 my-4 lg:my-0'>
                        <h1 className='text-lg font-semibold'>Zufaa.com</h1>Providing reliable tech since 2002 <br />
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Error harum nobis incidunt consequatur numquam quia?
                    </div>
                    <div className='hidden lg:flex gap-5 mt-5'>
                        <a
                            href='https://www.facebook.com/zufaa.usa/'
                            target='_blank'
                            rel="noreferrer"
                        >
                            <img className='w-10' src={ fb } alt="" />
                        </a>
                        <a
                            href='https://www.linkedin.com/company/zufaa/'
                            target='_blank'
                            rel="noreferrer"
                        >
                            <img className='w-10' src={ linkedin } alt="" />
                        </a>
                        <a
                            href='https://www.instagram.com/zufaa.inc/?igshid=NjZiMGI4OTY%3D'
                            target='_blank'
                            rel="noreferrer"
                        >
                            <img className='w-10' src={ insta } alt="" />
                        </a>
                        <a
                            href='https://twitter.com/ZUFAA'
                            target='_blank'
                            rel="noreferrer"
                        >
                            <img className='w-10' src={ twitter } alt="" />
                        </a>
                    </div>
                </div>
                {/* Large device view--------start */ }
                <div className='hidden lg:block'>
                    <h1 className="footer-title opacity-100 ">Help & Support</h1>
                    <div className='flex flex-col gap-4'>
                        <Link to='/faq' className="link link-hover">FAQ</Link>
                        <Link to='/blog' className="link link-hover">Blog</Link>
                        <Link to='/contact' className="link link-hover">Contact us</Link>
                    </div>
                </div>
                <div className='hidden lg:block'>
                    <h1 className="footer-title opacity-100 ">Services</h1>
                    <div className='flex flex-col gap-4'>
                        <Link className="link link-hover">Buy Car</Link>
                        <Link className="link link-hover">Sell Car</Link>
                        <Link className="link link-hover">Buy Bike</Link>
                    </div>
                </div>
                <div className='hidden lg:block'>
                    <h1 className="footer-title opacity-100 ">About</h1>
                    <div className='flex flex-col gap-4'>
                        <Link to='/aboutus' className="link link-hover">About us</Link>
                        <Link to='/termscondition' className="link link-hover">Terms and Conditions</Link>
                        <Link to='/pivacypolicy' className="link link-hover">Privacy Policy</Link>
                    </div>
                </div>
                {/* Large device view--------start */ }

                {/* Mobile view---------start */ }
                <div className='lg:hidden col-span-2 text-center grid grid-cols-3 my-5'>
                    <div>
                        <h1 className="footer-title opacity-100 ">Support</h1>
                        <div className='flex flex-col gap-4'>
                            <Link to='/faq' className="link link-hover">FAQ</Link>
                            <Link to='/blog' className="link link-hover">Blog</Link>
                            <Link to='/contact' className="link link-hover">Contact us</Link>
                        </div>
                    </div>
                    <div>
                        <h1 className="footer-title opacity-100 ">About</h1>
                        <div className='flex flex-col gap-4'>
                            <Link className="link link-hover">About us</Link>
                            <Link className="link link-hover">Conditions</Link>
                            <Link className="link link-hover">Privacy Policy</Link>
                        </div>
                    </div>
                    <div>
                        <h1 className="footer-title opacity-100 ">Services</h1>
                        <div className='flex flex-col gap-4'>
                            <Link className="link link-hover">Buy Car</Link>
                            <Link className="link link-hover">Sell Car</Link>
                            <Link className="link link-hover">Buy Bike</Link>
                        </div>
                    </div>

                </div>
                {/* Mobile view---------e */ }
                <div className='hidden lg:block text-lg col-span-2'>
                    <h1 className="footer-title opacity-100 ">Subscribe to our newsletter</h1>
                    <div className='h-14 rounded-lg w-full bg-white text-black flex justify-between items-center p-4'>

                        <div className='flex gap-2 items-end w-full'>
                            <FaRegEnvelope className='text-xl text-gray-400' />
                            <input className='bg-transparent w-4/5 border-b-2 text-sm' type="email" name="email" placeholder='Enter You Email' />
                        </div>
                        <button className='btn btn-sm rounded-full border-none text-white bg-[#589BB1]' type="submit">subscribe</button>

                    </div>
                </div>

            </footer>
            <div className='flex lg:hidden gap-5 my-5 justify-center'>
                <img className='w-8' src={ fb } alt="" />
                <img className='w-8' src={ linkedin } alt="" />
                <img className='w-8' src={ insta } alt="" />
                <img className='w-8' src={ twitter } alt="" />
            </div>
            <hr className='border-1 w-full' />

            <div className='py-4'>
                <h1 className='mx-auto text-center'>CopyRight <span>&copy;</span> Zufaa.Inc</h1>
            </div>
        </div>
    );
};

export default Footer;