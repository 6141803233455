import React from 'react';
import { FaCog } from 'react-icons/fa';

const PageLoadign = () => {
    return (
        <div className="flex items-center justify-center">
            <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-blue-700 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
            </div>
        </div>
    );
};

export default PageLoadign;