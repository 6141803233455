import React, { useEffect, useState } from 'react';
import './Location.css'
import PlaceIcon from '@mui/icons-material/Place';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import axios from 'axios';
import { useContext } from 'react';
import { LocationContext } from '../Context/LocationProvider/LocationProvider';


const Location = ( { currentRoute } ) => {
    const [ long, setLong ] = useState();
    const [ lat, setLat ] = useState();
    const [ address, setAddress ] = useState();
    const { setLocation } = useContext( LocationContext )


    const options = {
        enableHighAccuracy: true,
        timeout: 10000,
    };

    const successCallback = ( position ) => {
        // console.log(position);
        setLat( position.coords.latitude
        )
        setLong( position.coords.longitude
        )
    };

    const errorCallback = ( error ) => {
        // console.error( "Location not found or access denied" );
    };

    navigator.geolocation.getCurrentPosition( successCallback, errorCallback, options );

    // const id = navigator.geolocation.watchPosition( successCallback, errorCallback, options );
    // const loctaionOfUserURL = ` https://nominatim.openstreetmap.org/reverse?lat=<value>&lon=<value>&<params>`

    useEffect( ( () => {

        axios.get( `https://nominatim.openstreetmap.org/reverse?format=json&lat=${ lat }&lon=${ long }&zoom=18&addressdetails=1` )
            .then( res => {
                // console.log(res.data);
                setAddress( res.data.address )
                setLocation( res.data.address )
            } ).catch( err => {
                // console.log( err );
            } )
    } ), [ long, lat, setLocation ] )

    return (
        <div>
            <Box sx={ { display: "flex", alignItems: "center", justifyItems: "center", gap: 1 } }>
                <PlaceIcon fontSize='18px'></PlaceIcon>
                <Typography variant={ currentRoute?.pathname === '/' ? "text1" : 'text1alt' }>{ address ? address?.country : "Location" }</Typography>
            </Box>
        </div>
    );
};

export default Location;