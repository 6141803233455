import React from 'react';
import { FaFacebook, FaWhatsapp } from 'react-icons/fa';
import insta from '../../Images/social media/insta.png';

const UserContactDetails = ({ saveUser }) => {

    const { fburl, instaurl, waurl } = saveUser;

    return (
        <div className='grid grid-cols-2 md:grid-cols-3 md:gap-3 gap-2'>
            <a
                href={fburl}
                target="_blank"
                rel="noreferrer"
                style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='btn btn-md bg-transparent border-none text-blue-800 hover:text-[#F1F1F1] hover:bg-sky-800 duration-1000'>
                <div className='flex gap-2 items-center'>
                    <FaFacebook className='text-2xl' />
                    <p>Go to FaceBook</p>
                </div>
            </a>

            <a
                href={instaurl}
                target="_blank"
                rel="noreferrer"
                style={{ boxShadow: '1px 1px 8px 2px gray ' }}
                className='btn btn-md bg-transparent border-none hover:bg-pink-300 duration-500'>
                <div className='flex gap-2 items-center'>
                    <img className='w-7' src={insta} alt="" />
                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500'>
                        Go Instagram
                    </p>
                </div>
            </a>
            <a
                href={waurl}
                target="_blank"
                rel="noreferrer"
                style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='btn btn-md bg-transparent border-none text-green-700 hover:text-[#F1F1F1] hover:bg-green-700 duration-700'>
                <div className='flex gap-2 items-center'>
                    <FaWhatsapp className='text-2xl' />
                    <p>Go WhatsApp</p>
                </div>
            </a>
        </div>
    );
};

export default UserContactDetails;