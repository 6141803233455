import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import './ReAuth.css'
import { Box, Typography, TextField, } from '@mui/material';

const Transition = React.forwardRef( function Transition ( props, ref ) {
    return <Slide direction="up" ref={ ref } { ...props } />;
} );

export default function ReAuthDialouge ( { dialouge, handleClose, promptForCredentials } ) {
    // const [ open, setOpen ] = React.useState( false );

    // const handleClickOpen = () => {
    //     setOpen( true );
    // };

    const [ dialougeEmail, setDialougeEmail ] = React.useState( '' );
    const [ dialougePass, setDialougePass ] = React.useState( '' );

    const handleDialougeEmail = ( event ) => {
        setDialougeEmail( event.target.value );
    }
    const handleDialougePass = ( event ) => {

        setDialougePass( event.target.value );
    }
    const handleAuth = () => {
        promptForCredentials( dialougeEmail, dialougePass );

    }
    return (
        <div>
            {/* <Button variant="outlined" onClick={ handleClickOpen }>
                Slide in alert dialog
            </Button> */}
            <Dialog
                open={ dialouge }
                TransitionComponent={ Transition }
                keepMounted
                onClose={ handleClose }
                aria-describedby="alert-dialog-slide-description"
                sx={ { color: "black" } }
            >
                <DialogTitle sx={ { fontSize: "2em" } }>{ "Please re-authenticate" }</DialogTitle>
                <DialogContent>

                    <Box>
                        <Typography variant="h5" >Email</Typography>
                        <TextField
                            id="dialougeEmail"
                            label="Email"
                            value={ dialougeEmail }
                            onChange={ ( event ) => handleDialougeEmail( event ) }
                            variant='outlined'
                            type={ "email" }
                            sx={ {
                                width: "100%", backgroundColor: "#F1F1F1",
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#C6DECD',
                                }, borderRadius: 2, "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "green"
                                }, "&:hover": {
                                    "&& fieldset": {
                                        border: "1px solid green"
                                    }
                                },
                                "& .Mui-disabled": {
                                    backgroundColor: "#BFEAF5",

                                },
                            } }
                            InputLabelProps={ { style: { fontWeight: "600", } } }
                        />

                    </Box>
                    <Box>
                        <Typography variant="h45" >Password</Typography>
                        <TextField
                            id="dialougePass"
                            label="Password"
                            value={ dialougePass }
                            onChange={ ( event ) => handleDialougePass( event ) }
                            variant='outlined'
                            type={ "password" }
                            sx={ {
                                width: "100%", backgroundColor: "#F1F1F1",
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#C6DECD',
                                }, borderRadius: 2, "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "green"
                                }, "&:hover": {
                                    "&& fieldset": {
                                        border: "1px solid green"
                                    }
                                },
                                "& .Mui-disabled": {
                                    backgroundColor: "#BFEAF5",

                                },
                            } }
                            InputLabelProps={ { style: { fontWeight: "600", } } }
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button sx={ { color: "black", fontWeight: "600" } } onClick={ handleClose }>Cancel</Button>
                    <Button sx={ { color: "black", fontWeight: "600" } } onClick={ handleAuth }>Enter</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}