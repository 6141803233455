import { Box, Container, Hidden, Typography } from '@mui/material';
import React from 'react';
import FindVehicleSearchBar from '../../Components/FindVehicleSearchBar/FindVehicleSearchBar';
import curevyArrow from '../../Images/heroImages/curved-arrow.png'


import './Home.css'
import HeroMobile from '../../Components/HeroMobile/HeroMobile';
import BrowseItems from '../../Components/BrowseItems/BrowseItems';
import MostRecentCar from '../../Components/MostRecentCar/MostRecentCar';
import PostadMobile from '../../Components/PostadMobile/PostadMobile';
import TopViewsCar from '../../Components/TopViewsCar/TopViewsCar';
import NeaerbyCars from '../../Components/NearbyCars/NeaerbyCars';
import ChooseByBrand from '../../Components/ChooseByBrand/ChooseByBrand';
import FeedBack from '../../Components/FeedBack/FeedBack';
import PageLoadign from '../PageLoading/PageLoadign';

const Home = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <div className='outside-div'>
                <Box className='hero-background' sx={{ maxWidth: { xs: "500px", sm: "800px", md: "1200px", lg: "1200px", xl: "1440px" }, width: 'auto', mx: "auto", pt: { xs: 5, sm: 0 } }}>
                    <Container className='search-bar-3-options' sx={{}} >
                        <Hidden smDown>
                            <Box sx={{ width: "fit-content", display: "flex", flexDirection: "row-reverse", alignItems: "flex-start", justifyContent: "start", ml: { xs: 0, sm: 0, md: 5, lg: 8 } }}>
                                <Box sx={{ display: 'flex', flexDirection: "column", alignItems: "center", position: "relative", left: "-70px", bottom: "90px" }}>
                                    <Typography variant="h6" sx={{ color: "#F48B34", fontSize: "80px", fontWeight: "500", lineHeight: '119px' }}>1000+</Typography>
                                    <Typography variant="p" sx={{ color: "white", fontSize: "20px", fontWeight: "500", lineHeight: '10px' }}></Typography>
                                </Box>
                                <Box sx={{ width: "100px", }}>
                                    <img width={"100%"} height={'auto'} src={curevyArrow} alt="a-curvy-arrow" />
                                </Box>
                            </Box>
                        </Hidden>
                        <FindVehicleSearchBar></FindVehicleSearchBar>
                    </Container>
                    {/* Search bar */}
                    {/* <Hidden >
                        <HeroMobile></HeroMobile>
                    </Hidden> */}
                </Box >
            </div >
            {/* <PageLoadign /> */}
            <BrowseItems />
            <PostadMobile />
            <MostRecentCar />
            <TopViewsCar />
            {/* <NeaerbyCars /> */}
            {/* <ChooseByBrand /> */}
            <FeedBack />
        </div>
    );
};

export default Home;