import React, { useContext, useState } from 'react';
import logo from '../../Images/logo/reuse-signinandup-logo.png'
import google from '../../Images/logo/Google.png'
import facebook from '../../Images/logo/Facebook.png'
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../Context/AuthProvider';
import { doc, setDoc } from "firebase/firestore";
import axios from 'axios';

const SignUp = () => {
    const { createUser, googleLogin, updateUser, emailVerification, facebookLogin } = useContext(AuthContext)
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [phonenumber, setPhoneNumber] = useState(null);
    const [error, setError] = useState('');
    const [providerError, setProviderError] = useState('');


    const location = useLocation();
    let from = location.state?.from?.pathname || '/';



    const onSubmit = data => {
        setPhoneNumber(data?.phone)
        // Register/SignUp here----------------
        createUser(data.email, data.password)
            .then(result => {
                console.log(result);
                // Email verification----------
                emailVerification()
                    .then(() => {
                        alert("Email verification Message sent! Please Verify Your Email");

                    })
                    .catch(error => {
                        console.log(error);
                    })

                // user save to database---------
                const user = {
                    name: data?.name,
                    email: result?.user.email,
                    phone: data?.phone,
                    u_id: result?.user.uid,
                    vStatus: result?.user.emailVerified

                }
                // user update for firebase---------------
                const profile = {
                    displayName: data.name,
                    phoneNumber: data.phone
                }

                updateUser(profile)
                    .then(() => {
                        saveUsertoDB(user)
                        // navigate('/')
                        // console.log( data );
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
            .catch(error => {
                // console.log(error);
                setError(error.code)
            })
    };

    // Google Login-----------------------
    const handleGoogleLogin = () => {
        googleLogin()
            .then(result => {
                // console.log(result.user);
                const user = {
                    name: result?.user.displayName,
                    email: result?.user.email,
                    u_id: result?.user.uid,
                    vStatus: result?.user.emailVerified

                }
                // console.log(user);
                saveUsertoDB(user)
                navigate('/')
            })
            .catch(error => {
                console.log(error);
            })
    }

    // Facebook Login------------------------------------
    const handleFacebookLogin = () => {

        facebookLogin()
            .then(result => {
                console.log(result);
                const user = {
                    name: result?.user.displayName,
                    email: result?.user.email,
                    u_id: result?.user.uid,
                    vStatus: result?.user.emailVerified

                }
                saveUsertoDB(user)
                // navigate( '/' )
                navigate(from, { replace: true });
            })
            .catch(error => {
                if (error.code === 'auth/account-exists-with-different-credential') {
                    setProviderError('Facebook Email Already in Used!')
                }
            })
    }


    // Save User to database done------------------Pintu
    const saveUsertoDB = (user) => {
        // user Save to database done-------------Pintu
        axios.post('https://api.reuse.sale/user/api/create.php', {
            "u_fullname": user?.name,
            "u_email": user?.email,
            "u_password": user?.u_id,
            "u_phone": user?.phone,
            "u_image": '',
            "code": '',
            "status": `${user?.vStatus ? 'true' : 'false'}`,
            "pstatus": '',
            "fburl": '',
            "instaurl": '',
            "waurl": '',
        })

            .then(res => {
                console.log(res);
                navigate('/')
            })
            .catch(error => {
                console.log(error);
            })
    }


    return (
        <div className='w-full md:w-1/2 mx-auto shadow-2xl p-10 mt-16 rounded-lg'>
            <div className='w-2/5 mx-auto'>
                <img className='w-full' src={logo} alt="" />
            </div>
            <h1 className='text-3xl font-bold text-center my-5'>Create Account</h1>

            {/* Login with google and facebook--------------- */}
            <div className='grid grid-flow-row md:grid-cols-2 gap-4 md:gap-2'>
                <button onClick={handleGoogleLogin} className='flex justify-center border rounded-md px-8 text-center py-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={google} className='w-6' alt="" />
                        <h1 className='font-semibold'>Sign in with Google</h1>
                    </div>
                </button>
                <button onClick={handleFacebookLogin} className='flex justify-center border rounded-md px-8 text-center py-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={facebook} className='w-6' alt="" />
                        <h1 className='font-semibold'>Sign in with Facebook</h1>
                    </div>
                </button>
            </div>
            <p className='text-error text-right'>{providerError}</p>
            <div className="divider my-4">OR</div>

            {/* Login with Email and password----------- */}
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2'>
                <div>
                    <label>
                        <span className="font-semibold">Full Name:</span>
                    </label>
                    <input type="name" placeholder="Enter your Full Name" className="input border-2 text-black font-semibold input-bordered w-full bg-transparent"{...register("name", { required: true })} />
                </div>
                <div>
                    <label>
                        <span className="font-semibold">Email:</span>
                    </label>
                    <input type="Email" placeholder="Enter your Email" className="input border-2 text-black font-semibold input-bordered w-full bg-transparent"{...register("email", { required: true })} />
                </div>
                <div>
                    <label>
                        <span className="font-semibold">Phone Number:</span>
                    </label>
                    <input type="number" placeholder="Enter your Phone Number" className="input border-2 text-black input-bordered w-full bg-transparent" {...register("phone", { required: true })} />
                </div>

                <div className='relative'>
                    <label>
                        <span className="font-semibold">Password:</span>
                    </label>
                    <input type={showPassword ? 'text' : 'password'} placeholder="Enter your Password" className="input border-2 text-black font-semibold input-bordered w-full bg-transparent" {...register("password", { required: true })} />
                    <div className='absolute top-9 right-4'>
                        {
                            showPassword ?
                                <BsFillEyeSlashFill onClick={() => setShowPassword(!showPassword)} className='text-2xl' />
                                :
                                <BsFillEyeFill onClick={() => setShowPassword(!showPassword)} className='text-2xl' />
                        }
                    </div>
                </div>
                {
                    error &&
                    <p className='text-error'>{error}</p>
                }
                <button className='btn bg-[#589BB1] border-none hover:bg-[#3a7285] duration-700 mt-5 text-white'>Sign UP</button>
            </form>
            <p className='text-[#589BB1] font-medium mt-2 hover:text-success duration-300'>Forgot password?</p>
            <p className='text-center font-semibold mt-8'>Already have an account? <Link to='/login' className='text-[#589BB1] font-semibold hover:text-success duration-300'> Log In</Link></p>

        </div>
    );
};

export default SignUp;