import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import AuthProvider from './Context/AuthProvider';
import SearchContext from './Context/SearchContext/SearchContext';

import LocationProvider from './Context/LocationProvider/LocationProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({

  spacing: 4,
  palette: {

    background: {
      default: "#F6F6F6",
    },

    primary: {
      main: "#F5F5F5",
    },
    secondary: {
      main: '#171827',
    },
    thirdColor: {
      main: "#589BB117",
    },
    footer: {
      main: "#589BB1",
    },
    textColorOne: {
      main: "#B6B0B0",
    },

  },

  typography: {

    text1: {

      fontFamily: ['Titillium Web', 'sans-serif'].join(','),

      fontSize: 16,
      color: "#B6B0B0",
    },
    text1alt: {


      fontFamily: ['Titillium Web', 'sans-serif'].join(','),

      fontSize: 16,
      color: "#171827",
    },

    text2: {

      fontFamily: ['Titillium Web', 'sans-serif'].join(','),
      fontSize: 16,
      lineHeight: 24.34,
    },

    text3: {


      fontFamily: ['Titillium Web', 'sans-serif'].join(','),
      fontSize: 18,
      lineHight: 27.38,
      color: "#000000",
    },

    priceFont: {

      fontFamily: ['Titillium Web', 'sans-serif'].join(','),

      fontSize: 14,
      lineHight: 16.8,
      color: "rgba( 0, 0, 0, 0.7 )",
    },

    signInHeadingFont: {

      fontFamily: ['Titillium Web', 'sans-serif'].join(','),

      fontSize: "24px",
      fontWeight: "700",
      lineHeight: "29px",
      textAlign: 'left',

    },
    profileHeading: {

      fontFamily: ['Titillium Web', 'sans-serif'].join(','),

      fontSize: "24px",
      color: "#589BB1",
      lineHeight: "28.8px",
    }


  },


});



root.render(
  <React.StrictMode>
    <AuthProvider>

      <LocationProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline></CssBaseline>
          <SearchContext>
            <App />
          </SearchContext>
        </ThemeProvider>
      </LocationProvider>

    </AuthProvider >

    {/* <UserAuthContext>
      <ThemeProvider theme={ theme }>
        <CssBaseline></CssBaseline>
        <App />
      </ThemeProvider>
    </UserAuthContext> */}


  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
