import React from 'react';
import './Blog.css'
import headerimg from '../../Images/hero/blog-seo.jpg'
import headerover from '../../Images/hero/Rectangle 2381.png'

const Blog = () => {
    return (
        <div>
            <div className='relative'>
                <img className='absolute h-96 w-full' src={ headerover } alt="" />
                <img className='h-96 w-full object-cover' src={ headerimg } alt="" />
                <div className='absolute top-1/4 mx-auto w-11/12 text-white'>
                    <div >
                        <h1 className='text-center text-6xl font-semibold my-5'>Your Blog Title</h1>
                        <p
                            className='w-1/2 mx-auto text-center'
                        >Lorem ipsum dolor sit amet consectetur. Gravida viverra a eu elit egestas sed enim volutpat scelerisque. Et aliquam duis id lacus aliquam leo risus tortor. Nullam quam arcu aliquam lacus.</p>

                    </div>
                </div>
            </div>
            <div className='my-16 w-11/12 mx-auto'>
                <h1 className='font-semibold text-lg text-[#589BB1] my-2'>Featured Articles</h1>
                <hr className='w-full' />
            </div>
        </div>
    );
};

export default Blog;