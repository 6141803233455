import Main from '../Layout/Main';
import Home from '../Pages/Home/Home';
import FindAVehicle from '../Pages/FindAVehicle/FindAVehicle';
import HowItWorks from '../Pages/HowItWorks/HowItWorks';
import FAQ from '../Pages/FAQ/FAQ';
// import PostYourAD from '../Pages/PostYourAD/PostYourAD'
import Contact from '../Pages/Contact/Contact'
import { createBrowserRouter } from 'react-router-dom';
import Profile from '../Pages/Profile/Profile';
import Advertise from '../Components/Advertise/Advertise';
import Aboutus from '../Components/AboutUs/Aboutus';
import Blog from '../Pages/Blog/Blog';
import Category from '../Components/Category/Category';
import SingleproductDetails from '../Components/SingleProductDetails/SingleproductDetails';
import Login from '../Login/Login/Login';
import SignUp from '../Login/SignUp/SignUp';
import AllUsers from '../AllUsers/AllUsers';
import UserProfile from '../Pages/UserProfile/UserProfile';
import PrivateRoutes from './PrivateRoutes/PrivateRoutes';
import UserContactDetails from '../Pages/UserContactDetails/UserContactDetails';
import ProductContactDetails from '../Pages/ProductContactDetails/ProductContactDetails';
import TermsCondition from '../Components/TermsCondition/TermsCondition';
import PrivacyPolicy from '../Components/PrivacyPolicy/PrivacyPolicy';




export const router = createBrowserRouter( [
  {
    path: '/',
    element: <Main></Main>,
    children: [
      {
        path: '/',
        element: <Home></Home>
      },
      // {
      //   path: 'home',
      //   element: <Home></Home>
      // },
      {
        path: '/login',
        element: <Login></Login>
      },
      {
        path: '/signup',
        element: <SignUp></SignUp>
      },
      {
        path: '/findavehicle',
        // element: <FindAVehicle></FindAVehicle>
        element: <Category></Category>

      },
      {
        path: 'howitworks',
        element: <HowItWorks></HowItWorks>
      },
      {
        path: 'faq',
        element: <FAQ></FAQ>
      },
      // {
      //   path: 'postyourad',
      //   element: <PostYourAD></PostYourAD>
      // },
      {
        path: '/advertise',
        element: <PrivateRoutes><Advertise></Advertise></PrivateRoutes>
      },
      {
        path: 'contact',
        element: <Contact></Contact>
      },
      {
        path: "/blog",
        element: <Blog></Blog>
      },
      {
        path: '/profile',
        element: <Profile></Profile>
      },
      {
        path: '/userprofile',
        element: <UserProfile></UserProfile>
      },
      {
        path: '/aboutus',
        element: <Aboutus />
      },
      {
        path: '/category',
        element: <Category></Category>
      },
      {
        path: '/singleProduct/:id',
        element: <SingleproductDetails></SingleproductDetails>,
        loader: ( { params } ) => fetch( `https://api.reuse.sale/listing/api/single_read.php?list_id=${ params?.id }` )
      },
      {
        path: '/alluser',
        element: <AllUsers></AllUsers>
      },
      {
        path: '/productContact/:id',
        element: <PrivateRoutes><ProductContactDetails></ProductContactDetails></PrivateRoutes>
      },
      {
        path: '/termscondition',
        element: <TermsCondition></TermsCondition>
      },
      {
        path: '/pivacypolicy',
        element: <PrivacyPolicy></PrivacyPolicy>
      }

    ]
  },

] )