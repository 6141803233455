import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { BsFillEyeFill, BsFillEyeSlashFill, BsSpeedometer2 } from 'react-icons/bs';
import { FaCog, FaFacebook, FaWhatsapp } from 'react-icons/fa';
import Swal from 'sweetalert2';
import 'react-phone-number-input/style.css'
import userImage from '../../Images/placeHolder/user.webp'
import { AuthContext } from '../../Context/AuthProvider';
import insta from '../../Images/social media/insta.png';
import { useForm } from 'react-hook-form';
import { GoLocation } from 'react-icons/go';
import { Link } from 'react-router-dom';

const UserProfile = () => {
    const { user, updateUser, updateUserPassword, updateUserEmail } = useContext(AuthContext);
    const { register: register2, handleSubmit: handlesubmit2, reset: reset2, formState: { errors: errors2 } } = useForm();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const [saveUser, setSaveUser] = useState();
    const [isDisable, setDisable] = useState(true);
    const [refresh, setRefresh] = useState(true)
    // const [name, setName] = useState('');
    // const [email, setEmail] = useState('');
    // const [phone, setPhone] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [linkShow, setLinkShow] = useState(false);
    const [social, setSocial] = useState('');
    const [allPrducts, setAllProducts] = useState([]);

    const userproducts = [];


    // Get save User from db------------------
    useEffect(() => {
        fetch(`https://api.reuse.sale/user/api/authentication.php?u_password=${user?.uid}`)
            .then(res => res.json())
            .then(data => {
                // console.log(data);
                window.scrollTo(0, 0);
                setSaveUser(data);
                // console.log( data );
            })
    }, [user?.uid, refresh]);


    // get all Product-------------------------
    const getProductUrl = 'https://api.reuse.sale/listing/api/read.php';
    useEffect(() => {
        fetch(getProductUrl)
            .then(res => res.json())
            .then(data => {
                setAllProducts(data.body)
                // console.log( data.body );
            })
    }, [])


    for (const product of allPrducts) {
        // console.log( product.u_id );
        if (product?.u_id === saveUser?.u_id) {
            userproducts.push(product);
        }
    }


    // const handleName = (e) => {
    //     setName(e.target.value)
    // }
    // const handleEmail = (e) => {
    //     setEmail(e.target.value)
    //     // console.log( e.target.value );
    // }
    // const handlePhone = (e) => {
    //     setPhone(e.target.value)
    //     // console.log( e.target.value );
    // }

    const handleNewPassword = (e) => {
        setNewPassword(e.target.value)
        // console.log( e.target.value );
    }
    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
        // console.log( e.target.value );
    }

    // image Upload............
    const handleImageUpload = (e) => {
        const formData = new FormData();
        const pic = e.target.files[0]
        formData.append('file', pic);

        fetch('https://api.reuse.sale/listing/api/upload.php', {

            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(data => {
                // console.log( data[ 0 ] );
                const updateUser = {
                    image: data[0]
                }
                saveUsertoDB(updateUser);
                setRefresh();
            })
            .catch(error => {
                console.error('Error:', error);

            });

    }


    // update details functions------------
    // update user Name------------
    const handleNameUpdate = (name) => {
        // console.log( name );
        const profile = {
            displayName: name
        }
        updateUser(profile)
            .then(() => {
                setRefresh(!refresh)
                saveUsertoDB(user)
                Swal.fire(
                    'Good job!',
                    'User Name Successfully Changed !!',
                    'success'
                )
                setDisable(!isDisable)
            })
            .catch(error => {
                console.log(error);
            })
    }

    // update user email----------
    const handleEmailUpdate = (email) => {
        console.log(email);

        updateUserEmail(email)
            .then(result => {
                setRefresh(!refresh)
                saveUsertoDB(user)
                Swal.fire(
                    'Good job!',
                    'Email Successfully Changed !!',
                    'success'
                )
                setDisable(!isDisable)
            })
            .catch(error => {
                console.log(error);
            })
    }

    // update user Phone number------------
    const handlePhoneUpdate = (phone) => {
        console.log(phone);
        saveUsertoDB(phone);
        setDisable(!isDisable)
    }

    // update user password---------------
    const handlePasswordUpdate = () => {
        if (newPassword === confirmPassword) {
            console.log(' Matched');
            updateUserPassword(confirmPassword)
                .then(result => {
                    setRefresh(!refresh)
                    Swal.fire(
                        'Good job!',
                        'Password Successfully Changed !!',
                        'success'
                    )

                    setDisable(!isDisable)
                })
                .catch(error => {
                    console.log(error);
                })
        }
        else {
            console.log('Wrong password');
        }
    }

    // social media handle----------
    const handleFacebook = () => {
        setSocial('Facebook');
        setLinkShow(!linkShow)
    }
    const handleInsta = () => {
        setSocial('Instagram');
        setLinkShow(!linkShow)
    }

    const handleWhatsapp = () => {
        setSocial('WhatsApp');
        setLinkShow(!linkShow)
    }


    // user Info save to database--------------

    const saveUsertoDB = (updateUser) => {
        console.log(updateUser);

        // const userDetails = {
        //     "u_fullname": updateUser?.displayName ? updateUser?.displayName : saveUser.u_fullname,
        //     "u_email": updateUser?.email ? updateUser?.email : saveUser.u_email,
        //     "u_password": updateUser?.uid ? updateUser?.uid : saveUser.u_password,
        //     "u_phone": updateUser ? updateUser : saveUser.u_phone,
        //     "u_image": updateUser?.image ? updateUser.image : saveUser?.u_image,
        //     "u_id": saveUser?.u_id,
        //     "code": '',
        //     "status": `${ updateUser?.vStatus ? 'true' : 'false' }`,
        //     "pstatus": '',
        //     "fburl": updateUser?.fbLink ? updateUser.fbLink : saveUser?.fburl,
        //     "instaurl": updateUser?.instaUrl ? updateUser.instaUrl : saveUser?.instaurl,
        //     "waurl": updateUser?.whatsappUrl ? updateUser.whatsappUrl : saveUser?.waurl,
        // }
        // // console.log( updateUser.link );
        // // console.log( updateUser );
        // console.log( userDetails );

        axios.post('https://api.reuse.sale/user/api/update.php', {
            "u_fullname": updateUser?.displayName ? updateUser?.displayName : saveUser.u_fullname,
            "u_email": updateUser?.email ? updateUser?.email : saveUser.u_email,
            "u_password": updateUser?.uid ? updateUser?.uid : saveUser.u_password,
            "u_phone": updateUser ? updateUser : saveUser.u_phone,
            "u_image": updateUser?.image ? updateUser.image : saveUser?.u_image,
            "u_id": saveUser?.u_id,
            "code": '',
            "status": `${updateUser?.vStatus ? 'true' : 'false'}`,
            "pstatus": saveUser?.pstatus ? 'false' : 'true',
            "fburl": updateUser?.fbLink ? updateUser.fbLink : saveUser?.fburl,
            "instaurl": updateUser?.instaUrl ? updateUser.instaUrl : saveUser?.instaurl,
            "waurl": updateUser?.whatsappUrl ? updateUser.whatsappUrl : saveUser?.waurl,
        })

            .then(res => {
                console.log(res);
                setRefresh(!refresh)
            })
            .catch(error => {
                console.log(error);
            })
    }


    const onSubmit2 = (data) => {
        handleNameUpdate(data?.name ? data.name : user?.displayName);
        handleEmailUpdate(data?.email ? data.email : user?.email);
        handlePhoneUpdate(data?.phone ? data.phone : saveUser?.u_phone);
        console.log(data);
        reset2()
    }

    // social media input here--------------------
    const onSubmit = data => {
        const name = (Object.keys(data)[0]);
        if (name === 'Facebook') {
            console.log(data[name]);
            const fbUrl = { fbLink: data[name] }
            saveUsertoDB(fbUrl)
        }
        else if (name === 'Instagram') {
            console.log(data[name]);
            const instaUrl = { instaUrl: data[name] }
            saveUsertoDB(instaUrl)
        }
        else if (name === 'WhatsApp') {
            console.log(data[name]);
            const whatsappUrl = { whatsappUrl: data[name] }
            saveUsertoDB(whatsappUrl)
        }

        // console.log( data[ name ] );
        setLinkShow(!linkShow)
        reset()
    };



    return (
        <div style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='w-11/12 mx-auto mt-8 rounded-lg p-5 md:p-14' >

            <div className='block md:flex md:justify-between md:gap-8'>
                {/* User detail ----------- */}
                <div className='w-full md:w-1/3 mx-auto flex flex-col'>

                    {/* <div className="avatar flex-col relative"> */}
                    {/* <div className="w-36 mx-auto rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                            <img src={ saveUser?.u_image ? saveUser.u_image : userImage } alt='' />
                        </div>
                        <div className='absolute top-28 right-28 z-10'>
                            <label htmlFor="upload-photo">
                                <BiImageAdd className='text-3xl bg-white rounded-lg' />
                            </label>
                            <input onChange={ handleImageUpload } type="file" name="photo" id="upload-photo" className='hidden' />
                        </div> */}

                    <label htmlFor="upload-photo" className="avatar w-36 mx-auto tooltip tooltip-bottom tooltip-accent " data-tip="Upload Photo">
                        <div className="mx-auto rounded-full ring ring-[#16a7d7] ring-offset-base-100 ring-offset-2">
                            <img src={saveUser?.u_image ? saveUser.u_image : userImage} alt='' className='cursor-pointer hover:opacity-50 duration-500 ' />
                        </div>
                    </label>
                    <input onChange={handleImageUpload} type="file" name="photo" id="upload-photo" className='hidden' />


                    <h1 className='text-center mt-3 text-3xl font-bold'>{user?.displayName}</h1>
                    <h1 className='text-center font-bold'>{user?.email}</h1>
                    <h1 className='text-center text-gray-500 '>{saveUser?.u_phone}</h1>
                    {/* </div> */}
                    <h1 className='w-full text-center text-2xl font-semibold mt-5 text-[#589BB1]'>About</h1>
                    <p className='text-center'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto veniam veritatis laboriosam, iusto facere impedit fugiat recusandae in sapiente facilis voluptatum tempore labore, magnam cupiditate ipsum quisquam quos minus non nemo  sit molestiae!</p>
                </div>

                {/* User detail ----------- */}
                <div className='w-full md:w-2/3 mx-auto'>
                    <h1 className='text-center md:text-left text-2xl font-medium text-[#589BB1] mt-4 md:mt-0 '>Personal Details</h1>

                    {/* Name Email and Phone section---------- */}
                    <form key={2} onSubmit={handlesubmit2(onSubmit2)}>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                            {/* Name section-------- */}
                            <div className='text-xl  '>
                                <label className='label'>
                                    Full Name
                                </label>
                                <input
                                    // onChange={handleName}
                                    type="text"
                                    defaultValue={user?.displayName}
                                    disabled={isDisable}
                                    placeholder="Enter your Name"
                                    className={`${isDisable ? 'px-4 py-2 input-disabled rounded-lg bg-transparent border border-gray-300 text-gray-400 bg-opacity-40 w-full' : 'px-4 py-2 rounded-lg border bg-transparent border-gray-400 input-accent w-full '}`}
                                    {...register2('name')}
                                />

                            </div>
                            {/* Email Section----------------- */}
                            <div className='text-xl '>
                                <label className='label'>
                                    Email
                                </label>
                                <input
                                    // onChange={handleEmail}
                                    defaultValue={user?.email}
                                    disabled={isDisable}
                                    type="email"
                                    placeholder="Enter your Email"
                                    className={`${isDisable ? 'px-4 py-2 input-disabled rounded-lg bg-transparent border border-gray-300 text-gray-400 bg-opacity-40 w-full' : 'px-4 py-2 rounded-lg border bg-transparent border-gray-400 input-accent w-full '}`}
                                    {...register2('email')}
                                />

                            </div>
                            {/* Phone number section-------------- */}
                            <div className='text-xl '>
                                <label>
                                    Phone Number
                                </label>
                                <div className='flex gap-3 items-end'>

                                    <input
                                        // onChange={handlePhone}
                                        defaultValue={saveUser?.u_phone}
                                        disabled={isDisable}
                                        type="number"
                                        placeholder="Your Phone Number"
                                        className={`${isDisable ? 'px-4 py-2 input-disabled rounded-lg bg-transparent border border-gray-300 text-gray-400 bg-opacity-40 w-full' : 'px-4 py-2 rounded-lg border bg-transparent border-gray-400 input-accent w-full '}`}
                                        {...register2('phone')}
                                    />


                                    {/* <button className="btn btn-disabled btn-md border-none mt-4 text-white ">Verify</button>
                                    {/* <button className={ `${ isDisable ? "btn btn-disabled btn-md border-none mt-4 text-white " : 'btn btn-outline text-[#FF7200] btn-md border-[#FF7200] mt-4' }` }>Verify</button> */ }

                                </div>

                            </div>

                        </div>

                        {/* User Bio---------------- */}
                        <div className='mt-5 '>
                            <label className='label text-xl '>
                                Bio
                            </label>
                            <textarea
                                disabled={isDisable}
                                className={`${isDisable ? 'px-4 py-2 input-disabled md:h-32 rounded-lg bg-transparent border border-gray-300 text-gray-400 bg-opacity-40 w-full' : 'px-4 py-2 rounded-lg border bg-transparent md:h-32 border-gray-400 input-accent w-full '}`}
                                placeholder="Bio"
                                {...register2('bio')}
                            />

                        </div>

                        <input type="submit" value="Submit" className={`${isDisable ? "btn btn-disabled btn-md border-none mt-4 text-white disabled" : 'btn btn-accent btn-md border-none mt-4 text-white'}`} />
                    </form>

                    <hr className={`${isDisable ? 'border my-7' : 'border border-accent my-7'}`} />

                    {/* password section -------------- */}
                    <div>
                        <div className=' text-xl '>
                            <label className='label'>
                                New Password
                            </label>
                            <input
                                onChange={handleNewPassword}
                                type={showPassword ? 'text' : 'password'}
                                placeholder="New Password"
                                disabled={isDisable}
                                className={`${isDisable ? 'px-4 py-2 input-disabled rounded-lg bg-transparent border border-gray-300 text-gray-400 bg-opacity-40 w-full' : 'px-4 py-2 rounded-lg border bg-transparent border-gray-400 input-accent w-full '}`} />

                        </div>
                        <div className='relative mt-5 text-xl '>
                            <label className='label'>
                                Confirm Password
                            </label>
                            <input
                                onChange={handleConfirmPassword}
                                type={showPassword ? 'text' : 'password'}
                                disabled={isDisable}
                                placeholder="Confirm Password"
                                className={`${isDisable ? 'px-4 py-2 input-disabled rounded-lg bg-transparent border border-gray-300 text-gray-400 bg-opacity-40 w-full' : 'px-4 py-2 rounded-lg border bg-transparent border-gray-400 input-accent w-full '}`} />
                            {
                                !isDisable ?
                                    <div className='absolute top-14 right-4' >
                                        {
                                            showPassword ?
                                                <BsFillEyeSlashFill onClick={() => setShowPassword(!showPassword)} className='text-2xl' />
                                                :
                                                <BsFillEyeFill onClick={() => setShowPassword(!showPassword)} className='text-2xl' />
                                        }
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                        <button onClick={handlePasswordUpdate} className={`${isDisable ? "btn btn-disabled btn-md border-none mt-4 text-white disabled" : 'btn btn-md btn-info btn-outline mt-5'}`}>Update Password</button>
                    </div>

                    <hr className={`${isDisable ? 'border my-7' : 'border border-accent my-7'}`} />

                    {/* all button Enable and Disable section--------------- */}
                    <button onClick={() => setDisable(!isDisable)} type="button" className="btn btn-accent text-lg" >
                        <FaCog style={{ animation: 'spin 2s linear infinite' }} className='animate-spin h-6 w-6 mr-3' />
                        Edit Setting
                    </button>



                    <hr className={`${isDisable ? 'border my-7' : 'border-accent my-7'}`} />

                    {/* social media input-------------- */}
                    <h1 className='text-center md:text-left text-2xl font-medium text-[#589BB1] mb-0 md:mb-4 py-5 md:py-0'>Linked Accounts</h1>

                    {/* social media linked --------------- */}
                    {
                        linkShow &&
                        <div className='text-xl  mb-5'>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className='flex items-end gap-4'>
                                <div className='w-full'>
                                    <label>
                                        {`Enter Your ${social}`}
                                    </label>
                                    <input
                                        // onChange={handleName}
                                        type="text"
                                        placeholder={`Enter Your ${social}`}

                                        className='px-4 py-2 rounded-lg border bg-transparent input-bordered input-accent w-full '
                                        {...register(`${social}`)}
                                    />

                                </div>
                                {
                                    linkShow &&
                                    <input type="submit"
                                        className='btn btn-accent btn-md border-none mt-4 text-white' />
                                    // <button
                                    //     onClick={ handleLinkubmit }
                                    //     className='btn btn-accent btn-md border-none mt-4 text-white'>
                                    //     Submit
                                    // </button>
                                }
                            </form>
                        </div>
                    }
                    {/* social media linked --------------- */}

                    <div className='flex md:flex-row flex-col gap-4'>
                        {/* Facebook ----------------- */}
                        <div className="dropdown dropdown-end">
                            <div tabIndex={0} style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='btn btn-md w-full bg-transparent border-none text-blue-800 hover:text-[#F1F1F1] hover:bg-sky-800 duration-1000 '>
                                <div className='flex gap-2 items-center'>
                                    <FaFacebook className='text-2xl' />
                                    <p>FaceBook</p>
                                </div>
                            </div>
                            <div tabIndex={0} className="dropdown-content menu w-60 rounded-lg px-5 py-2 gap-4 bg-white">
                                {
                                    saveUser?.fburl ?
                                        <div onClick={handleFacebook} tabIndex={0} style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='btn btn-md bg-transparent border-none text-blue-800 hover:text-[#F1F1F1] hover:bg-sky-800 duration-1000'>
                                            <div className='flex gap-2 items-center'>
                                                <FaFacebook className='text-2xl' />
                                                <p>Update FaceBook</p>
                                            </div>
                                        </div>
                                        :
                                        <div onClick={handleFacebook} tabIndex={0} style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='btn btn-md bg-transparent border-none text-blue-800 hover:text-[#F1F1F1] hover:bg-sky-800 duration-1000'>
                                            <div className='flex gap-2 items-center'>
                                                <FaFacebook className='text-2xl' />
                                                <p>Add FaceBook</p>
                                            </div>
                                        </div>
                                }
                                <a
                                    href={`${saveUser?.fburl}`} target="_blank"
                                    rel="noreferrer"
                                    style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='btn btn-md bg-transparent border-none text-blue-800 hover:text-[#F1F1F1] hover:bg-sky-800 duration-1000'>
                                    <div className='flex gap-2 items-center'>
                                        <FaFacebook className='text-2xl' />
                                        <p>Go to FaceBook</p>
                                    </div>
                                </a>
                            </div>
                        </div>

                        {/* Instagram --------------------- */}
                        <div className="dropdown dropdown-end">
                            <div
                                tabIndex={0}
                                style={{ boxShadow: '1px 1px 8px 2px gray ' }}
                                className='btn btn-md bg-transparent border-none w-full hover:bg-pink-300 duration-700'>
                                <div className='flex gap-2 items-center'>
                                    <img className='w-7' src={insta} alt="" />
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500'>Instagram</p>
                                </div>
                            </div>
                            <div tabIndex={0} className="dropdown-content menu w-60 rounded-lg px-5 py-2 gap-4 bg-white">
                                {
                                    saveUser?.instaurl ?
                                        <div onClick={handleInsta} style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='btn btn-md bg-transparent border-none hover:bg-pink-300 duration-500'>
                                            <div className='flex gap-2 items-center'>
                                                <img className='w-7' src={insta} alt="" />
                                                <p className='text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500'>
                                                    Update Instagram
                                                </p>
                                            </div>
                                        </div>
                                        :
                                        <div onClick={handleInsta} style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='btn btn-md bg-transparent border-none hover:bg-pink-300 duration-500'>
                                            <div className='flex gap-2 items-center'>
                                                <img className='w-7' src={insta} alt="" />
                                                <p className='text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500'>
                                                    Add Instagram
                                                </p>
                                            </div>
                                        </div>
                                }
                                <a
                                    href={`${saveUser?.instaurl}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ boxShadow: '1px 1px 8px 2px gray ' }}
                                    className='btn btn-md bg-transparent border-none hover:bg-pink-300 duration-500'>
                                    <div className='flex gap-2 items-center'>
                                        <img className='w-7' src={insta} alt="" />
                                        <p className='text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500'>
                                            Go Instagram
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>


                        {/* WhatsApp----------------- */}
                        <div className="dropdown dropdown-end">
                            <div tabIndex={0} style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='btn btn-md w-full bg-transparent border-none text-green-700 hover:text-[#F1F1F1] hover:bg-green-700 duration-700'>
                                <div className='flex gap-2 items-center'>
                                    <FaWhatsapp className='text-2xl' />
                                    <p>WhatsApp</p>
                                </div>
                            </div>
                            <div tabIndex={0} className="dropdown-content menu w-60 rounded-lg px-5 py-2 gap-4 bg-white">
                                {
                                    saveUser?.waurl ?
                                        <div onClick={handleWhatsapp} style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='btn btn-md bg-transparent border-none text-green-700 hover:text-[#F1F1F1] hover:bg-green-700 duration-700'>
                                            <div className='flex gap-2 items-center'>
                                                <FaWhatsapp className='text-2xl' />
                                                <p>Update WhatsApp</p>
                                            </div>
                                        </div>
                                        :
                                        <div onClick={handleWhatsapp} style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='btn btn-md bg-transparent border-none text-green-700 hover:text-[#F1F1F1] hover:bg-green-700 duration-700'>
                                            <div className='flex gap-2 items-center'>
                                                <FaWhatsapp className='text-2xl' />
                                                <p>Add WhatsApp</p>
                                            </div>
                                        </div>

                                }
                                <a
                                    href={`${saveUser?.waurl}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ boxShadow: '1px 1px 8px 2px gray ' }} className='btn btn-md bg-transparent border-none text-green-700 hover:text-[#F1F1F1] hover:bg-green-700 duration-700'>
                                    <div className='flex gap-2 items-center'>
                                        <FaWhatsapp className='text-2xl' />
                                        <p>Go WhatsApp</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* social media input-------------- */}
                </div>
            </div>


            <hr className='border my-10' />

            <h1 className='text-center md:text-left text-2xl font-medium text-[#589BB1] mb-0 md:mb-4 py-5 md:py-0'>You have posted {userproducts?.length} ads</h1>

            <div className='grid grid-cols-1 md:grid-cols-4 gap-5 mt-5'>
                {
                    userproducts?.map((product, i) =>
                        <Link
                            to={`/singleProduct/${product?.list_id}`}
                            key={i}
                            className="card rounded-md card-compact shadow-md">
                            <figure><img className='h-40 object-cover w-full' src={product?.image_one} alt="car" /></figure>
                            <div className="card-body">
                                <h2 className="card-title">2022 Toyota Corolla Cross</h2>
                                <div>
                                    <h1 className=' text-gray-600'>Price</h1>
                                    <p className='text-lg font-bold'>$ 2000</p>
                                </div>
                                <div className='flex justify-between'>
                                    <div className='flex gap-1 items-center'>
                                        <GoLocation className='text-lg text-orange-500' />
                                        <p>USA</p>
                                    </div>
                                    <div className='flex gap-1 items-center text-gray-500'>
                                        <BsSpeedometer2 className='text-lg' />
                                        <p>5,000 km</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )
                }
            </div>
        </div >
    );
};

export default UserProfile;