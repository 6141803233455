import React, { useState } from 'react';
import './FAQ.css'
import headerimg from '../../Images/hero/Group-1118.png'
import headerover from '../../Images/hero/Rectangle 2381.png'
import { FaPlus, FaMinus } from "react-icons/fa";

const accordions = [
    {
        id: 1
    },
    {
        id: 2
    },
    {
        id: 3
    },
    {
        id: 4
    },
    {
        id: 5
    },
    {
        id: 6
    },
]

const FAQ = () => {
    const [ selectAccordion, setSelectAccordion ] = useState();
    const [ sign, setSign ] = useState( false )


    const AccordionSelect = ( id ) => {
        setSelectAccordion( id )
        setSign( !sign )

    }

    return (
        <div>
            <div className='relative'>
                <img className='absolute h-full w-full' src={ headerover } alt="" />
                <img className='h-full w-full object-cover' src={ headerimg } alt="" />
                <div className='absolute top-1/3 left-1/3'>
                    <hr className='border-2 border w-96' />
                    <h1 className='text-center text-6xl font-semibold text-white my-5'>FAQ</h1>
                    <hr className='border-2 border w-96' />
                </div>
            </div>
            <div className='my-16'>
                <h1 className='text-3xl text-center font-bold underline'>Frequently Asked Questions</h1>
            </div>
            <div className='w-2/3 mx-auto flex flex-col gap-4'>
                {
                    accordions.map( accordion =>
                        <div
                            key={ accordion.id }
                            className="collapse border-y rounded-md"
                            onClick={ () => AccordionSelect( accordion.id ) }
                        >
                            <input type="checkbox" />
                            <div className="collapse-title text-xl font-medium flex justify-between items-center">
                                <h1 className='font-semibold'>Focus me to see content</h1>
                                {
                                    sign && selectAccordion === accordion.id ?
                                        <FaMinus className='text-[#00A590]' />
                                        :
                                        <FaPlus className='text-[#00A590]' />
                                }
                            </div>
                            <div className="collapse-content px-10">
                                <p>Some of us are car nuts, some of us aren't, but we're all passionate about solving big problems and building awesome products. While we're an impressive team (if we do say so ourselves) that works hard, we try to keep our culture ego-free and value creativity, autonomy, and fun.

                                </p>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default FAQ;