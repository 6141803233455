// import { CleaningServices } from '@mui/icons-material';
import { Box, Button, Hidden, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { searchContext } from '../../Context/SearchContext/SearchContext';
import SelectBox from '../SelectBox/SelectBox';
import './FindVehicleSearchBar.css'

// test option 1
const optionBrands = [
    { label: "Audi", value: "Audi" },
    { label: "BMW", value: "BMW" },
    { label: "Ford", value: "Ford" },
    { label: "Toyota", value: "Toyota" },
    { label: "Honda", value: "Honda" },
    { label: "Hyundai", value: "Hyundai" },
    { label: "Jeep", value: "Jeep" },
    { label: "Kia", value: "Kia" },
    { label: "Land Rover", value: "Land Rover" },
    { label: "Mitsubishi", value: "Mitsubishi" },
    { label: "Volvo", value: "Volvo" },
    { label: "Suzuki", value: "Suzuki" },
    { label: "Ferrari", value: "Ferrari" },
    { label: "Rolls-Royce", value: "Rolls-Royce" },
    { label: "Lamborghini", value: "Lamborghini" },
    { label: "FIAT", value: "FIAT" },
    { label: "Nissan", value: "Nissan" },
]
const optionModels = [
    { label: "Audi223", value: "audi223" },
    { label: "Audi242", value: "audi224" },
    { label: "Audi225", value: "audi225" },
    { label: "Audi226", value: "audi226" },
    { label: "Audi227", value: "audi227" },
    { label: "Audi228", value: "audi228" },

]
const optionLocations = [
    { label: "USA", value: "USA" },
    { label: "Canada", value: "canada" },

]

const FindVehicleSearchBar = () => {
    const { register, handleSubmit, reset, formState: { errors }, control } = useForm();
    const [ models, setModels ] = useState( [] );
    const navigate = useNavigate()
    const { setSearchText } = useContext( searchContext );
    // value handling requires adjustment

    const [ option1, setOption1 ] = useState();

    // get all model form CarModels.json file-------------
    useEffect( () => {
        fetch( 'CarModels.json' )
            .then( res => res.json() )
            .then( data => {
                setModels( data );
            } )
    }, [] )



    const handleValue = ( data ) => {
        console.log( data );
        setSearchText( data.brand.toLowerCase() )
        navigate( '/findavehicle' )

    }


    return (
        <div className='select-search-bar'>
            <Hidden smDown>
                <Box sx={ { borderRadius: 1, backgroundColor: "white", display: "flex", alignItems: "center", justifyContent: "center", maxWidth: { xs: "max-content", sm: "max-content", md: "max-content", lg: "max-content" }, mx: "auto", p: 2, gap: 12, boxShadow: 5 } }>

                    <form onSubmit={ handleSubmit( handleValue ) } className='w-full flex gap-4 justify-between'>
                        <div className='flex gap-4'>

                            {/* Brands here----------------- */ }
                            <input
                                list="brands"
                                type="text"
                                placeholder="Brands"
                                className="w-28 md:w-32 text-black bg-[#E0E0E0] input input-bordered" { ...register( "brand", { required: true } ) } />

                            <datalist id="brands">
                                {
                                    optionBrands.map( ( brand, i ) => <option key={ i }>{ brand?.value }</option> )
                                }
                            </datalist>

                            {/* Models here---------------- */ }

                            <input
                                list="models"
                                type="text"
                                placeholder="Models"
                                className="w-28 md:w-32 text-black bg-[#E0E0E0] input input-bordered"
                                { ...register( "model", { required: true } ) } />

                            <datalist id="models">
                                {
                                    models.map( model => <option key={ model.id }>{ model.name }</option> )
                                }
                            </datalist>

                            {/* Location here---------------- */ }
                            <input
                                list="location"
                                type="text"
                                placeholder="Location"
                                className="w-28 md:w-32 text-black bg-[#E0E0E0] input input-bordered"
                                { ...register( "location" ) } />

                            <datalist id="location">
                                {
                                    optionLocations.map( ( location, i ) => <option key={ i }>{ location?.value }</option> )
                                }
                            </datalist>

                        </div>
                        <input type='submit' value='Search' className='btn md:btn-wide bg-[#589BB1] hover:bg-[#135e78] border-none text-white' />
                    </form>


                    {/* <Stack direction={ "row" } alignItems="center" justifycontents="center" spacing={ 0 } sx={ { p: 0, width: "auto" } }>
                        <SelectBox options={ optionBrands } label={ "Brands" }></SelectBox>
                        <SelectBox options={ optionModels } label={ "Models" }></SelectBox>
                        <SelectBox options={ optionLocations } label={ "Location" }></SelectBox>
                    </Stack>
                    <Button onClick={ ( e ) => handleValue( e ) } disableElevation sx={ {
                        px: 6, borderRadius: '5px',
                        backgroundColor: "#589BB1", '&:hover': {
                            backgroundColor: '#4E6C50',
                        },
                    } }>
                        <Typography variant="p">Search </Typography>
                    </Button> */}

                </Box>
            </Hidden>

        </div>
    );
};

export default FindVehicleSearchBar;