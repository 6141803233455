import React, { useEffect, useRef, useState } from 'react';
import { FaCar, FaCheckCircle, FaClipboardList, FaCogs, FaGasPump, FaMinus, FaPlus, FaTachometerAlt, FaTimesCircle } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
import { BsSpeedometer2 } from 'react-icons/bs';
import { Link, useLocation, useParams } from 'react-router-dom';
import UserContactDetails from '../../Pages/UserContactDetails/UserContactDetails';
import { useContext } from 'react';
import { AuthContext } from '../../Context/AuthProvider';
import seat from '../../Images/icon/seat.png'
import gear from '../../Images/icon/gearbox.png'
import userImage from '../../Images/placeHolder/user.webp'
import axios from 'axios';




const SingleproductDetails = () => {
    const id = useParams()
    const { user } = useContext(AuthContext);
    const [selectAccordion, setSelectAccordion] = useState(true);
    const [desclosures, setDesclosures] = useState(true);
    const [carDetails, setCarDetails] = useState({});
    const [saveUser, setSaveUser] = useState('');
    const [allProduct, setAllProduct] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const location = useLocation();
    const prevLocationRef = useRef();


    const AdditionalInfo = () => {
        setSelectAccordion(!selectAccordion)
    }

    const Disclosures = () => {
        setDesclosures(!desclosures)
    }

    // get single product details---------------
    useEffect(() => {
        if (prevLocationRef.current !== location.pathname) {
            window.scrollTo(0, 0);
            prevLocationRef.current = location.pathname;
        }
        fetch(`https://api.reuse.sale/listing/api/single_read.php?list_id=${id?.id}`)
            .then(res => res.json())
            .then(carData => {

                setCarDetails(carData);

                // Read all views data --------------
                fetch('https://api.reuse.sale/count/api/read.php')
                    .then(res => res.json())
                    .then(data => {
                        const countData = data?.body
                        const result = countData?.find(getProduct);

                        if (result) {
                            let view = parseInt(result?.list_count) + 1;

                            // update Views -------------------
                            axios.post('https://api.reuse.sale/count/api/update.php', {
                                "count_id": result?.count_id,
                                "list_id": result?.list_id,
                                "list_count": view
                            })
                                .then(res => {
                                    // console.log( res );
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                        }
                        else {
                            // Set views-----------------------
                            axios.post('https://api.reuse.sale/count/api/create.php', {
                                "list_id": carData?.list_id,
                                "list_count": 1
                            })
                                .then(res => {
                                    // console.log( res );
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                        }

                    })

                setRefresh(!refresh)
            })
    }, [id, location.pathname]);

    const getProduct = (countData) => {
        if (countData?.list_id === id?.id) {
            return countData;
        }
    }

    // get single User-----------
    useEffect(() => {
        fetch(`https://api.reuse.sale/user/api/single_read.php?u_id=${carDetails?.u_id}`)
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                setSaveUser(data);
            })
    }, [carDetails?.u_id]);


    // get all similar Product---------------------
    useEffect(() => {
        fetch('https://api.reuse.sale/listing/api/read.php')
            .then(res => res.json())
            .then(data => {
                const allProducts = data.body;
                if (data.body.length > 4) {
                    let products = allProducts.slice(0, 4);
                    setAllProduct(products);
                    return;
                }
                setAllProduct(allProducts);
            })
    }, []);


    const { u_image, u_fullname, status, pstatus } = saveUser;


    // CarDetails Distructured here--------------------
    const {
        list_registrationyear,
        list_productname,
        list_price,
        list_negotiable,
        list_address,
        list_description,
        list_publishdate,
        image_one,
        image_five,
        image_three,
        image_four,
        image_two,
        list_brand,
        list_model,
        list_mileage,
        list_fueltype,
        interior,
        list_enginecapacity,
        list_transmission,
        trimm,
        body_style,
        cylinders,
        exterior,
        doors,
        title_type,
        disclosure
    } = carDetails;

    const Details = [
        {
            id: 1,
            name: 'Trim',
            des: trimm
        },
        {
            id: 2,
            name: 'Body style',
            des: body_style
        },
        {
            id: 3,
            name: 'Number of cylinders',
            des: cylinders
        },
        {
            id: 4,
            name: 'Exterior color',
            des: exterior
        },
        {
            id: 5,
            name: 'Number of doors',
            des: doors
        },
        {
            id: 6,
            name: 'Title type',
            des: title_type
        },
    ]



    return (
        <div className='w-11/12 my-5 md:my-10 mx-auto'>
            {/* images section ------------ */}
            <div className='grid grid-rows-2 grid-cols-4 gap-1 md:gap-2'>
                <img className='rounded-md md:rounded-lg row-span-2 col-span-2 h-full w-full object-cover' src={image_one} alt="" />
                <img className='rounded-md md:rounded-lg h-full w-full object-cover' src={image_two} alt="" />
                <img className='rounded-md md:rounded-lg h-full w-full object-cover' src={image_three} alt="" />
                <img className='rounded-md md:rounded-lg h-full w-full object-cover' src={image_four} alt="" />
                <img className='rounded-md md:rounded-lg h-full w-full object-cover' src={image_five} alt="" />
            </div>
            {/* card heading---------------- */}
            <div className='block md:flex justify-between mt-10'>
                <div>
                    <h1 className='text-2xl font-semibold'>{list_registrationyear} {list_productname}</h1>

                    <h2 className='font-bold text-lg'>${list_price}</h2>
                    <p className='my-2'>Negotiable: <span className='font-bold'>{list_negotiable}</span></p>
                </div>
                <div className='flex gap-2 items-center text-xl'>
                    <GoLocation className='text-xl' />
                    <h1>{list_address ? list_address : 'No Address added'}</h1>
                </div>
            </div>
            {/* person details------- */}

            <div className='md:flex justify-between my-2 gap-5'>
                <div className='mt-5 md:mt-0 block md:flex md:flex-col'>
                    <div className='flex gap-5 items-center'>
                        <div className="avatar online placeholder">
                            <div className="bg-neutral-focus text-neutral-content rounded-full w-16">
                                <div className="w-24 rounded-full">
                                    {
                                        u_image ?
                                            <img className='object-cover' src={u_image} alt='' />
                                            :
                                            <img className='object-cover' src={userImage} alt='' />
                                    }
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-xl font-bold'>{u_fullname ? u_fullname : 'Not Updated'}</h1>
                            <p className='text-gray-500 text-lg'>Joined {list_publishdate}</p>
                        </div>
                    </div>
                    <div className='mt-3 md:mt-5 flex gap-4 items-center'>
                        <div className='flex gap-2 items-center text-xl'>
                            {
                                status === 'true' ?
                                    <FaCheckCircle className='text-[#589BB1]' />
                                    :
                                    <FaTimesCircle className='text-red-800' />
                            }
                            <h1 className='italic'>Email</h1>
                        </div>
                        <div className='flex gap-2 items-center text-xl'>
                            {
                                pstatus === 'true' ?
                                    <FaCheckCircle className='text-[#589BB1]' />
                                    :
                                    <FaTimesCircle className='text-red-800' />
                            }
                            <h1 className='italic'>Phone</h1>
                        </div>
                    </div>
                </div>
                <div className='flex mt-5 md:mt-0'>
                    {
                        user ? <UserContactDetails saveUser={saveUser}></UserContactDetails>
                            :
                            // <Link to={ `/productContact/${ list_id }` } className='btn btn-accent'>Contact user</Link>
                            <Link to='/login' className='btn btn-accent'>Contact user</Link>
                    }
                </div>

                {/* {
                    user ?
                        <div className='flex gap-4'>
                            <a
                                href={ fburl }
                                target="_blank"
                                rel="noreferrer"
                                style={ { boxShadow: '1px 1px 8px 2px gray ' } } className='btn btn-md bg-transparent border-none text-blue-800 hover:text-[#F1F1F1] hover:bg-sky-800 duration-1000'>
                                <div className='flex gap-2 items-center'>
                                    <FaFacebook className='text-2xl' />
                                    <p>Go to FaceBook</p>
                                </div>
                            </a>

                            <a
                                href={ instaurl }
                                target="_blank"
                                rel="noreferrer"
                                style={ { boxShadow: '1px 1px 8px 2px gray ' } }
                                className='btn btn-md bg-transparent border-none hover:bg-pink-300 duration-500'>
                                <div className='flex gap-2 items-center'>
                                    <img className='w-7' src={ insta } alt="" />
                                    <p className='text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500'>
                                        Go Instagram
                                    </p>
                                </div>
                            </a>
                            <a
                                href={ waurl }
                                target="_blank"
                                rel="noreferrer"
                                style={ { boxShadow: '1px 1px 8px 2px gray ' } } className='btn btn-md bg-transparent border-none text-green-700 hover:text-[#F1F1F1] hover:bg-green-700 duration-700'>
                                <div className='flex gap-2 items-center'>
                                    <FaWhatsapp className='text-2xl' />
                                    <p>Go WhatsApp</p>
                                </div>
                            </a>
                        </div>
                        :
                        <button className='btn btn-accent'>Contact user</button>
                    // <Link to={ `/productContact/${ list_id }` } className='btn btn-accent'>Contact user</Link>
                } */}
            </div>

            {/* person details------- */}
            <hr className='my-5 border w-full' />
            <div>
                <h1 className='text-2xl font-bold'>Details</h1>
                <hr className='border w-20 border-[#589BB1]' />
                <hr className='border w-16 mt-1 border-[#589BB1] mb-5' />
                {/* Details----------- */}
                <div className='grid grid-cols-2 md:grid-cols-4 gap-5 md:gap-10'>
                    <div className='flex gap-4 items-center'>
                        <FaCar className='text-3xl font-bold' />
                        <div>
                            <h1 className='font-bold text-lg'>{list_brand}</h1>
                            <p className='text-gray-500'>{list_model}</p>
                        </div>
                    </div>

                    <div className='flex gap-4 items-center'>
                        <BsSpeedometer2 className='text-3xl font-bold' />
                        <div>
                            <h1 className='font-bold text-lg'>{list_mileage}</h1>
                            <p className='text-gray-500'>Miles</p>
                        </div>
                    </div>

                    <div className='flex gap-4 items-center'>
                        <FaGasPump className='text-3xl font-bold' />
                        <div>
                            <h1 className='font-bold text-lg'>{list_fueltype}</h1>
                            <p className='text-gray-500'>Fuel</p>
                        </div>
                    </div>

                    <div className='flex gap-4 items-center'>
                        <img className='w-8' src={seat} alt="" />
                        <div>
                            <h1 className='font-bold text-lg'>{interior}</h1>
                            <p className='text-gray-500'>Interior</p>
                        </div>
                    </div>

                    <div className='flex gap-4 items-center'>
                        <FaClipboardList className='text-3xl font-bold' />
                        <div>
                            <h1 className='font-bold text-lg'>{list_registrationyear}</h1>
                            <p className='text-gray-500'>Registration year</p>
                        </div>
                    </div>

                    <div className='flex gap-4 items-center'>
                        <FaCogs className='text-3xl font-bold ' />
                        <div>
                            <h1 className='font-bold text-lg'>{list_enginecapacity}</h1>
                            <p className='text-gray-500'>Engine Capacity</p>
                        </div>
                    </div>

                    <div className='flex gap-4 items-center'>
                        <FaTachometerAlt className='text-3xl font-bold ' />
                        <div>
                            <h1 className='font-bold text-lg'>{list_mileage}</h1>
                            <p className='text-gray-500'>Kilometers run
                                (km)</p>
                        </div>
                    </div>

                    <div className='flex gap-4 items-center'>
                        <img className='w-8' src={gear} alt="" />
                        <div>
                            <h1 className='font-bold text-lg'>{list_transmission}</h1>
                            <p className='text-gray-500'>Transmission</p>
                        </div>
                    </div>

                </div>
                {/* Details----------- */}
                <hr className='border my-5' />
                {/* Description ------------start */}
                <div className='w-full text-justify md:text-start md:w-2/3'>
                    <h1 className='text-2xl font-bold'>Description</h1>
                    <hr className='border w-32 border-[#589BB1]' />
                    <hr className='border w-24 mt-1 border-[#589BB1] mb-5' />
                    <p>{list_description}</p>
                </div>
                {/* Description ------------End */}
                <hr className='border my-5' />
                {/* Additional Info------------ */}
                <div onClick={AdditionalInfo}
                    className="collapse border-y rounded-md my-10">
                    <input type="checkbox" />
                    <div className="collapse-title text-xl font-medium flex justify-between items-center">
                        <h1 className='font-medium'>Additional Info</h1>
                        {
                            selectAccordion ?
                                <FaPlus className='text-[#589BB1]' />
                                :
                                <FaMinus className='text-[]' />
                        }
                    </div>
                    <div className="collapse-content px-10">
                        <div className='grid grid-cols-2 md:grid-cols-3 gap-5'>
                            {
                                Details.map(detail =>
                                    <div key={detail.id} className='grid grid-cols-2'>
                                        <h1 className='text-lg font-semibold text-gray-400'>{detail.name} :</h1>
                                        <p>{detail.des}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                {/* Additional Info------------ */}

                {/* Seller desclosures------------ */}
                <div onClick={Disclosures} className="collapse border-y rounded-md my-10">
                    <input type="checkbox" />
                    <div
                        className="collapse-title text-xl font-medium flex justify-between items-center">
                        <h1 className='font-medium'>Seller Disclosures</h1>
                        {
                            desclosures ?
                                <FaPlus className='text-[#589BB1]' />
                                :
                                <FaMinus className='text-[#589BB1]' />
                        }
                    </div>
                    <div className="collapse-content px-10">
                        <p>Are you aware of any damage or defects that would materially effect the value of the vehicle?</p>
                        {
                            disclosure === 'yes' ?
                                <div className='flex gap-4'>
                                    <div className="form-control my-5">
                                        <label className="flex gap-2 items-center cursor-pointer">
                                            <input defaultChecked type="checkbox" className="checkbox border-gray-700" />
                                            <span className="">Yes</span>
                                        </label>
                                    </div>
                                    <div className="form-control my-5">
                                        <label className="flex gap-2 items-center cursor-pointer">
                                            <input type="checkbox" className="checkbox border-gray-700" disabled />
                                            <span className="">No</span>
                                        </label>
                                    </div>
                                </div>
                                :
                                <div className='flex gap-4'>
                                    <div className="form-control my-5">
                                        <label className="flex gap-2 items-center cursor-pointer">
                                            <input type="checkbox" className="checkbox border-gray-700" disabled />
                                            <span className="">Yes</span>
                                        </label>
                                    </div>
                                    <div className="form-control my-5">
                                        <label className="flex gap-2 items-center cursor-pointer">
                                            <input defaultChecked type="checkbox" className="checkbox border-gray-700" />
                                            <span className="">No</span>
                                        </label>
                                    </div>
                                </div>
                        }

                    </div>
                </div>
                {/* Seller desclosures------------ */}
            </div>
            {/* Similar start--------- */}
            <div>
                <h1 className='text-2xl font-bold mb-5'>Similar</h1>
                <hr className='w-full' />
                <div className='grid grid-cols-1 md:grid-cols-4 gap-5 mt-5'>
                    {
                        allProduct.map((product, i) =>
                            <Link
                                to={`/singleProduct/${product?.list_id}`}
                                key={i}
                                className="card rounded-md card-compact shadow-md">
                                <figure><img className='h-40 w-full object-cover' src={product?.image_two} alt="car" /></figure>
                                <div className="card-body">
                                    <h2 className="card-title">{product?.list_productname}</h2>
                                    <div>
                                        <h1 className='font-semibold text-gray-600'>Price</h1>
                                        <p className='text-lg font-bold'>$ {product?.list_price}</p>
                                    </div>
                                    <div className='grid grid-cols-2 gap-2'>
                                        <div className='flex gap-1 items-center'>
                                            <GoLocation className='text-lg text-orange-500' />
                                            <p> Address Here</p>
                                        </div>
                                        <div className='flex gap-1 items-center text-gray-500'>
                                            <BsSpeedometer2 className='text-lg' />
                                            <p>{product?.list_mileage} km</p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )
                    }
                </div>
            </div>
            {/* Similar End--------- */}
        </div >
    );
};

export default SingleproductDetails;