import React from 'react';
import { useForm } from 'react-hook-form';


const AdditionalInfo = ({ setPersonalInfo, personalInfo, handleBack, activeStep, handleFinish, handleNext }) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const wheels = [];
    // const currentYear = date.getFullYear();
    for (let i = 2; i <= 20; i++) {
        wheels.push(i + 1);
    }

    const Doors = [];
    for (let i = 2; i <= 8; i++) {
        Doors.push(i + 1)
    }

    const onSubmit = data => {
        setPersonalInfo({ ...personalInfo, ...data })
        // console.log(data);

        handleNext();
    };


    return (

        <form onSubmit={handleSubmit(onSubmit)}>
            {/* Form section -------------Start */}
            <div className='text-black grid grid-cols-1 md:grid-cols-2 gap-5 justify-between items-center mt-10'>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text text-black">Interior</span>
                    </label>

                    <div className="md:h-12">
                        <input type="text" placeholder="Interior" className="input bg-transparent focus:outline-none border-[#00000051] text-black input-bordered w-full bg-[#E0E0E0]"
                            {...register("interior",
                                { required: 'Interior is required' }
                            )} />
                        {errors.interior && <span className="text-error">{errors.interior?.message}</span>}
                    </div>
                </div>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text text-black">Wheel Drive</span>
                    </label>
                    <div className='md:h-12'>
                        <select defaultValue='Wheels' className="select bg-transparent focus:outline-none border-[#00000051] font-normal w-full select-bordered bg-[#E0E0E0]"
                            {...register("wheel",
                                { required: 'Wheel is required' }
                            )}>
                            <option value=''>Select Wheels</option>
                            {
                                wheels.map((wheel, i) => <option key={i} value={wheel}>Wheels {wheel}</option>)
                            }
                        </select>
                        {errors.wheel && <span className="text-error">{errors.wheel?.message}</span>}
                    </div>
                </div>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text text-black">Trim</span>
                    </label>
                    <div className='md:h-12'>
                        {/* <select defaultValue='Select One' className="select font-normal w-full select-bordered bg-[#E0E0E0]" {...register("trim", {
                            required: 'Trim is Required'
                        })}>
                            <option disabled>Select One</option>
                            <option>Trim 1</option>
                            <option>Trim 2</option>
                        </select> */}

                        <input type="text" placeholder="Trim Option" className="input bg-transparent focus:outline-none border-[#00000051] text-black input-bordered w-full bg-[#E0E0E0]"
                            {...register("trim",
                                { required: 'Trim is Required' }
                            )} />
                        {errors.trim && <span className="text-error">{errors.trim?.message}</span>}
                    </div>
                </div>
                <div className="form-control w-full ">
                    <label className="label">
                        <span className="label-text text-black">Body Style</span>
                    </label>
                    <div className='md:h-12'>
                        <input type="text" placeholder="Body Style" className="input bg-transparent focus:outline-none border-[#00000051] text-black input-bordered w-full bg-[#E0E0E0]"
                            {...register("body_style",
                                { required: 'Body Style is Required' }
                            )} />
                        {errors.body_style && <span className="text-error">{errors.body_style?.message}</span>}
                    </div>
                </div>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text text-black">Number Of Doors</span>
                    </label>
                    <div className='md:h-12'>
                        <select defaultValue='Select' className="select bg-transparent focus:outline-none border-[#00000051] font-normal w-full select-bordered bg-[#E0E0E0]"
                            {...register("doors",
                                { required: 'Doors is Required' }
                            )}>
                            <option value='' disabled>Select</option>
                            {
                                Doors.map((door, i) => <option key={i} value={door}>Doors-{door}</option>)
                            }
                        </select>
                        {errors.doors && <span className="text-error">{errors.doors?.message}</span>}
                    </div>
                </div>
                <div className="form-control w-full ">
                    <label className="label">
                        <span className="label-text text-black">Number Of Cylinders</span>
                    </label>
                    <div className='md:h-12'>
                        <input type="text" placeholder="Number Of Cylinders" className="input bg-transparent focus:outline-none border-[#00000051] input-bordered w-full bg-[#E0E0E0]"
                            {...register("cylinders", {
                                required: 'Cylinder is Required'
                            })} />

                        {errors.cylinders && <span className="text-error">{errors.cylinders?.message}</span>}
                    </div>
                </div>
                <div className="form-control w-full ">
                    <label className="label">
                        <span className="label-tex text-black">Title Type</span>
                    </label>
                    <div className='md:h-12'>
                        <input type="text" placeholder="Title Type" className="input bg-transparent focus:outline-none border-[#00000051] input-bordered w-full bg-[#E0E0E0]"
                            {...register("title_type", {
                                required: 'Title_type is Required'
                            })} />
                        {errors.title_type && <span className="text-error">{errors.title_type?.message}</span>}
                    </div>
                </div>
                <div className="form-control w-full">
                    <label className="label">
                        <span className="label-text text-black">Exterior Color</span>
                    </label>
                    {/* <select defaultValue='Select' className="select font-normal select-bordered bg-[#E0E0E0]"{...register("exterior")}>
                        <option disabled >Select</option>
                        <option>Exterior Color 1</option>
                        <option>Exterior Color 2</option>
                    </select> */}
                    <div className='md:h-12'>
                        <input type="text" placeholder="Exterior Design" className="input bg-transparent focus:outline-none border-[#00000051] input-bordered w-full bg-[#E0E0E0]"
                            {...register("exterior", {
                                required: 'Exterior is Required'
                            })} />
                        {errors.exterior && <span className="text-error">{errors.exterior?.message}</span>}
                    </div>
                </div>
            </div>
            {/* Form section ------------- End */}

            {/* Button section --------------- start */}
            <div className='flex flex-col md:flex-row gap-4 md:justify-between mt-10'>
                <button
                    onClick={handleBack}
                    className='btn btn-accent btn-outline btn-block md:btn-wide'
                >Back</button>

                {
                    activeStep === 2 ?
                        <button
                            onClick={handleFinish}
                            className='btn btn-primary btn-block md:btn-wide'
                        >Post Ad</button>
                        :
                        // <button
                        //     onClick={ handleNext }
                        //     className='btn btn-primary btn-wide'
                        // >Next</button>
                        <button type="submit"
                            onSubmit={handleSubmit}
                            className='btn btn-primary btn-block md:btn-wide'>
                            Next
                        </button>
                }
            </div>
            {/* <FormButton
                handleBack={ handleBack }
                handleFinish={ handleFinish }
                handleSubmit={ handleSubmit }
                activeStep={ activeStep }
                handleNext={ handleNext }
            /> */}
            {/* Button section --------------- End */}
        </form >
    );
};

export default AdditionalInfo;