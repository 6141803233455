import React from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const TermsCondition = () => {
    return (
        <div className='w-5/6 mx-auto mt-10'>
            <div className='flex w-full items-center font-semibold'>
                <h1>Home</h1>
                <FaAngleRight />
                <h1>Terms & Condition</h1>
            </div>

            <div className='mt-10 flex flex-col md:flex-row gap-8'>
                <div className='w-full md:w-1/4'>
                    <div>
                        <h1 className='text-2xl font-bold'>Terms & conditions</h1>
                        <hr className='border mb-5 mt-2' />
                    </div>
                    <div className='flex flex-col gap-3 sticky top-24'>
                        <a href='#introduction' className='text-[#F33C93]'>Introduction and Agreement</a>
                        <a href='#advertising' className='text-[#F33C93]'>Advertising and Research Service</a>
                        <a href='#dealer' className='text-[#F33C93]'>Dealer and Manufacturer Incentives and Offers</a>
                        <a href='#copyrights' className='text-[#F33C93]'>Copyrights and Copyright Agents</a>
                        <a href='#trademark' className='text-[#F33C93]'>Trademarks</a>
                        <a href='#materials' className='text-[#F33C93]'>User Materials</a>
                        <a href='#termsofservice' className='text-[#F33C93]'>Changes to these Terms of Service</a>
                    </div>
                </div>
                <div className='w-full md:w-3/4 flex flex-col gap-8'>
                    <div>
                        <h1 className='text-xl font-semibold mb-3'>Introduction and Agreement</h1>
                        <p id='introduction' className='text-justify'>This website (the "Site") is owned and operated by Cars.com LLC ("Cars.com"). These Terms of Service apply to the Site, located at www.cars.com, as well as to any mobile or similar versions of the Site that Cars.com operates. By using the Site, you hereby agree that you are at least eighteen (18) years of age And you acknowledge that you have read and agree to be bound by these Terms of Service and the Cars.com Privacy Statement and California & Nevada Privacy Notice.</p>
                    </div>
                    <div>
                        <h1 id='advertising' className='text-xl font-semibold mb-3'>Advertising and Research Service</h1>
                        <p className='text-justify'>Cars.com operates the Site as an online advertising and research service for car buyers, sellers and enthusiasts. Cars.com does not sell vehicles directly and is never a party to any transaction between buyers and sellers. As a result, Cars.com does not (a) guarantee or ensure any vehicle or any transaction between a buyer and seller, (b) collect or process payment or transfer of title on behalf of buyers or sellers, or (c) warehouse, store, ship or deliver any vehicles.</p>
                    </div>
                    <div>
                        <h1 id='dealer' className='text-xl font-semibold mb-3'>Dealer and Manufacturer Incentives and Offers</h1>
                        <p className='text-justify'>Advertisers on Cars.com may include information about special offers, incentives, or pricing programs associated with a specific brand, model, or vehicle ("Offers"). Cars.com is not responsible for the content of any such Offers, nor responsible for any errors or omissions in Offer contents or descriptions. Users of the site should contact the relevant advertiser for full details on any such Offers, including eligibility requirements, limitations and restrictions, and availability.</p>
                    </div>
                    <div>
                        <h1 id='copyrights' className='text-xl font-semibold mb-3'>Copyrights and Copyright Agents</h1>
                        <p className='text-justify'>It is the policy of Cars.com to respond to claims of intellectual property infringement. Cars.com will promptly process and investigate notices of alleged infringement and take appropriate actions under the Digital Millennium Copyright Act, Title 17, United States Code, Section 512(c)(2) ("DMCA") and other applicable intellectual property laws. Pursuant to the DMCA, notifications of claimed copyright infringement should be sent to a Service Provider's Designated Agent</p>
                        <ol className='list-decimal list-inside mt-2 text-justify leading-relaxed'>
                            <li>
                                A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed
                            </li>
                            <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</li>
                            <li> Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit the service provider to locate the material;</li>
                            <li>Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number and, if available, an electronic mail address at which the complaining party may be contacted;</li>
                            <li>A statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law</li>
                        </ol>
                    </div>
                    <div>
                        <h1 id='trademark' className='text-xl font-semibold mb-3'>Trademarks</h1>
                        <p>You acknowledge that the Content includes certain trademarks and service marks owned by Cars.com, Affiliates, Licensors and others. You agree not to copy, use or otherwise infringe upon or dilute these trademarks or service marks. You further agree that you will not alter or remove any trademark, copyright or other notice from any copies of the Content. The "Cars.com" name and logo are trademarks of Cars.com, LLC. Other product and company names herein may be trademarks of their respective owners.</p>
                    </div>
                    <div>
                        <h1 id='materials' className='text-xl font-semibold mb-3'>User Materials</h1>
                        <p>By submitting ads, content, photos, data or other materials ("Materials") to the Site, you hereby grant to Cars.com and its Affiliates a royalty-free, perpetual, irrevocable, nonexclusive, fully transferable, fully sublicensable right and license to copy, modify, display, distribute, perform, create derivative works from, and otherwise use and exploit all such Materials in any form, media, software or technology of any kind now existing or developed in the future. You further grant to Cars.com and its Affiliates a royalty-free right and license to use your name, image and likeness in connection with the reproduction or distribution of the Materials.</p>
                    </div>
                    <div>
                        <h1 id='termsofservice' className='text-xl font-semibold mb-3'>Changes to these Terms of Service</h1>
                        <p>We reserve the right to update and periodically amend these Terms of Service at our discretion and at any time. If we make changes to these Terms of Service, amendments will be posted online and the date of update will be included. Your continued use of Cars.com and its affiliated sites after any such update indicates your agreement to the same. Cars.com may modify, suspend, discontinue or restrict the use of any portion of the Site without notice or liability.</p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default TermsCondition;