import React, { createContext, useState } from 'react';


export const searchContext = createContext();

const SearchContext = ( { children } ) => {
    const [ searchText, setSearchText ] = useState( '' );
    const [ category, setCategory ] = useState( '' )


    const searchInfo = { searchText, setSearchText, category, setCategory }

    return (
        <searchContext.Provider value={ searchInfo }>
            { children }
        </searchContext.Provider>
    );
};

export default SearchContext;