import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import './Category.css'
import { FaAngleRight } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { searchContext } from '../../Context/SearchContext/SearchContext';
import Product from './Product/Product';



const brands = [
    'Audi', 'Chevrolet', 'Cadillac', 'Acura',
    'BMW', 'Chrysler', 'Ford', 'Buick',
    'INFINITI', 'GMC', 'Honda', 'Hyundai',
    'Jeep', 'Genesis', 'Dodge', 'Jaguar',
    'Kia', 'Land Rover', 'Lexus', 'Mercedes-Benz',
    'Mitsubishi', 'Lincoln', 'MAZDA', 'Nissan',
    'MINI', 'Porsche', 'Ram', 'Subaru',
    'Toyota', 'Volkswagen', 'Volvo', 'Alfa Romeo',
    'FIAT', 'Freightliner', 'Maserati', 'Tesla',
    'Aston Martin', 'Bentley', 'Ferrari', 'Lamborghini',
    'Lotus', 'McLaren', 'Rolls-Royce', 'smart',
    'Scion', 'SRT', 'Suzuki', 'Fisker',
    'Maybach', 'Mercury', 'Saab', 'HUMMER',
    'Pontiac', 'Saturn', 'Isuzu', 'Panoz',
    'Oldsmobile', 'Daewoo', 'Plymouth', 'Eagle',
    'Geo', 'Daihatsu'
]

const Category = () => {
    const [ allProduct, setAllProduct ] = useState( [] );
    const [ filterProduct, setFilterProduct ] = useState( [] )
    const { searchText, category } = useContext( searchContext );
    const location = useLocation();
    const prevLocationRef = useRef();

    const { register, handleSubmit } = useForm();


    // get all Product from database---------------------
    // getUrl = https://api.reuse.sale/listing/api/read.php

    // console.log( category );

    useEffect( () => {
        if ( prevLocationRef.current !== location.pathname ) {
            window.scrollTo( 0, 0 );
            prevLocationRef.current = location.pathname;
        }
        fetch( 'https://api.reuse.sale/listing/api/read.php' )
            .then( res => res.json() )
            .then( data => {

                if ( searchText === '' ) {
                    setAllProduct( data.body );
                }
                else {

                    const searchProduct = allProduct.filter( product => product?.list_brand.toLowerCase().includes( `${ searchText }` ) || product?.list_vehicletype.toLowerCase().includes( `${ searchText }` ) || product?.list_model.toLowerCase().includes( `${ searchText }` ) );

                    setFilterProduct( searchProduct );
                    setAllProduct( data.body );

                }
            } )
    }, [ searchText, location.pathname ] );



    const onSubmit = data => {
        const { vehicletype, Model, Brands, Fuel } = data;

        const categoryProduct = allProduct.filter( product => product?.list_vehicletype === vehicletype );

        const ModelProduct = allProduct.filter( product => product?.list_model === Model );

        const BrandsProduct = allProduct.filter( product => product?.list_brand === Brands );

        const FuelProduct = allProduct.filter( product => product?.list_fueltype === Fuel );

        const filterProduct = categoryProduct.concat( ModelProduct, BrandsProduct, FuelProduct );


        const unique = filterProduct.filter( ( value, index ) => {
            return filterProduct.indexOf( value ) === index;
        } );

        setFilterProduct( unique )

    };



    const handleBudget = ( e ) => {
        if ( e.target.checked ) {
            const budget = e.target.value;

            const budgetString = ( budget.split( " " ) );
            const lowBudget = ( +( budgetString[ 0 ] ) );
            const highBudget = ( +( budgetString[ 1 ] ) );

            const BudgetProduct = allProduct.filter( product => product.list_price >= lowBudget && product.list_price <= highBudget );
            setFilterProduct( BudgetProduct )

        } else {
            // console.log( "" );
        }


    }


    return (
        <div className='w-5/6 mx-auto my-10'>

            <div className='md:flex gap-5 '>
                <div>
                    <div className='sticky top-20'>

                        <form onChange={ handleSubmit( onSubmit ) } className='flex gap-4 justify-center items-center flex-col '>
                            <div className='flex w-full md:w-80 gap-2 flex-col border border-[#00000067] rounded-md p-4'>
                                <select className='bg-transparent flex flex-row px-5 rounded-md border font-bold border-[#00000067]' { ...register( "vehicletype" ) }>
                                    <option value='' className='font-bold'>All Categories</option>
                                    <option value="Motorbikes">Motorbikes</option>
                                    <option value="Car">Car</option>
                                    <option value="Trucks">Trucks</option>
                                    <option value="Bicycles">Bicycles</option>
                                </select>

                                {/* <select className='bg-transparent flex flex-row px-5 rounded-md border font-bold' { ...register( "location" ) }>
                                    <option value='' className='font-bold'>Location</option>
                                    <option value="Location-1">Location-1</option>
                                    <option value="Location-2">Location-2</option>
                                    <option value="Location-3">Location-3</option>
                                    <option value="Location-4">Location-14</option>
                                </select> */}

                                <select className='bg-transparent flex flex-row px-5 rounded-md border font-bold border-[#00000067]' { ...register( "Brands" ) }>
                                    <option value='' className='font-bold'>Brand</option>
                                    {
                                        brands?.map( ( brand, i ) => <option key={ i } value={ `${ brand }` }>{ brand }</option> )
                                    }
                                </select>
                                <select className='bg-transparent flex flex-row px-5 rounded-md border font-bold border-[#00000067]' { ...register( "Model" ) }>
                                    <option value='' className='font-bold'>Model</option>
                                    <option value="Volvo-121">Volvo-121</option>
                                    <option value="Volovo_1211_P">Volovo_1211_P</option>
                                    <option value="BMW-121">BMW-121</option>
                                    <option value="Toyota-121">Toyota-121</option>
                                </select>
                                <select className='bg-transparent flex flex-row px-5 rounded-md border font-bold border-[#00000067]' { ...register( "Fuel" ) }>
                                    <option value='' className='font-bold'>Fuel</option>
                                    <option>Diesel</option>
                                    <option>Petrol</option>
                                    <option>CNG</option>
                                    <option>Electric </option>
                                    <option>octane</option>
                                    <option>Hydrogen</option>
                                </select>

                            </div>

                        </form>

                        <hr className='border border-[#00000067] w-full my-5 md:w-80' />
                        <div className='flex text-black w-full md:w-80 bg-white gap-2 flex-col border border-[#00000067] rounded-md p-4 mb-5'>
                            <h1 className='font-bold'>Budget</h1>
                            <div className="form-control justify-start">
                                <label className="label justify-start gap-5 cursor-pointer">
                                    <input onChange={ handleBudget } type="checkbox" value='3000 5000' className="checkbox rounded-md h-5 w-5 border-black" />
                                    <span className="">$3000 - $5000</span>
                                </label>
                            </div>
                            <div className="form-control justify-start">
                                <label className="label justify-start gap-5 cursor-pointer">
                                    <input onChange={ handleBudget }
                                        type="checkbox" value='5000 10000' className="checkbox rounded-md h-5 w-5 border-black" />
                                    <span className="">$5000 - $10000</span>
                                </label>
                            </div>
                            <div className="form-control justify-start">
                                <label className="label justify-start gap-5 cursor-pointer">
                                    <input onChange={ handleBudget } type="checkbox" value='10000 15000' className="checkbox rounded-md h-5 w-5 border-black" />
                                    <span className="">$10000 - $15000</span>
                                </label>
                            </div>
                            <div className="form-control justify-start">
                                <label className="label justify-start gap-5 cursor-pointer">
                                    <input onChange={ handleBudget } type="checkbox" value='15000 20000' className="checkbox rounded-md h-5 w-5 border-black" />
                                    <span className="">$15000 - $20000</span>
                                </label>
                            </div>
                            <div className="form-control justify-start">
                                <label className="label justify-start gap-5 cursor-pointer">
                                    <input onChange={ handleBudget } type="checkbox" value='20000 25000' className="checkbox rounded-md h-5 w-5 border-black" />
                                    <span className="">$20000 - $25000</span>
                                </label>
                            </div>
                            {/* <input type="submit" value="Submit" className='hidden' /> */ }
                        </div>
                    </div>
                </div>

                <div className='border-2 p-2 md:p-5 w-full rounded-lg'>
                    <div className='flex gap-2 w-full items-center'>
                        <h1>Home</h1>
                        <FaAngleRight />
                        <h1>Category Name</h1>
                        <FaAngleRight />
                        <h1>Cars:</h1>

                        <span className='text-purple-900 font-bold'>{ filterProduct?.length ? filterProduct?.length : allProduct?.length }</span>

                    </div>
                    {
                        filterProduct?.length ?

                            filterProduct.map( ( car, i ) => <Product key={ i } car={ car }></Product> )
                            :
                            allProduct?.map( ( car, i ) => <Product key={ i } car={ car }></Product>
                            )

                    }

                </div >
            </div >
        </div >
    );
};

export default Category;
